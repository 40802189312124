<div class="d-flex justify-content-center h-100 gray-bg">

    <!-- CONFIRM TIME -->
    <div class="shadow-sm rounded-card" [hidden]="changeTime">
        <h3 class="fw-600">{{'endUserConf.title' | translate}}</h3>
        <div class="info-container mt-4">
            <div class="margin-desktop">
                <div class="mt-5">
                    <div>{{'endUserConf.firstP' | translate}}</div>
                    <p class="fw-bold">{{returnDate(startTime)}} {{returnTime(startTime)}} - {{returnTime(endTime)}}</p>
                    <p class="mt-3">{{'endUserConf.secondP' | translate}}</p>
                </div>

            </div>
            <div>
                <p class="mt-3">{{'basic.metersInformation' | translate}}</p>
                <p class="fw-bold">{{'basic.meteringPointNumber' | translate}}: <br> {{locationNr}}</p>
                <p class="mt-3 fw-bold">{{'basic.meteringPointAddress' | translate}}: <br> {{address}}</p>
            </div>
        </div>
        <!-- TIME NOT CONFIRMED -->
        <div class="mt-5" *ngIf="!confirmed">
            <h4><img src="assets/icons/done_green.svg">
                {{'endUserConf.confirmTime' | translate}}</h4>
            <div class="gray-text">
                {{'endUserConf.phone' | translate}}
            </div>
            <input id="phonenum1" type="phone" class="form-control phone-width" placeholder="+123 456 789" [(ngModel)]="phoneNr" (ngModelChange)="checkPhoneNumber()">
            <div [hidden]="!invalidPhoneNumber" class="red-text" id="phonewarning">{{'call-service.phoneNumberInvalid' | translate }}</div>

            <p class="mt-3">{{'endUserConf.pressConfirm' | translate}}</p>
            <button class="btn btn-green" (click)="confirmWorkorder()">{{'basic.confirm' | translate}}</button>
        </div>
        <!-- TIME CONFIRMED -->
        <div class="mt-5" *ngIf="confirmed">
            <h4><img src="assets/icons/done_green.svg">
                {{'endUserConf.timeAndPhoneOk' | translate}}</h4>
            <div class="gray-text">
                {{'endUserConf.phone' | translate}}
            </div>
            <input type="phone" class="form-control phone-width" placeholder="+123 456 789" [(ngModel)]="phoneNr" disabled>
            <p class="mt-5">{{'basic.timeConfirmed' | translate}} <span class="fw-bold">{{returnDate(startTime)}} {{returnTime(startTime)}} - {{returnTime(endTime)}}</span> {{'endUserConf.timeConfirmed2' | translate}}</p>
        </div>
        <p class="mt-5">{{'endUserConf.ifNotOk' | translate}} <a class="blu-text pe-auto" (click)="changeSlot()">{{'endUserConf.fromWhere' | translate}}</a></p>
    </div>

    <!-- CHANGE TIME -->

    <div class="shadow-sm rounded-card" [hidden]="!changeTime">
        <button class="btn btn-secondary" (click)="backToConfirm()">< Back</button>
        <h3 class="fw-600 mt-3">{{'basic.selectNewTimeSlot' | translate}}</h3>

        <div class="info-container mt-4">
            <div class="margin-desktop">
                <div>
                    <div>{{'basic.currentTimeSlot' | translate}}</div>
                    <p class="fw-bold">{{returnDate(startTime)}} {{returnTime(startTime)}} - {{returnTime(endTime)}}</p>
                </div>

            </div>
            <div>
                <p class="mt-3">{{'basic.metersInformation' | translate}}</p>
                <p class="fw-bold">{{'basic.meteringPointNumber' | translate}} <br> {{locationNr}}</p>
                <p class="mt-3 fw-bold">{{'basic.meteringPointAddress' | translate}} <br> {{address}}</p>
            </div>
        </div>
        <hr>
        <div class="desktop-60">
            {{'endUserConf.newSlot1' | translate}}
          {{returnDate(calendarStart)}} - {{returnDate(calendarEnd)}}.
            {{'endUserConf.newSlot2' | translate}}
        </div>
        <div class="d-flex flex-wrap slot-container">
            <div class="day-container" *ngFor="let item of slotDays | keyvalue">
                <h6 class="day-name">{{returnDateString(item.key)}}</h6>
                <div class="slots">
                    <button
                        class="btn btn-slot"
                        *ngFor="let slot of item.value"
                        id="{{slot.id}}"
                        [ngClass]="{
                            'chosen-slot': slot.active,
                            'disabled-slot': !isSlotActive(slot)
                        }"
                        (click)="isSlotActive(slot) ? selectSlot(slot.id) : null">
                        {{returnTime(slot.starttime)}} - {{returnTime(slot.endtime)}}
                    </button>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div class="gray-text">
                 {{'endUserConf.phone' | translate}}
             </div>
             <input type="phone" class="form-control phone-width" placeholder="+123 456 789" [(ngModel)]="phoneNr"  (ngModelChange)="checkPhoneNumber()">
             <div [hidden]="!invalidPhoneNumber" class="red-text">{{'call-service.phoneNumberInvalid' | translate }}</div>
             <button class="btn btn-green mt-3" (click)="confirmWorkorderWithChange()">{{'basic.confirm' | translate}}</button>
         </div>
        <hr>
<!--      Contact details-->
        <div *ngIf="contractor_type==1">
          <h4>{{'endUserConf.contractors.1.noSuitable' | translate}}</h4>
          <p>{{'endUserConf.contractors.1.contact' | translate}}</p>
          <div>{{'endUserConf.contractors.1.customerService' | translate}}<br>
            <a href="tel:{{'endUserConf.contractors.1.customerServicePhoneTel' | translate}}">{{'endUserConf.contractors.1.customerServicePhone' | translate}}</a>
          </div>
          <div>{{'endUserConf.contractors.1.customerService2' | translate}}</div>
        </div>
        <div *ngIf="contractor_type==2">
            <h4>{{'endUserConf.contractors.2.noSuitable' | translate}}</h4>
            <p>{{'endUserConf.contractors.2.contact' | translate}}</p>
            <div>{{'endUserConf.contractors.2.customerService' | translate}}<br>
              <a href="tel:{{'endUserConf.contractors.2.customerServicePhoneTel' | translate}}">{{'endUserConf.contractors.2.customerServicePhone' | translate}}</a>
            </div>
          <div>{{'endUserConf.contractors.2.customerService2' | translate}}</div>
        </div>
      <div *ngIf="contractor_type==3">
        <h4>{{'endUserConf.contractors.3.noSuitable' | translate}}</h4>
        <p>{{'endUserConf.contractors.3.contact' | translate}}</p>
        <div>{{'endUserConf.contractors.3.customerService' | translate}}<br>
          <a href="tel:{{'endUserConf.contractors.3.customerServicePhoneTel' | translate}}">{{'endUserConf.contractors.3.customerServicePhone' | translate}}</a>
        </div>
      </div>
      <div *ngIf="contractor_type==4">
        <h4>{{'endUserConf.contractors.4.noSuitable' | translate}}</h4>
        <p>{{'endUserConf.contractors.4.contact' | translate}}</p>
        <div>{{'endUserConf.contractors.4.customerService' | translate}}<br>
          <a href="tel:{{'endUserConf.contractors.4.customerServicePhoneTel' | translate}}">{{'endUserConf.contractors.4.customerServicePhone' | translate}}</a>
        </div>
      </div>
      <div *ngIf="contractor_type==5">
      </div>
      <div *ngIf="contractor_type==6">
      </div>
    </div>
</div>
