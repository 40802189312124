import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarsService } from '@modules/planner/services/calendars/calendars.service';
import { WorkersService } from '@modules/planner/services/workers/workers.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@shared/services/user/user.service';
import { baseUrl } from '@environments/environment';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { PlannerContractorService } from '@modules/planner/services/planner-contractor/planner-contractor.service';
import { WorkorderService } from '@modules/planner/services/workorder/workorder.service';
import { SlotDetails, SlotsByDate } from '@shared/models/specified-slot';
import { ToastService } from '@shared/services/toast/toast.service';
import { MapService } from '@modules/planner/services/map/map.service';

@Component({
  selector: 'app-contractor-dashboard',
  templateUrl: './contractor-dashboard.component.html',
  styleUrls: ['./contractor-dashboard.component.scss'],
  providers: [HttpClient]
})
export class ContractorDashboardComponent implements OnInit {

  componentDestroyed$: Subject<boolean> = new Subject()
  allCalendars;
  teams = Array()
  contractorId: any

  monDate: any
  tueDate: any
  wedDate: any
  thuDate: any
  friDate: any
  satDate: any
  sunDate: any
  todaysDate: any
  chosenDate: any
  today: any

  calendarsLoading = true
  weekdays: any
  calendarObjectIsSet = false
  thisDayHover = -1
  hoveredCalendar = -1
  customCalendarObject = {}
  showCalendarsWithSlots = true

  msas: any
  userid: any
  contractorData: any
  contractorName: any
  msaEstimatesLoading = true

  showSpecificSlot: boolean = false
  objectKeys = Object.keys
  currentWorklist: any = null
  contractorEstimates;
  allEstimates;
  allMsaEstimates;

  constructor(
    private http: HttpClient,
    private calendars: CalendarsService,
    private router: Router,
    private datePipe: DatePipe,
    private workersService: WorkersService,
    private userService: UserService,
    private translateService: TranslateService,
    private plannerContractorService: PlannerContractorService,
    private workorderService: WorkorderService,
    private calendarsService: CalendarsService,
    private toastService: ToastService,
    private mapService: MapService
  ) {}

  ngOnInit() {
    this.todaysDate = new Date()
    this.today = this.todaysDate.getDay()
    this.getCalendarsInit()
  }

  getCalendarsInit() {
    this.getCurrentContractorId(() => {
      this.getContractorCalendars()
      this.plannerContractorService.getContractorById(this.contractorId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(data => {
          this.contractorData = data
          this.contractorName = data.name
        })
        .add(() => {
          this.getMsaEstimates()
        })
    })
  }

  getContractorCalendars() {
    this.calendarsLoading = true
    let startdate = this.calendars.dateToYMD(this.monDate)
    let enddate = this.calendars.dateToYMD(this.sunDate)

    this.calendars.getContractorCalendars(this.contractorId, startdate, enddate)
      .subscribe(data => {
        this.allCalendars = data['message']
        this.allCalendars = this.allCalendars.map(item => {
          return { ...item, showCalendar: false };
        });
        this.calendarsLoading = false
      })
  }

  calendarsWithSlotsCount() {
    let slotCalendars = Array()
    for (let i = 0; i < this.allCalendars.length; i++) {
      const calendar = this.allCalendars[i];

      if (calendar.slots.length != 0) {
        slotCalendars.push(i)
      }
    }

    return slotCalendars.length
  }

  calendarsWithNoSlotsCount() {
    let slotCalendars = Array()
    for (let i = 0; i < this.allCalendars.length; i++) {
      const calendar = this.allCalendars[i];

      if (calendar.slots.length == 0) {
        slotCalendars.push(i)
      }
    }

    return slotCalendars.length
  }

  openCalendar(id) {
    let url = '/planner/weekly-planning-map/' + id
    this.router.navigate([url])
  }

  /**
   * Returns the first team found in teamObject
   * @param teamObject
   * @returns
   */
  returnFirstTeam(teamObject) {
    for (var prop in teamObject) {
      return teamObject[prop]
    }
    return null
  }

  /**
   * Returns the key value of teamObject which is the id of the team
   * @param teamObject
   * @returns
   */
  returnFirstTeamId(teamObject) {
    for (var prop in teamObject) {
      return prop
    }
    return null
  }

  /**
   * Takes teamId as parameter and goes through teams.
   * Returns the team size.
   * @param teamId
   * @returns
   */
  returnTeamSize(teamId) {
    for (let i = 0; i < this.teams.length; i++) {
      const element = this.teams[i];
      if (element.id == teamId) {
        if (element.workercount) return element.workercount
        return 0
      }
    }
  }

  /**
   * Returns the team efficiency of certain team
   * @param teamId
   * @returns
   */
  returnTeamEfficiency(teamId) {
    for (let i = 0; i < this.teams.length; i++) {
      const element = this.teams[i];
      if (element.id == teamId) {
        return element.efficiency
      }
    }
  }

  /**
   * Takes efficiency as parameter and returns percentage as result
   * @param efficiency
   * @returns
   */
  getEfficiencyPercentage(efficiency) {
    if (efficiency >= 1.0) {
      return (efficiency - 1) * 100
    } else {
      return efficiency * 100
    }
  }

  /**
   * Gets teams and assigns them into a variable
   */
  getWorkerTeams() {
    this.workersService.getWorkerteams().subscribe(data => {
      this.teams = data
    })
  }

  /**
   * Sets the outer color of the team efficiency circle
   * @param efficiency
   * @returns
   */
  getOuterColor(efficiency) {
    if (efficiency >= 1.0) {
      return '#05791F'
    } else {
      return '#FF0000'
    }
  }

  /**
   * Sets the inner color of the team efficiency circle
   * @param efficiency
   * @returns
   */
  getInnerColor(efficiency) {
    if (efficiency >= 1.0) {
      return '#10A231'
    } else {
      return '#ffcccb'
    }
  }

  /**
   * Sets current week from today. Used only in onload/return to today.
   */
  setCurrentWeek(value) {

    let dateValue = new Date(value)

    if (this.todaysDate.setHours(0, 0, 0, 0) == dateValue.setHours(0, 0, 0, 0)) {
      this.chosenDate = this.todaysDate
      this.today = this.todaysDate.getDay()
    } else {
      this.chosenDate = dateValue
      this.today = null
    }

    if (this.today == 1 || !this.today) {
      this.monDate = this.chosenDate
      this.tueDate = this.getDateXDaysAgo(-1, this.chosenDate)
      this.wedDate = this.getDateXDaysAgo(-2, this.chosenDate)
      this.thuDate = this.getDateXDaysAgo(-3, this.chosenDate)
      this.friDate = this.getDateXDaysAgo(-4, this.chosenDate)
      this.satDate = this.getDateXDaysAgo(-5, this.chosenDate)
      this.sunDate = this.getDateXDaysAgo(-6, this.chosenDate)
    }
    else if (this.today == 2) {
      this.monDate = this.getDateXDaysAgo(1, this.chosenDate)
      this.tueDate = this.chosenDate
      this.wedDate = this.getDateXDaysAgo(-1, this.chosenDate)
      this.thuDate = this.getDateXDaysAgo(-2, this.chosenDate)
      this.friDate = this.getDateXDaysAgo(-3, this.chosenDate)
      this.satDate = this.getDateXDaysAgo(-4, this.chosenDate)
      this.sunDate = this.getDateXDaysAgo(-5, this.chosenDate)
    }
    else if (this.today == 3) {
      this.monDate = this.getDateXDaysAgo(2, this.chosenDate)
      this.tueDate = this.getDateXDaysAgo(1, this.chosenDate)
      this.wedDate = this.chosenDate
      this.thuDate = this.getDateXDaysAgo(-1, this.chosenDate)
      this.friDate = this.getDateXDaysAgo(-2, this.chosenDate)
      this.satDate = this.getDateXDaysAgo(-3, this.chosenDate)
      this.sunDate = this.getDateXDaysAgo(-4, this.chosenDate)
    }
    else if (this.today == 4) {
      this.monDate = this.getDateXDaysAgo(3, this.chosenDate)
      this.tueDate = this.getDateXDaysAgo(2, this.chosenDate)
      this.wedDate = this.getDateXDaysAgo(1, this.chosenDate)
      this.thuDate = this.chosenDate
      this.friDate = this.getDateXDaysAgo(-1, this.chosenDate)
      this.satDate = this.getDateXDaysAgo(-2, this.chosenDate)
      this.sunDate = this.getDateXDaysAgo(-3, this.chosenDate)
    }
    else if (this.today == 5) {
      this.monDate = this.getDateXDaysAgo(4, this.chosenDate)
      this.tueDate = this.getDateXDaysAgo(3, this.chosenDate)
      this.wedDate = this.getDateXDaysAgo(2, this.chosenDate)
      this.thuDate = this.getDateXDaysAgo(1, this.chosenDate)
      this.friDate = this.chosenDate
      this.satDate = this.getDateXDaysAgo(-1, this.chosenDate)
      this.sunDate = this.getDateXDaysAgo(-2, this.chosenDate)
    }
    else if (this.today == 6) {
      this.monDate = this.getDateXDaysAgo(5, this.chosenDate)
      this.tueDate = this.getDateXDaysAgo(4, this.todaysDate)
      this.wedDate = this.getDateXDaysAgo(3, this.todaysDate)
      this.thuDate = this.getDateXDaysAgo(2, this.todaysDate)
      this.friDate = this.getDateXDaysAgo(1, this.todaysDate)
      this.satDate = this.todaysDate
      this.sunDate = this.getDateXDaysAgo(-1, this.todaysDate)
    }
    else if (this.today == 7) {
      this.monDate = this.getDateXDaysAgo(6, this.chosenDate)
      this.tueDate = this.getDateXDaysAgo(5, this.chosenDate)
      this.wedDate = this.getDateXDaysAgo(4, this.chosenDate)
      this.thuDate = this.getDateXDaysAgo(3, this.chosenDate)
      this.friDate = this.getDateXDaysAgo(2, this.chosenDate)
      this.satDate = this.getDateXDaysAgo(1, this.chosenDate)
      this.sunDate = this.chosenDate
    }

    this.weekdays = Array()

    this.weekdays.push(this.monDate)
    this.weekdays.push(this.tueDate)
    this.weekdays.push(this.wedDate)
    this.weekdays.push(this.thuDate)
    this.weekdays.push(this.friDate)
    this.weekdays.push(this.satDate)
    this.weekdays.push(this.sunDate)

    if (this.contractorId) this.getContractorCalendars()

  }

  /**
   * Returns the name of given date in short form.
   * @param date
   * @returns
   */
  getDayName(date) {
    if (this.translateService.currentLang === 'en') return date.toLocaleDateString('en-GB', { weekday: 'short' })
    else if (this.translateService.currentLang === 'de') return date.toLocaleDateString('de-DE', { weekday: 'short' })
    else return date.toLocaleDateString('en-GB', { weekday: 'short' })
  }

  /**
   * Takes a number and a date as variable and returns.
   * If number is 7 and date is Christmas, returns date 7 days before Christmas.
   * @param numOfDays
   * @param date
   * @returns
   */
  getDateXDaysAgo(numOfDays, date = new Date()) {
    const daysAgo = new Date(date.getTime());

    daysAgo.setDate(date.getDate() - numOfDays);

    daysAgo.setHours(0, 0, 0, 0);

    let newToday = new Date()
    newToday.setHours(0, 0, 0, 0)
    if (newToday.getTime() == daysAgo.getTime()) this.today = daysAgo.getDay()

    return daysAgo;
  }

  hoverHandler(day, calendar) {
    this.hoveredCalendar = calendar
    this.thisDayHover = day
  }

  /**
   * Gets current contractor id and calls a callback method to tell that it has finished
   * @param cb
   */
  getCurrentContractorId(cb) {
    this.userService.getUserInfo().subscribe(
      data => {
        this.contractorId = data.current_contractor
        this.userid = data.id

        // cb() = callback to let the other function know that this one is finished
        cb()
      }
    );
  }

  /**
   * Sets locked status for specific day
   * @param day
   * @param slots
   * @returns
   */
  getDayLocked(day, slots) {
    let daystring = this.calendars.dateToYMD(day)

    if (slots[daystring]) {
      if (slots[daystring].locked == 1) {
        return true
      } else if (slots[daystring].locked == 0) {
        return false
      }
    }
    return false
  }

  /**
   * Gets all slot accesstype minutes and returns the days overall booking value as percentage
   * @param day
   * @param slots
   * @returns
   */
  getOverallStatus(day, slots) {
    let daystring = this.calendars.dateToYMD(day)

    if (slots[daystring]) {
      let workMinutes = 0
      let totalPercentage = 0
      if (slots[daystring].capacity.access_type_1) {
        workMinutes = workMinutes + slots[daystring].capacity.access_type_1
      }
      if (slots[daystring].capacity.access_type_2) {
        workMinutes = workMinutes + slots[daystring].capacity.access_type_2
      }
      if (slots[daystring].capacity.access_type_3) {
        workMinutes = workMinutes + slots[daystring].capacity.access_type_3
      }
      if (slots[daystring].capacity.access_type_) {
        workMinutes = workMinutes + slots[daystring].capacity.access_type_
      }
      totalPercentage = workMinutes / slots[daystring].capacity.total
      let rounded = Math.round(totalPercentage * 1000) / 10
      return rounded + '%'
    }
    return '0%'
  }

  /**
   * Gets person/key needed access types and returns the days fixed booking value as percentage
   * @param day
   * @param slots
   * @returns
   */
  getFixedStatus(day, slots) {
    let daystring = this.calendars.dateToYMD(day)

    if (slots[daystring]) {
      let workMinutes = 0
      let totalPercentage = 0

      if (slots[daystring].capacity.access_type_2) {
        workMinutes = workMinutes + slots[daystring].capacity.access_type_2
      }
      if (slots[daystring].capacity.access_type_3) {
        workMinutes = workMinutes + slots[daystring].capacity.access_type_3
      }
      totalPercentage = workMinutes / slots[daystring].capacity.total
      let rounded = Math.round(totalPercentage * 1000) / 10
      return rounded + '%'
    }
    return '0%'
  }

  /**
   * Gets free access types and returns the days free booking value as percentage
   * @param day
   * @param slots
   * @returns
   */
  getFreeStatus(day, slots) {
    let daystring = this.calendars.dateToYMD(day)

    if (slots[daystring]) {
      let workMinutes = 0
      let totalPercentage = 0
      if (slots[daystring].capacity.access_type_1) {
        workMinutes = workMinutes + slots[daystring].capacity.access_type_1
      }
      totalPercentage = workMinutes / slots[daystring].capacity.total
      let rounded = Math.round(totalPercentage * 1000) / 10
      return rounded + '%'
    }
    return '0%'
  }

  /**
   * Gets NULL access types and returns the days unknown booking value as percentage
   * @param day
   * @param slots
   * @returns
   */
  getOtherStatus(day, slots) {
    let daystring = this.calendars.dateToYMD(day)

    if (slots[daystring]) {
      let workMinutes = 0
      let totalPercentage = 0
      if (slots[daystring].capacity.access_type_) {
        workMinutes = workMinutes + slots[daystring].capacity.access_type_
      }
      totalPercentage = workMinutes / slots[daystring].capacity.total
      let rounded = Math.round(totalPercentage * 1000) / 10
      return rounded + '%'
    }
    return '0%'
  }

  /**
   * Returns a certain days workorder count
   * @param day
   * @param slots
   * @returns
   */
  getOrderCount(day, slots) {
    let daystring = this.calendars.dateToYMD(day)
    if (slots[daystring]) return slots[daystring].ordercount
  }

  /**
   * Returns a certain days interrupted and total workorder count.
   * example return '4/20'
   * @param day
   * @param slots
   * @returns
   */
  getInterrupted(day, slots) {
    let daystring = this.calendars.dateToYMD(day)

    if (slots[daystring]) {
      let reported = slots[daystring].state_6
      let overall = slots[daystring].ordercount

      if (typeof reported === 'undefined') reported = 0
      return reported + '/' + overall
    }

    return ''
  }

  /**
   * Returns a certain days successfully reported and total workorder count.
   * example return '4/20'
   * @param day
   * @param slots
   * @returns
   */
  getReported(day, slots) {
    let daystring = this.calendars.dateToYMD(day)

    if (slots[daystring]) {
      let reported = slots[daystring].state_4
      let overall = slots[daystring].ordercount

      if (typeof reported === 'undefined') reported = 0
      return reported + '/' + overall
    }

    return ''
  }

  /**
   * Returns a certain days confirmed and total workorder count.
   * example return '4/20'
   * @param day
   * @param slots
   * @returns
   */
  getConfirmed(day, slots) {
    let daystring = this.calendars.dateToYMD(day)

    if (slots[daystring]) {
      let confirmed = slots[daystring].confirmedcount
      let overall = slots[daystring].ordercount

      if (typeof confirmed === 'undefined') confirmed = 0
      return confirmed + '/' + overall
    }

    return '?/?'
  }

  /**
   * Returns true if all orders are confirmed
   * @param day
   * @param slots
   * @returns
   */
  checkAllConfirmed(day, slots) {
    let daystring = this.calendars.dateToYMD(day)

    if (slots[daystring]) {
      let confirmed = slots[daystring].confirmedcount
      let overall = slots[daystring].ordercount

      if (typeof confirmed === 'undefined') confirmed = 0
      if (confirmed == overall) return true
    }

    return false
  }

  /**
   * Goes through this weeks days and checks if calendar has any days with slots that match that day.
   * @param slots
   * @returns
   */
  hasSlotsThisWeek(slots) {
    // Return true immediately if not filtering by slots
    if (!this.showCalendarsWithSlots) return true;

    // Use Array.prototype.some for an early exit upon finding a valid slot
    return this.weekdays.some(weekday => {
      const day = this.calendars.dateToYMD(weekday);

      // Check if any slot's date matches the current day's date
      return Object.keys(slots).some(date => {
        const slotDay = this.calendars.dateToYMD(new Date(date));
        return day === slotDay;
      });
    });
  }

  /**
   * Converts a 2022-05-01 datestring to 01.05.2022 datestring.
   * @param datestring
   * @returns
   */
  dashesToDots(datestring) {
    var newdate = datestring.split("-").reverse().join("-")

    newdate = newdate.replace(/\-/g, '.')

    return newdate
  }

  /**
   * Downloads workorders dump for active week. Also downloads logs in separate file.
   * @param datestring
   * @returns
   */
  downloadWorkorders() {
    let startdate = this.datePipe.transform(new Date(this.weekdays[0]), 'yyyy-MM-dd')?.toString() ?? ""
    let enddate = this.datePipe.transform(new Date(this.weekdays[6]), 'yyyy-MM-dd')?.toString() ?? ""

    this.http.get(`${baseUrl}/api/contractorWorkordersCsv/${this.contractorId}`,
      {
        responseType: 'text',
        params: {
          'startdate': startdate,
          'enddate': enddate
        }
      }).subscribe({
        next: response => {
          this.exportCSV(response, 'workorders_' + startdate + '-' + enddate);
        },
        error: error => {
          if (error['message'].includes('404 Not Found')) {
            if (!Swal.isVisible())
              Swal.fire(
                this.translateService.instant('planner.contractorDashboard.errorMessages.404')
              );
          } else if (error['message'].includes('400 Bad Request')) {
            if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.contractorDashboard.errorMessages.400_1'));
          } else {
            if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.contractorDashboard.errorMessages.unknown'));
          }
        }
      })

    this.http.get(`${baseUrl}/api/contractorWorkordersLogsCsv/${this.contractorId}`,
      {
        responseType: 'text',
        params: {
          'startdate': startdate,
          'enddate': enddate
        }
      }).subscribe({
        next: response => {
          this.exportCSV(response, 'workorderslogs_' + startdate + '-' + enddate);
        },
        error: error => {
          if (error['message'].includes('404 Not Found')) {
            if (!Swal.isVisible())
              Swal.fire(
                this.translateService.instant('planner.contractorDashboard.errorMessages.404_2')
              );
          } else if (error['message'].includes('400 Bad Request')) {
            if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.contractorDashboard.errorMessages.400_2'));
          } else {
            if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.contractorDashboard.errorMessages.unknown'));
          }
        }
      })

  }

  exportCSV(response, name) {
    let fileName = name + '.csv';
    let fileContent = response;

    const file = new Blob([fileContent], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = fileName;
    link.click();
    link.remove();
  }

  /**
   * Fetches the msa-estimates for the contractor and builds usable variables from that.
   * Calls a few functions to build data for the UI after it finishes.
   */
  getMsaEstimates() {
    this.workorderService.getMsas()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.msas = data
          let allMsaEstimates = {}
          for (let i = 0; i < data.length; i++) {
            this.workorderService.getWorkordersByMsa(this.msas[i].id)
              .pipe(takeUntil(this.componentDestroyed$))
              .subscribe(wodata => {
                this.msas[i].notConfirmed = wodata.notConfirmed
                this.msas[i].done = wodata.done
                this.msas[i].interrupted = wodata.interrupted
                this.msas[i].total = wodata.total
              })
            allMsaEstimates[data[i].id] = data[i].default_time_parameters
          }
          if (data.length == 0) this.msaEstimatesLoading = false
          this.allMsaEstimates = allMsaEstimates
        }
      ).add(() => {
        this.msaEstimatesLoading = false
      })
  }

  setProgress(notConfirmed: number, total: number) {
    let progress = parseInt((notConfirmed / total * 100).toFixed(2))
    let color = "#0058ff"
    // let color = "#FF9F0A"
    // if(progress >= 90 && progress <= 100) {
    //     color = "#10A231"
    // } else if(progress > 100) {
    //     color = "#0058FF"
    // }

    let styles = {
      'width': progress + '%',
      'background-color': color
    };

    return styles
  }

  toggleSpecificSlot(value: boolean, calendarNumber: number) {
    if (value) {
      if (!this.allCalendars[calendarNumber].specificData) {
        let calendarId = this.allCalendars[calendarNumber].id
        let startTime = this.datePipe.transform(this.monDate, 'yyyy-MM-dd')
        let endTime = this.datePipe.transform(this.sunDate, 'yyyy-MM-dd')

        if (startTime && endTime) {
          this.calendarsService.getCalendarSpecifiedSlots(calendarId, startTime, endTime)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(data => {
              this.allCalendars[calendarNumber].specificData = data
            })
        }
      }
      this.allCalendars[calendarNumber].showSpecificSlots = true
    } else {
      this.allCalendars[calendarNumber].showSpecificSlots = false
    }
  }

  getFormattedDate(day) {
    return this.datePipe.transform(day, 'yyyy-MM-dd') || ''
  }

  /**
   * Navigate to reports page with workorders in query params. Don't navigate if no data is found.
   * @param data data including interrupted and reported workorders.
   */
  openWorkorders(data) {
    let workorders: number[] = [...data.confirmed, ...data.notconfirmed]
    if (workorders.length > 0) {
      this.router.navigate(['/planner/reports'], { queryParams: { workorders: workorders.toString() } })
    }
  }

  openWorkordersDaily(day, calendarId) {
    day.setHours(0, 1)
    let startTime = this.datePipe.transform(day, 'yyyy-MM-dd')
    day.setHours(11, 59)
    let endTime = this.datePipe.transform(day, 'yyyy-MM-dd')
    this.openWorkordersApiCall(startTime, endTime, calendarId)
  }

  openWorkordersWeekly(calendarId) {
    let start = this.datePipe.transform(this.monDate, 'yyyy-MM-dd')
    let end = this.datePipe.transform(this.sunDate, 'yyyy-MM-dd')
    this.openWorkordersApiCall(start, end, calendarId)
  }

  openWorkordersApiCall(startTime, endTime, calendarId) {
    if (startTime && endTime) {
      this.calendarsService.getCalendarSpecifiedSlots(calendarId, startTime, endTime)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(data => {
          let workorders: number[] = []
          Object.keys(data).forEach(date => {
            const slotsByDate: SlotsByDate = data[date];
            Object.keys(slotsByDate).forEach(slotId => {
              const slotDetails: SlotDetails = slotsByDate[slotId];
              if (slotDetails.confirmed.length > 0) workorders = [...workorders, ...slotDetails.confirmed]
              if (slotDetails.notconfirmed.length > 0) workorders = [...workorders, ...slotDetails.notconfirmed]
            });
          });
          if (workorders.length > 0) {
            this.router.navigate(['/planner/reports'], { queryParams: { workorders: workorders.toString() } })
          } else Swal.fire(this.translateService.instant('planner.reports.noworkorders'))
        });
    }
  }

  getWorklist(calendar) {
    this.allCalendars.forEach(element => {
      if (element.id === calendar.id) {
        element.showWorklist = !element.showWorklist
        element.calendarSpinner = true
      } else {
        element.showWorklist = false
        element.calendarSpinner = false
      }
    });
    if (calendar.showWorklist) {
      this.calendarsService.getCalendarById(calendar.id).subscribe({
        next: data => {
          console.log(data)
          this.currentWorklist = data.worklist
          calendar.calendarSpinner = false

        },
        error: err => {
          this.toastService.sendToast(false, this.translateService.instant('planner.contractorDashboard.errorMessages.worklist'))
          console.log(err.toString())
        }
      })
    }
  }

  getNotDoneWorklist() {
    let worklist = this.currentWorklist
    let state1 = 0
    let state2 = 0
    if (worklist.hasOwnProperty('state_1')) state1 = worklist.state_1
    if (worklist.hasOwnProperty('state_2')) state2 = worklist.state_2
    if (state1 !== 0 || state2 !== 0) return state1 + state2
    else return 0

  }

  openWorklist(message: string) {
    let filter: number[] | null = null
    if (message === 'reported') filter = [3]
    else if (message === 'interrupted') filter = [4]
    else if (message === 'notDone') filter = [1, 2]

    let workorders = []
    if (filter) {
      workorders = this.currentWorklist.workorders.filter(wo => filter?.includes(wo.state)).map(wo => wo.workorder_id)
    } else {
      workorders = this.currentWorklist.workorders.map(wo => wo.workorder_id)
    }
    if (workorders.length > 0) {
      this.router.navigate(['/planner/reports'], { queryParams: { workorders: workorders.toString() } })
    }

  }

}
