<div class="page-container">
  <div class="page-content">
    <div class="row">
      <div class="col-12">
        <div class="bg-white p-4">
          <h3>{{ 'basic.reports' | translate }}</h3>
          <h5>{{ 'planner.reports.searchReports' | translate }}</h5>
          <div class="form-group mt-4">
            <div class="row align-items-end g-0">
              <div class="col-4">
                <label for="search-input">{{ 'planner.reports.searchReports' | translate }} {{'planner.reports.separateWithComma' | translate}}</label>
                <input type="text" id="search-input" [(ngModel)]="searchValue"
                  class="form-control w-100">
              </div>
              <div ngbDropdown class="col-auto ms-2">
                <button type="button" class="btn btn-outline-secondary" id="dropdownInputType"
                  ngbDropdownToggle>{{ selectedSearchType.text }}</button>
                <div ngbDropdownMenu aria-labelledby="dropdownInputType">
                  <div *ngFor="let inputType of searchTypes">
                    <button ngbDropdownItem type="button" (click)="selectedSearchType = inputType">{{inputType.text}}</button>
                  </div>
                </div>
              </div>
              <div class="col-auto ms-2">
                <button type="button" class="btn btn-primary" (click)="searchReports()">{{'call-service.dashboard.search' | translate }}</button>
              </div>
              <div class="col-auto ms-2">
                <button type="button" class="btn btn-primary" (click)="resetSearch()">{{'planner.reports.resetSearches' | translate}}</button>
              </div>
            </div>
          </div>
          <load-spinner [hidden]="!listSpinner"></load-spinner>
          <div class="col-12" [hidden]="selectedOrders.length == 0" >
            <button *ngIf="!projectId" data-bs-toggle="modal" data-bs-target="#rescheduleModal" class="btn btn-primary">{{'planner.reports.editSelected' | translate }}</button>
            <button *ngIf="!projectId" data-bs-toggle="modal" data-bs-target="#exportModal"  class="btn btn-primary mx-1">{{'planner.reports.export' | translate}}</button>
            <button *ngIf="!projectId" data-bs-toggle="modal" data-bs-target="#changeContractorModal"  class="btn btn-primary">{{'planner.reports.changeContractor' | translate}}</button>
            <button *ngIf="projectId" data-bs-toggle="modal" data-bs-target="#confirmModal"  class="btn btn-primary mt-2 mt-sm-0">{{'basic.confirm' | translate}}</button>
          </div>
          <load-spinner *ngIf="loading"></load-spinner>
          <div class="mt-10" *ngIf="!loading && workorders.length > 0">
              <div class="row">
                <div class="col-md-12">
                  <ag-grid-angular
                    style="width: 100%;"
                    class="ag-theme-alpine"
                    [rowData]="workorders"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    [pagination]="scaleOnSizePagination"
                    [paginationPageSize]="paginationPageSize"
                    [paginationPageSizeSelector]="paginationPageSizes"
                    rowSelection="multiple"
                    domLayout="autoHeight"
                    [localeText]="localeText"
                    [autoSizeStrategy]="autoSizeStrategy"
                    (gridReady)="gridReady($event)"
                    (rowSelected)="onSelectionChanged($event)"
                    (columnMoved)="onGridStateChanged()"
                    (filterChanged)="onGridStateChanged()">
                  </ag-grid-angular>
                </div>
            </div>
          </div>
          <div *ngIf="!loading && workorders.length == 0">
            {{'planner.reports.noworkorders' | translate}}
          </div>
        </div>

        <!-- BASIC MODAL -->
          <div  class="modal modalbox" id="rescheduleModal" tabindex="1">
            <div class="modal-dialog modal-lg modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <h4>
                    <span id="reschedule-title" class="ms-1">{{'planner.reports.editSelected' | translate}}</span>
                  </h4>
                  <div class="dual-container">
                    <div class="left">
                      <div class="gray-text mt-4">{{'planner.reports.selectedWorkorders' | translate}}: </div>
                      <div class="fw-bold">{{selectedOrders.length}}</div>
                    </div>
                    <div class="right">
                      <div class="blue" *ngIf="selectedStatus.id !== 0">
                        <span>{{'planner.reports.statusModified' | translate}}</span>
                        <button class="btn btn-danger btn-sm ms-2" (click)="resetStatus()">X</button>
                      </div>
                      <div class="blue" *ngIf="selectedPhase.id !== 0">
                        <span>{{'planner.reports.phaseModified' | translate}}</span>
                        <button class="btn btn-danger btn-sm ms-2" (click)="resetPhase()">X</button>
                      </div>
                      <div class="blue" *ngIf="notesForInstaller">
                        <span>{{'planner.reports.notesForInstallerModified' | translate}}</span>
                        <button class="btn btn-danger btn-sm ms-2" (click)="notesForInstaller = null">X</button>
                      </div>
                      <div class="blue" *ngIf="additionalInformation">
                        <span>{{'planner.reports.additionalInfoModified' | translate}}</span>
                        <button class="btn btn-danger btn-sm ms-2" (click)="additionalInformation = null">X</button>
                      </div>
                      <div class="blue" *ngIf="selectedAccessType.id !== 0">
                        <span>{{'planner.reports.accessTypeModified' | translate }}</span>
                        <button class="btn btn-danger btn-sm ms-2" (click)="resetAccessType()">X</button>
                      </div>
                      <div class="blue" *ngIf="selectedMsa.id !== 0">
                        <span>{{'planner.reports.msaModified' | translate }}</span>
                        <button class="btn btn-danger btn-sm ms-2" (click)="resetMsa()">X</button>
                      </div>
                      <div class="blue" *ngIf="selectedConfirmed !== null">
                        <span>{{'planner.reports.confirmedModified' | translate }}</span>
                        <button class="btn btn-danger btn-sm ms-2" (click)="selectedConfirmed == null">X</button>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4">
                    <form>
                      <div class="form-group">
                        <div ngbDropdown class="d-inline-block mb-3">
                          <button type="button" class="btn btn-outline-secondary border-color" id="dropdownInputType"
                            ngbDropdownToggle>{{ selectedInput }}</button>
                          <div ngbDropdownMenu aria-labelledby="dropdownInputType">
                            <div *ngFor="let inputType of inputTypes">
                              <button ngbDropdownItem type="button" (click)="changeInputType(inputType)">{{ inputType.text
                                }}</button>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="formTab === 1">
                          <div class="gray-text">{{'planner.reports.selectNewStatus' | translate}}</div>
                          <div ngbDropdown class="d-inline-block mb-3">
                            <button type="button" class="btn btn-primary border-color" id="dropdownStatus"
                              ngbDropdownToggle>{{ selectedStatus.definition }}</button>
                            <div ngbDropdownMenu aria-labelledby="dropdownStatus">
                              <div *ngFor="let status of statuses">
                                <button ngbDropdownItem type="button" (click)="selectedStatus = status">{{ status.definition }}</button>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="selectedStatus.state === 6" class="d-flex flex-column flex-md-row">
                            <textarea class="col-12 col-md-6" [(ngModel)]="interruptReason" [ngModelOptions]="{standalone: true}" rows="4" placeholder="{{'basic.interruptReason' | translate}}"></textarea>
                            <!--<div class="justify-content-center ms-1"><input type="text" [(ngModel)]="interruptCode" [ngModelOptions]="{standalone: true}" placeholder="{{'basic.interruptCode' | translate}}"></div>-->
                            <div ngbDropdown class="d-inline-block ms-md-2 ms-0 col-12 col-md-6 mt-2 mt-md-0">
                              <button type="button" class="btn btn-primary border-color" id="dropdownInterruptCode"
                                ngbDropdownToggle>
                                <span *ngIf="interruptCode">{{ interruptCode.definition }}</span>
                                <span *ngIf="!interruptCode">{{'planner.reports.selectInterruptCode' | translate}}</span>
                              </button>
                              <div ngbDropdownMenu aria-labelledby="dropdownStatus">
                                <div *ngFor="let interrupt of interruptOptions">
                                  <button ngbDropdownItem type="button" (click)="interruptCode = interrupt">{{ interrupt.definition }}</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="gray-text" *ngIf="selectedStatus.state === 1">{{'planner.reports.rescheduleInfo' | translate}}</div>
                        </div>
                        <div *ngIf="formTab === 2">
                          <div class="gray-text">{{'planner.reports.typeNotesForInstaller' | translate}}</div>
                          <textarea id="notesForInstaller" class="form-control" [(ngModel)]="notesForInstaller" rows="4"
                            [ngModelOptions]="{standalone: true}" placeholder="{{'call-service.notesForInstaller' | translate}}"></textarea>
                        </div>
                        <div *ngIf="formTab === 3">
                          <div class="gray-text">{{'planner.reports.typeGeneralNotes' | translate}}</div>
                          <textarea id="additionalInformation" class="form-control" [(ngModel)]="additionalInformation" rows="4"
                            [ngModelOptions]="{standalone: true}" placeholder="{{'basic.additionalInformation' | translate}}"></textarea>
                        </div>
                        <div *ngIf="formTab === 4">
                          <div class="gray-text">{{'planner.reports.selectNewAccessType' | translate}}</div>
                          <div ngbDropdown class="d-inline-block mb-3">
                            <button type="button" class="btn btn-primary border-color" id="dropdownAccessType"
                              ngbDropdownToggle>{{ selectedAccessType.description }}</button>
                            <div ngbDropdownMenu aria-labelledby="dropdownStatus">
                              <div *ngFor="let accessType of accessTypes">
                                <button ngbDropdownItem type="button" (click)="selectedAccessType = accessType">{{ accessType.description }}</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="formTab === 5">
                          {{'planner.reports.confirmed' | translate}}?
                          <form>
                              <label>
                                  <input type="radio" checked name="confirmation" [(ngModel)]="selectedConfirmed" value="1">
                                  {{'basic.yes' | translate}}
                              </label>
                              <br>
                              <label>
                                  <input type="radio" name="confirmation" [(ngModel)]="selectedConfirmed" value="0">
                                  {{'basic.no' | translate}}
                              </label>
                          </form>
                        </div>
                        <div *ngIf="formTab === 6">
                          <div class="gray-text">{{'planner.reports.selectNewMsa' | translate}}</div>
                          <div ngbDropdown class="d-inline-block mb-3">
                            <button type="button" class="btn btn-primary border-color" id="dropdownMsa"
                              ngbDropdownToggle>{{ selectedMsa.name }}</button>
                            <div ngbDropdownMenu aria-labelledby="dropdownStatus">
                              <div ngbDropdownItem (click)="setMsaToNull()">{{'basic.noMsa' | translate}}</div>
                              <div *ngFor="let msa of msas">
                                <button ngbDropdownItem type="button" (click)="selectedMsa = msa">{{ msa.name }}</button>
                              </div>
                            </div>
                          </div>
                          <div class="gray-text" *ngIf="selectedMsa.id !== 0">{{'planner.reports.rescheduleInfoMsa' | translate}}</div>
                        </div>
                        <div *ngIf="formTab === 7">
                          <div class="gray-text">{{'planner.reports.selectNewPhase' | translate}}</div>
                          <div ngbDropdown class="d-inline-block mb-3">
                            <button type="button" class="btn btn-primary border-color" id="dropdownPhase"
                              ngbDropdownToggle>{{ selectedPhase.name }}</button>
                            <div ngbDropdownMenu aria-labelledby="dropdownPhase">
                              <div *ngFor="let phase of phases">
                                <button ngbDropdownItem type="button" (click)="selectedPhase = phase">{{ phase.name }}</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button id="modal-submit" type="button" [disabled]="disabledbutton" (click)="doScheduling()"  class="btn btn-primary mt-2">{{'basic.submit' | translate }}</button>
                      <button id="closeModalButton" data-toggle="modal" #closeModalButton data-target="#rescheduleModal" class="btn btn-secondary mt-2 ms-2" data-bs-dismiss="modal" (click)="clearWorkorderData()">{{'basic.close' | translate}}</button>
                    </form>
                  </div>
                </div>
            </div>
          </div>
      </div>

      <!-- EXPORT MODAL -->
      <div  class="modal modalbox" id="exportModal" tabindex="-1" #exportModal>
          <div class="modal-dialog modal-lg modal-dialog-centered">
              <div class="modal-content">
                  <div class="modal-body">
                      <h4>
                          <span id="export-title" class="ms-1">{{'planner.reports.exportSelected' | translate}}</span>
                      </h4>
                      <p>{{'planner.reports.selectedWorkorders' | translate}}: {{selectedOrders.length}}</p>
                      <p>{{'planner.reports.exportInfo' | translate}}</p>
                      <div>
                          <form>
                              <div class="form-group">
                                  <div *ngFor="let col of allColumns">
                                      <input class="form-check-input mr-1" type="checkbox" [id]="col.colId" [(ngModel)]="col.selected" [name]="col.colId" [ngModelOptions]="{standalone: true}"/>
                                      <label [for]="col.colId"> {{ col.headerName | translate }}</label>
                                  </div>
                              </div>
                              <button id="exportmodal-submit" type="button" [disabled]="disabledbutton" (click)="exportToExcel()"  class="btn btn-primary mt-2">{{'basic.submit' | translate }}</button>
                              <button id="closeExportModalButton" data-toggle="modal" #closeModalButton
                              data-target="#exportModal" class="btn btn-secondary ms-1 mt-2"
                              data-bs-dismiss="modal">{{'basic.close' | translate}}</button>
                          </form>
                          
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <!--- CONFIRM MODAL -->
      <div  class="modal modalbox" id="confirmModal" tabindex="-1" #confirmModal>
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <p>{{'planner.reports.selectedWorkorders' | translate}}: <span class="fw-bold">{{selectedOrders.length}}</span></p>
                    <div>
                        <form>
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>{{'basic.oldDeviceID' | translate}}</th>
                                  <th>{{'planner.reports.workorderIdentifier' | translate}}</th>
                                  <th>{{'basic.project' | translate}}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let workorder of selectedOrders">
                                  <td>
                                    <span *ngIf="workorder.old_device_id">{{workorder.old_device_id}}</span>
                                    <span *ngIf="!workorder.old_device_id">{{'basic.missing' | translate }}</span>
                                  </td>
                                  <td>
                                    <span *ngIf="workorder.identifier">{{workorder.identifier}}</span>
                                    <span *ngIf="!workorder.identifier">{{'basic.missing' | translate }}</span>
                                  </td>
                                  <td>
                                    <span *ngIf="workorder.project_identifier">{{workorder.project_identifier}}</span>
                                    <span *ngIf="!workorder.project_identifier">{{'basic.missing' | translate }}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="form-check mt-2">
                              <label class="form-check-label" for="activate">{{'basic.activate' | translate }}</label>
                              <input class="form-check-input" type="radio" id="activate" name="activation" [(ngModel)]="activable" [value]="true">
                            </div>
                            <div class="form-check mb-2">
                              <label class="form-check-label" for="deactivate">{{'basic.deactivate' | translate }}</label>
                              <input class="form-check-input" type="radio" id="deactivate" name="activation" [(ngModel)]="activable" [value]="false">
                            </div>
                            <button type="button" (click)="confirmWorkorders()"  class="btn btn-primary mt-2">{{'basic.submit' | translate }}</button>
                            <button id="closeConfirmModal" data-toggle="modal" #closeModalButton
                            data-target="#confirmModal" class="btn btn-secondary mt-2 ms-1"
                            data-bs-dismiss="modal">{{'basic.close' | translate}}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <!--- CHANGE CONTRACTOR MODAL -->
      <div  class="modal modalbox" id="changeContractorModal" tabindex="-1" #changeContractorModal>
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <p>{{'planner.reports.selectedWorkorders' | translate}}: <span class="fw-bold">{{selectedOrders.length}}</span></p>
                    <div>
                        <form>
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>{{'basic.oldDeviceID' | translate}}</th>
                                  <th>{{'planner.reports.workorderIdentifier' | translate}}</th>
                                  <th>{{'basic.project' | translate}}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let workorder of selectedOrders">
                                  <td>
                                    <span *ngIf="workorder.old_device_id">{{workorder.old_device_id}}</span>
                                    <span *ngIf="!workorder.old_device_id">{{'basic.missing' | translate }}</span>
                                  </td>
                                  <td>
                                    <span *ngIf="workorder.identifier">{{workorder.identifier}}</span>
                                    <span *ngIf="!workorder.identifier">{{'basic.missing' | translate }}</span>
                                  </td>
                                  <td>
                                    <span *ngIf="workorder.project_identifier">{{workorder.project_identifier}}</span>
                                    <span *ngIf="!workorder.project_identifier">{{'basic.missing' | translate }}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            
                            <div ngbDropdown class="d-inline-block mb-2">
                              <button type="button" class="btn btn-outline-secondary border-color" id="dropdownChangeContractor"
                                ngbDropdownToggle>
                                <span *ngIf="selectedContractor">{{ selectedContractor.name }}</span>
                                <span *ngIf="!selectedContractor">{{'planners.selectContractor' | translate}}</span>
                              </button>
                              <div ngbDropdownMenu aria-labelledby="dropdownInputType">
                                <div *ngFor="let contractor of contractors">
                                  <button ngbDropdownItem type="button" (click)="selectedContractor = contractor">{{ contractor.name }}</button>
                                </div>
                              </div>
                            </div><br>

                            <button type="button" class="btn btn-primary mt-2" (click)="changeContractor()" [disabled]="!selectedContractor">{{'basic.submit' | translate }}</button>
                            <button id="closeChangeContractorModal" data-toggle="modal" #closeModalButton
                            data-target="#changeContractorModal" class="btn btn-secondary mt-2 ms-1"
                            data-bs-dismiss="modal">{{'basic.close' | translate}}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
      </div>

    </div>
  </div>
</div>

