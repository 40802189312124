import { KeyValue } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlannerContractorService } from '@modules/planner/services/planner-contractor/planner-contractor.service';
import { WorkorderService } from '@modules/planner/services/workorder/workorder.service';
import { TranslateService } from '@ngx-translate/core';
import { MSA } from '@shared/models/MSA';
import { ToastService } from '@shared/services/toast/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-import-data',
  templateUrl: './import-data.component.html',
  styleUrls: ['./import-data.component.scss']
})
export class ProjectImportComponent implements OnInit {

  componentDestroyed$: Subject<boolean> = new Subject()

  tabindex: any
  userStepCount: any
  excelMatchArray = Array()
  fileName: any
  fileError: any
  fileSelected: any
  dragAreaClass: any
  userId: any
  contractorId: any

  uniqueData: any
  uniqueDataHeaders: any
  uniqueDataRows: any
  excelData: any
  rowsData: any
  customRowsData: any
  dataStartIndex = 0
  headlineIndex = 0
  rowsAreSelected = false

  workorderSelected = false
  transFormerSelected = false

  timeEstimateChosen = false
  oneOrSomeColumnsMatched = false
  latChosen = false
  lonChosen = false
  projectidChosen = false
  addressChosen = false
  zipChosen: boolean = false
  cityChosen: boolean = false

  excelFile: File | undefined

  // Generate coordinates for transformers
  generateTransformers: boolean = true
  generateMeters: boolean = true

  msas: MSA[] = []
  msaId: number = 0
  msaIdChosen: boolean = false

  constructor(
    private contractorService: PlannerContractorService,
    private router: Router,
    private toastService: ToastService,
    private userService: UserService,
    private translateService: TranslateService,
    private workorderService: WorkorderService
  ) { }

  ngOnInit(): void {
    this.tabindex = 1
    this.dragAreaClass = "drag-area";
    this.fileSelected = false
    this.fileName = ""
    this.userStepCount = 2
    this.getUserInfo()
    this.getMsas()
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
  /**
   * Is called when the file inside the fileInput has changed.
   * @param event
   */
  onFileChange(event: any) {
    let files: FileList = event.target.files;
    this.saveFiles(files);
  }

    /**
   * These HostListeners act as eventlisteners.
   * They are listening to different drag events.
   */
     @HostListener("dragover", ["$event"]) onDragOver(event: any) {
      this.dragAreaClass = "drag-area active";
      event.preventDefault();
    }
    @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
      this.dragAreaClass = "drag-area active";
      event.preventDefault();
    }
    @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
      this.dragAreaClass = "drag-area";
      event.preventDefault();
    }
    @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
      this.dragAreaClass = "drag-area";
      event.preventDefault();
    }
    @HostListener("drop", ["$event"]) onDrop(event: any) {
      this.dragAreaClass = "drag-area active";
      event.preventDefault();
      event.stopPropagation();
      if (event.dataTransfer.files) {
        let files: FileList = event.dataTransfer.files;
        this.saveFiles(files);
      }
    }

  /**
   * Handles the file that has been input by the user.
   * Handles the styling related to fileupload.
   * @param files
   */
  saveFiles(files: FileList) {

    if (files.length > 1) {
      this.fileError = "Only one file at time!"
    } else {
      this.excelFile = files[0]
      this.fileSelected = true
      this.dragAreaClass = "drag-area active";
      this.fileError = ""
      this.fileName = files[0].name
    }
  }


  /**
   * Handles the order of stuff that is happening in the UI and acts accordingly with related api calls etc.
   * @param next
   */
  nextStep(next) {
    this.userStepCount = next
    if (next == 3 && this.fileSelected) {
      this.tabindex = 2
      // Send file to backend

      this.contractorService.uploadExcel(this.excelFile!).subscribe(data => {
        this.excelData = data
        this.rowsData = data['rowsdata']
        this.rowsData.splice(0, 0, this.translateService.instant('import.selectRow'))
      })

    }
  }

    /**
   * Reads and handles the selected value and puts it in to an array accordingly.
   * Example:
   *    columnKey = lat_3
   *    primary = lat
   *    secondary = 3
   * @param columnKey
   */
  matchColumns(columnKey) {
    // TODO switching on top of another doesnt set it to false
    // TODO ex. check lat -> lat true. Switch to lon -> lat & lon true
    let primary = columnKey.substring(0, columnKey.indexOf("-"))
    let secondary = columnKey.split('-')[1]
    this.oneOrSomeColumnsMatched = true
    if(this.transFormerSelected) {
            switch(primary) {
                case "lat": {
                    this.excelMatchArray['lat'] = secondary
                    this.latChosen = true
                    break;
                }
                case "lon": {
                    this.excelMatchArray['lon'] = secondary
                    this.lonChosen = true
                    break;
                }
                case "project_identifier": {
                    this.excelMatchArray['identifier'] = secondary
                    this.projectidChosen = true
                    break;
                }
                case "msa_id": {
                    this.excelMatchArray['msa_id'] = secondary
                    this.msaIdChosen = true
                    break;
                }
                default: {
                    this.excelMatchArray[primary] = secondary
                    break;
                }
            }
        } else {
            // workorderit
            switch(primary) {
                case "lat": {
                    this.excelMatchArray['lat'] = secondary
                    this.latChosen = true
                    break;
                }
                case "lon": {
                    this.excelMatchArray['lon'] = secondary
                    this.lonChosen = true
                    break;
                }
                case "address": {
                    this.excelMatchArray['address'] = secondary
                    this.addressChosen = true
                    break;
                }
                case "zip": {
                    this.excelMatchArray['zip'] = secondary
                    this.zipChosen = true
                    break;
                }
                case "city": {
                    this.excelMatchArray['city'] = secondary
                    this.cityChosen = true
                    break;
                }
                case "project_identifier": {
                    this.excelMatchArray['project'] = secondary
                    break;
                }
                case "timeparameter": {
                    let tempArray = Array()
                    let oneRemoved = false
                    if (!this.excelMatchArray['time_parameter_columns']) this.excelMatchArray['time_parameter_columns'] = tempArray

                    for (let index = 0; index < this.excelMatchArray['time_parameter_columns'].length; index++) {
                        const element = this.excelMatchArray['time_parameter_columns'][index];
                        if (element === secondary) {
                            oneRemoved = true
                            this.excelMatchArray['time_parameter_columns'].splice(index, 1);
                        }
                    }

                    if(!oneRemoved) this.excelMatchArray['time_parameter_columns'].push(secondary)

                    if (this.excelMatchArray['time_parameter_columns']) this.timeEstimateChosen = true
                    break;
                }
                default: {
                    this.excelMatchArray[primary] = secondary
                    break;
                }
            }
    }
  }

    saveWorkorders() {
        if (this.headlineIndex != 0 && this.dataStartIndex != 0) {
            // TODO: Loader here!
            if (!this.oneOrSomeColumnsMatched) {
                Swal.fire(this.translateService.instant('import.didNotMatchColumns'))
                this.rowsAreSelected = true
                this.userStepCount = 4

            }  else if (this.generateMeters && (!this.addressChosen || !this.cityChosen || !this.zipChosen)) this.toastService.sendToast(false, this.translateService.instant('import.generateMetersError'))
            else if (!this.generateMeters && (!this.addressChosen && (!this.latChosen || !this.lonChosen) || (!this.latChosen && !this.lonChosen))){

                this.toastService.sendToast(false, this.translateService.instant('planner.newContractor.error3'))

                this.rowsAreSelected = true
            } else {

                let ufile = this.excelData['filename']
                // We take -1 from the chosen index to not choose faulty index. "Select row" that is added to rowdata takes index 0 place.
                let headerline = this.headlineIndex-1
                let startat = this.dataStartIndex-1

                Swal.fire({
                    title: this.translateService.instant('planner.importData.swal.confirmation'),
                    showCancelButton: true,
                    confirmButtonText: this.translateService.instant('basic.continue'),
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isDismissed) {
                        this.rowsAreSelected = true
                        this.userStepCount = 4
                    } else if (result.isConfirmed) {
                      this.userStepCount = 5
                      this.excelMatchArray['contractor_id'] = this.contractorId
                      if (this.excelMatchArray['time_parameter_columns']) {
                          let jsonArray
                          jsonArray = '[' + this.excelMatchArray['time_parameter_columns'].toString() + ']'
                          this.excelMatchArray['time_parameter_columns'] = jsonArray
                      }
                      this.excelMatchArray['headerline'] = Number(headerline)
                      
                      this.contractorService.returnUnique(ufile, headerline, startat).subscribe(data => {
                      }).add(() => {
                        console.log(this.excelMatchArray)
                          this.contractorService.importHeadlines(ufile, headerline, startat, this.excelMatchArray, this.generateMeters).subscribe(d => {
                              // todo näytä käyttäjälle missä vaiheessa mennään
                              if(!d) {
                                  this.toastService.sendToast(false, this.translateService.instant('backend_errors.err_something_went_wrong'))
                                  this.userStepCount = 4
                              } else {
                                  this.toastService.sendToast(true, this.translateService.instant('planner.importData.toast.successWorkorders'))
                                  this.router.navigate(['/planner/contractor'])
                              }
                          })
                      })
                        }
                      })
            }
        } else {
            console.log('select data')
        }
    }

    saveProjects() {
      // todo käyttääkö tää endpoint queuea? jos ei, sen luultavasti pitäis
        if (this.headlineIndex != 0 && this.dataStartIndex != 0) {
            // TODO: Loader here!
            if (!this.oneOrSomeColumnsMatched) {
                Swal.fire(this.translateService.instant('import.didNotMatchColumns'))
                this.rowsAreSelected = true
                this.userStepCount = 4

            } else if ( (this.generateTransformers && !this.projectidChosen) || (!this.generateTransformers && (!this.projectidChosen || (!this.latChosen || !this.lonChosen))) ){
                // jos generateTransformers on päällä, tarvitaan projectid, koordinaatit optional
                // jos ei, tarvitaan projectid tai koordinaatit
                Swal.fire(this.translateService.instant('planner.importData.swal.projectID/lat/lonError'))
                this.rowsAreSelected = true
                this.userStepCount = 4
            } else {

                let ufile = this.excelData['filename']
                // We take -1 from the chosen index to not choose faulty index. "Select row" that is added to rowdata takes index 0 place.
                let startat = this.dataStartIndex-1

                Swal.fire({
                    title: this.translateService.instant('planner.importData.swal.confirmation'),
                    showCancelButton: true,
                    confirmButtonText: this.translateService.instant('basic.continue'),
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isDismissed) {
                        this.rowsAreSelected = true
                        this.userStepCount = 4
                    } else if (result.isConfirmed && this.msaIdChosen) {
                      Swal.fire({
                        title: this.translateService.instant('planner.importData.updatingMsaInformation'),
                        text: this.translateService.instant('planner.importData.updateMessageMsa'),
                        showCancelButton: true
                      }).then((msaConfirmation) => {
                        if (msaConfirmation.isDismissed) {
                          this.rowsAreSelected = true
                          this.userStepCount = 4
                        } else if (msaConfirmation.isConfirmed) {
                          this.userStepCount = 5
                          this.contractorService.importProjects(ufile, startat, this.excelMatchArray, this.userId, this.generateTransformers).subscribe(d => {
                              if(!d) {
                                  this.toastService.sendToast(false, this.translateService.instant('backend_errors.err_something_went_wrong'))
                                  this.userStepCount = 4
                              } else {
                                  this.toastService.sendToast(true, this.translateService.instant('planner.importData.toast.success'))
                                  this.router.navigate(['/planner/contractor'])
                              }
                          })
                        }
                      })
                    } else if (result.isConfirmed && !this.msaIdChosen) {
                      this.userStepCount = 5
                      this.contractorService.importProjects(ufile, startat, this.excelMatchArray, this.userId, this.generateTransformers).subscribe(d => {
                          if(!d) {
                              this.toastService.sendToast(false, this.translateService.instant('backend_errors.err_something_went_wrong'))
                              this.userStepCount = 4
                          } else {
                              this.toastService.sendToast(true, this.translateService.instant('planner.importData.toast.success'))
                              this.router.navigate(['/planner/contractor'])
                          }
                      })
                    }
                })
            }
        } else {
            // console.log('select data')
        }
    }

  /**
   * Slices the rowsData to kill the relation to it before we splice customRowsData.
   * Otherwise splice applies to all related arrays aswell.
   */
  rowSelectHandler() {
    if (this.headlineIndex == 0 || this.dataStartIndex == 0) {
      this.toastService.sendToast(false, this.translateService.instant('import.importError1'))

    } else if (this.headlineIndex > this.dataStartIndex) {
      this.toastService.sendToast(false, this.translateService.instant('import.importError2'))

    } else {
      this.rowsAreSelected = true
      this.customRowsData = this.rowsData.slice()
      this.customRowsData.splice(0, this.dataStartIndex)
      this.nextStep(4)
    }
  }

    /**
   * Preserve original property order.
   * https://stackoverflow.com/questions/52793944/angular-keyvalue-pipe-sort-properties-iterate-in-order
   * @param a
   * @param b
   * @returns
   */
  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  workorderSelect() {
    this.workorderSelected = true
    this.transFormerSelected = false
  }

  transformerSelect() {
    this.workorderSelected = false
    this.transFormerSelected = true
  }

  getUserInfo() {
    this.userService.getUserInfo()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(data => {
      this.userId = data.id
      this.contractorId = data.current_contractor
    })
  }

  // Move back to selecting rows page
  moveBack() {
    this.userStepCount = 3
    this.rowsAreSelected = false
  }

  getMsas() {
    this.workorderService.getMsas().subscribe({
      next: data => this.msas = data,
      error: err => console.log(err.toString())
    })
  }

}
