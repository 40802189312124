<div class="page-container">
    <div class="page-heading">
        <h3>
           <img class="nav-icon" src="assets/icons/list_alt_black_24dp.svg">
           <span class="page-title">{{'sideNavigation.titleNewContractor' | translate}}</span>
        </h3>
     </div>
    <div class="page-content">
        <div class="card">
            <div class="card-body">
                <div class="header">
                    <nav class="nav justify-content-center">
                        <!-- The second part of ngStyle can later be adjusted accoring to design when the tab functionalities are completed -->
                        <a class="nav-link" [ngStyle]="{'color': tabindex >= 1 ? '#0058ff' : '#707591', 'border-bottom': tabindex >= 1 ? '3px solid #0058ff' : '3px solid #707591'}">{{'planner.newContractor.basicInformation' | translate}}</a>
                        <a class="nav-link" [ngStyle]="{'color': tabindex >= 2 ? '#0058ff' : '#707591', 'border-bottom': tabindex >= 2 ? '3px solid #0058ff' : '3px solid #707591'}">{{'planner.newContractor.importData' | translate}}</a>
                        <a class="nav-link" [ngStyle]="{'color': tabindex >= 3 ? '#0058ff' : '#707591', 'border-bottom': tabindex >= 3 ? '3px solid #0058ff' : '3px solid #707591'}">{{'import.matchColumns' | translate}}</a>
                    </nav>
                </div>
                <div id="tab-container">

                    <!-- FIRST TAB -->
                    <div id="tab1" *ngIf="tabindex == 1">
                        <div class="form-group mt-10">
                            <h3>{{'planner.newContractor.basicInformation' | translate}}</h3>
                        </div>
                        <div id="contractor-basic-information-new-contractor" class="mt-10">
                            <div class="row">
                                <form (ngSubmit)="nextStep(2)">
                                    <div class="col-md-6">
                                        <div class="form-group col-md-6">
                                            <label>{{'basic.name' | translate}} *</label>
                                            <input type="text" name="name" class="form-control" [(ngModel)]="contractorName">
                                        </div>
                                        <div class="form-group col-md-6">
                                          <label class="select-label" for="contractortype">{{'planner.newContractor.contractorType' | translate}} *</label>
                                          <br>
                                          <select name="headline" id="contractortype" class="form-select" [(ngModel)]="contractorType">
                                            <option value="1" [selected]="contractorType==1">{{'planner.newContractor.contractorType_1' | translate}}</option>
                                          </select>
                                        </div>
                                        <div class="form-group col-md-10">
                                            <label>{{'planner.newContractor.contractorInfo' | translate}}</label>
                                            <textarea type="text" name="info" class="form-control" [(ngModel)]="contractorInfo"></textarea>
                                            <div class="row mt-10">
                                                <div class="col-md-6">
                                                    <button class="btn btn-primary w-100" type="submit">{{'basic.next' | translate}}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                        <!-- 'second' TAB -->
                    <div id="tab2" *ngIf="tabindex == 2">
                        <div id="contractor-basic-information">
                            <div class="row">
                                <div class="col-md-6 mt-10">
                                    <div class="form-group">
                                        <label>{{'basic.name' | translate}}</label>
                                        <div>{{ contractorName }}</div>
                                    </div>
                                    <div class="form-group">
                                        <label>{{'planner.newContractor.contractorType' | translate}}</label>
                                        <div>{{ contractorType }}</div>
                                    </div>
                                    <div class="form-group">
                                        <label>{{'planner.newContractor.contractorInfo' | translate}}</label>
                                        <div>{{ contractorInfo }}</div>
                                    </div>
                                </div>
                            </div>

                            <hr class="mt-10">

                            <div class="form-group mt-5">
                                <h3>{{'import.importInfo' | translate}}</h3>
                                <div class="import-coordinates p-4">
                                    <div>{{'import.generateMetersBasic' | translate}}?</div>
                                    <div class="fw-bold">{{'import.generateMetersCost' | translate}}.</div>
                                    <div class="d-flex mt-2">
                                        <input type="checkbox" [(ngModel)]="generateMeters" class="form-check-input margin-top-1">
                                        <span class="ms-1">{{'import.generateMetersConfirmation' | translate}}?</span>
                                    </div>
                                </div>

                                <div class="mt-10" ngClass="{{dragAreaClass}}">
                                    <div *ngIf="!fileSelected" class="text-center">
                                    <header>{{'import.dropXLSX' | translate}}</header>
                                    </div>
                                    <div *ngIf="fileSelected" class="text-center">
                                        <header class="blue-text">{{ fileName }}<span> {{'basic.selected' | translate | lowercase}}</span></header>
                                    </div>
                                    <input type="file" hidden accept=".xlsx">
                                </div>
                            </div>
                            <div class="row mt-10">
                                <div class="col-md-3">
                                    <button *ngIf="!fileSelected" class="btn btn-primary w-100" (click)="fileInput.click()">{{'import.uploadFromPC' | translate}}<input #fileInput type="file" hidden accept=".xlsx" type="file" (change)="onFileChange($event)"></button>
                                    <button *ngIf="fileSelected" class="btn btn-secondary w-100" (click)="fileInput.click()">{{'import.changeFile' | translate}}<input #fileInput type="file" hidden accept=".xlsx" type="file" (change)="onFileChange($event)"></button>
                                </div>
                                <div class="col-md-3">
                                    <button class="btn btn-primary w-100" (click)="nextStep(3)">{{'basic.next' | translate}}</button>
                                </div>
                                <div class="col-md-3">
                                    <button class="btn btn-secondary w-100" (click)="nextStep(1)">{{'basic.cancel' | translate}}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                        <!-- LAST TAB -->

                    <div id="tab3" *ngIf="tabindex == 3">
                        <div class="form-group mt-10">
                            <h3>{{'import.matchColumns' | translate}}</h3>
                        </div>
                        <div class="row" *ngIf="!showMatchColumns">
                            <div class="col-md-7 row">
                                <div class="col-md-6 row-selector form-group">
                                    <label class="select-label" for="headline">{{'import.headlineRow' | translate}}</label>
                                    <br>
                                    <select name="headline" id="headlineIndex" class="form-select" [(ngModel)]="headlineIndex">
                                        <option *ngFor="let row of rowsData| keyvalue: originalOrder" value="{{row.key}}" [selected]="row[0]" ng-disabled="row[0]">
                                            {{row.value}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6 row-selector form-group">
                                    <label class="select-label" for="data">{{'import.dataStarts' | translate}}</label>
                                    <br>
                                    <select name="data" id="dataStartIndex" class="form-select" [(ngModel)]="dataStartIndex">
                                        <option *ngFor="let row of rowsData | keyvalue: originalOrder" value="{{row.key}}" [selected]="row[0]" ng-disabled="row[0]">
                                            {{row.value}}
                                        </option>
                                    </select>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <button class="btn btn-primary w-100" (click)="rowSelectHandler()">{{'basic.next' | translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="showMatchColumns">
                            <div class="form-group">
                                <label>{{'planner.newContractor.selectColumnsTimeEstimates' | translate}}</label>
                            </div>
                            <div class="horizontal-scroll">
                                <table class="table table-striped" id="table1">
                                    <thead>
                                        <tr>
                                            <th *ngFor="let title of rowsData[headlineIndex] | keyvalue: originalOrder" class="p-0">
                                                <input *ngIf="excelMatchArray['time_parameter_columns']" type="checkbox" class="mr-1 ml-05" id="timeparameter_{{title.key}}" value="timeparameter-{{title.key}}" (change)="matchColumns($event.target.value)" [checked]="excelMatchArray['time_parameter_columns'].includes(title.key)">
                                                <input *ngIf="!excelMatchArray['time_parameter_columns']" type="checkbox" class="mr-1 ml-05" id="timeparameter_{{title.key}}" value="timeparameter-{{title.key}}" (change)="matchColumns($event.target.value)">
                                                <label class="gray-text fw-normal" for="timeparameter-{{title.key}}">{{'basic.use' | translate}}</label>
                                                <div class="gray-border th-padding">
                                                    <div class="mt-1 fw-600"> {{ title.value }} </div>
                                                    <select (change)="matchColumns($event.target.value)" class="form-select column-select">
                                                        <option selected value="{{title.key}}">-</option>
                                                        <option [selected]="excelMatchArray['lat'] == title.key" value="lat-{{title.key}}">{{'basic.latitude' | translate}} {{generateMeters === false ? '*' : null}}</option>
                                                        <option [selected]="excelMatchArray['lon'] == title.key" value="lon-{{title.key}}">{{'basic.longitude' | translate}} {{generateMeters === false ? '*' : null}}</option>
                                                        <option [selected]="excelMatchArray['address'] == title.key" value="address-{{title.key}}">{{'basic.address' | translate}} {{generateMeters === true ? '*' : null}}</option>
                                                        <option [selected]="excelMatchArray['zip'] == title.key" value="zip-{{title.key}}">{{'planner.newContractor.columns.zip' | translate}} {{generateMeters === true ? '*' : null}}</option>
                                                        <option [selected]="excelMatchArray['city'] == title.key" value="city-{{title.key}}">{{'planner.newContractor.columns.city' | translate}} {{generateMeters === true ? '*' : null}}</option>
                                                        <option [selected]="excelMatchArray['project'] == title.key" value="project-{{title.key}}">{{'basic.project' | translate}}</option>
                                                        <option [selected]="excelMatchArray['workorder_identifier'] == title.key" value="workorder-{{title.key}}">{{'basic.workorderID' | translate}}</option>
                                                        <option [selected]="excelMatchArray['notes'] == title.key" value="notes-{{title.key}}">{{'planner.newContractor.columns.notes' | translate}}</option>
                                                        <option [selected]="excelMatchArray['access_type'] == title.key" value="access_type-{{title.key}}">{{'basic.accessType' | translate}}</option>
                                                        <option [selected]="excelMatchArray['access_type_notes'] == title.key" value="access_type_notes-{{title.key}}">{{'planner.newContractor.columns.accessTypeNotes' | translate}}</option>
                                                        <option [selected]="excelMatchArray['location_number'] == title.key" value="location_number-{{title.key}}">{{'basic.locationNumber' | translate}}</option>
                                                        <option [selected]="excelMatchArray['old_device_id'] == title.key" value="old_device_id-{{title.key}}">{{'planner.newContractor.columns.oldDeviceID' | translate}}</option>
                                                        <option [selected]="excelMatchArray['worker_notes'] == title.key" value="worker_notes-{{title.key}}">{{'planner.newContractor.columns.workerNotes' | translate}}</option>
                                                        <option [selected]="excelMatchArray['fuse_limit'] == title.key" value="fuse_limit-{{title.key}}">{{'planner.newContractor.columns.fuseLimit' | translate}}</option>
                                                        <option [selected]="excelMatchArray['fuse_type'] == title.key" value="fuse_type-{{title.key}}">{{'planner.newContractor.columns.fuseType' | translate}}</option>
                                                        <option [selected]="excelMatchArray['story'] == title.key" value="story-{{title.key}}">{{'basic.story' | translate}}</option>
                                                        <option [selected]="excelMatchArray['placement'] == title.key" value="placement-{{title.key}}">{{'basic.placement' | translate}}</option>
                                                        <option [selected]="excelMatchArray['contact_info'] == title.key" value="contact_info-{{title.key}}">{{'planner.newContractor.columns.contactInfo' | translate}}</option>
                                                        <!-- Owner -->
                                                        <option [selected]="excelMatchArray['owner_firstname'] == title.key" value="owner_firstname-{{title.key}}">{{'planner.newContractor.columns.oFirstName' | translate}}</option>
                                                        <option [selected]="excelMatchArray['owner_lastname'] == title.key" value="owner_lastname-{{title.key}}">{{'planner.newContractor.columns.oLastName' | translate}}</option>
                                                        <option [selected]="excelMatchArray['owner_zip'] == title.key" value="owner_zip-{{title.key}}">{{'planner.newContractor.columns.oZip' | translate}}</option>
                                                        <option [selected]="excelMatchArray['owner_address'] == title.key" value="owner_address-{{title.key}}">{{'planner.newContractor.columns.oAddress' | translate}}</option>
                                                        <option [selected]="excelMatchArray['owner_city'] == title.key" value="owner_city-{{title.key}}">{{'planner.newContractor.columns.oCity' | translate}}</option>
                                                        <option [selected]="excelMatchArray['owner_phone'] == title.key" value="owner_phone-{{title.key}}">{{'planner.newContractor.columns.oPhone' | translate}}</option>
                                                        <option [selected]="excelMatchArray['owner_email'] == title.key" value="owner_email-{{title.key}}">{{'planner.newContractor.columns.oEmail' | translate}}</option>
                                                        <!-- Resident -->
                                                        <option [selected]="excelMatchArray['resident_firstname'] == title.key" value="resident_firstname-{{title.key}}">{{'planner.newContractor.columns.rFirstName' | translate}}</option>
                                                        <option [selected]="excelMatchArray['resident_lastname'] == title.key" value="resident_lastname-{{title.key}}">{{'planner.newContractor.columns.rLastName' | translate}}</option>
                                                        <option [selected]="excelMatchArray['resident_zip'] == title.key" value="resident_zip-{{title.key}}">{{'planner.newContractor.columns.rZip' | translate}}</option>
                                                        <option [selected]="excelMatchArray['resident_address'] == title.key" value="resident_address-{{title.key}}">{{'planner.newContractor.columns.rAddress' | translate}}</option>
                                                        <option [selected]="excelMatchArray['resident_city'] == title.key" value="resident_city-{{title.key}}">{{'planner.newContractor.columns.rCity' | translate}}</option>
                                                        <option [selected]="excelMatchArray['resident_phone'] == title.key" value="resident_phone-{{title.key}}">{{'planner.newContractor.columns.rPhone' | translate}}</option>
                                                        <option [selected]="excelMatchArray['resident_email'] == title.key" value="resident_email-{{title.key}}">{{'planner.newContractor.columns.rEmail' | translate}}</option>
                                                    </select>
                                                </div>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of customRowsData">
                                            <td *ngFor="let item of data"> {{ item }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="mt-2">
                                <button class="btn btn-primary" (click)="nextStep(4)">{{'basic.next' | translate}}</button>
                                <button class="btn btn-secondary mx-2" (click)="moveBack()">{{'planner.newContractor.selectRows' | translate}}</button>
                                <button class="btn btn-secondary" (click)="cancelCreation()">{{'basic.cancel' | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
