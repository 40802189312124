import { Component } from '@angular/core';

@Component({
  selector: 'app-project-lead',
  templateUrl: './project-lead.component.html',
  styleUrls: ['./project-lead.component.scss']
})
export class ProjectLeadComponent {

}
