import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { baseUrl } from '@environments/environment';
import { Project } from '@shared/models/project';  // Edge case: Changed to Project
import { Team } from '@shared/models/team';
import { ToastService } from '@shared/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  constructor( 
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService
    ) { }


  /**
   * Fetches teams from backend with api-call workerteams and returns them as an observable
   * @returns list of teams, if fails reports error message to console
   * @author Jesse Lindholm
   * @editedDate 16.7.2021
   */
  getTeams(): Observable<Team[]> {
    return this.http.get<Team[]>(`${baseUrl}/api/workerteams`)
      .pipe(
	    map((data: Team[]) => {
        // console.log(data['message']);
			  return data['message'];
		}),
        catchError(this.handleError<Team[]>('getTeams', []))
      );
  }

  /**
   * Get data from one team with id. Returns data in correct form. Handles error
   * @param id id of team
   * @returns team with given id, returns 404 if not found
   */
  getTeamById(id): Observable<Team> {
    return this.http.get<Team>(`${baseUrl}/api/workerteams/${id}`)
      .pipe(
	    map((data: Team) => {
			  return data['message'];
		}),
        catchError(this.handleError<Team>('getTeamById'))
      );
  }

  
  getProjectsById(id: number): Observable<Project[]> {  // Updated name
    const url = `${baseUrl}/api/projects/${id}`;
    return this.http.get<Project[]>(url)  // Updated name
      .pipe(
        map((data: Project[]) => {
          // console.log(data['message']);
          // return data['message'];
          return data['projects'];
      }),
        catchError(this.handleError<Project[]>(`getProject id=${id}`))
     );
  }

  /**
   * Creates team with correct name, enabling linking workers to workerteam
   * @param teamName parameter for teams name
   * @returns success message or error
   */
  createTeam(teamName) {
    let data = {
      name: teamName
    }

    return this.http.post(`${baseUrl}/api/workerteams`, data)
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          //alert(error.error)
          return of(false)
        })
      )
  }

  /**
   * Performs update api call to workerteam.
   * @param id workerteams id
   * @param teamName name for workerteam
   * @param teamAdditionalInformation notes for workerteam
   * @returns success message or error
   */
  editTeam(id, teamName, teamAdditionalInformation) {
    let data = {
      name: teamName,
      notes: teamAdditionalInformation
    }

    let newdata = JSON.stringify(data)

    return this.http.patch(`${baseUrl}/api/workerteams/${id}`, {newdata})
      .pipe(
        map((data: any) => {
          this.toastService.sendToast(true, this.translateService.instant('services.updateSuccess'))
          return data['message'];
        }),
        catchError(error => {
          //alert(error.error)
          return of(false)
        })
      )
  }

  /**
   * Delete team from workerteam
   * @param id workerteams id
   * @returns success message or error
   */
  deleteTeam(id) {
    return this.http.delete(`${baseUrl}/api/workerteams/${id}`).pipe(
      map((data) => {
        this.toastService.sendToast(true, this.translateService.instant('services.deleteSuccessful'))
        return data['message']
      }),
      catchError(err => {
        //alert(err.error)
        return of(false)
        }
      )
    )
    
  }

  /**
   * Unlink worker from worketeam and display success message in toast if api-call successful
   * @param workerId workers ID
   * @param workerteamId workerteams ID
   * @returns
   * @author Jesse Lindholm
   */
  unlinkWorkerFromWorkerteam(workerId, workerteamId) {
    return this.http.delete(`${baseUrl}/api/workers/${workerId}/${workerteamId}`).pipe(
      map((data) => {
        this.toastService.sendToast(true, this.translateService.instant('services.deleteWorkerFromWorkerteamSuccess'))
        return data['message']
      }),
      catchError(err => {
        //alert(err.error)
        return of(false)
        }
      )
    )
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      //console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
