<div class="page-container">
    <div class="page-heading">
        <h3>
            <img src="assets/icons/person_black_24dp.svg">
            <span class="page-title">{{'sideNavigation.titleWorkers' | translate}}</span>
        </h3>
    </div>
    <div class="page-content">
        <div class="card">
            <div class="card-body">
                <div *ngIf="workers.length > 0">
                    <div class="row">
                        <div class="col-4 form-group max-content">
                            <label class="gray-text">{{'planner.workersPage.search' | translate}}</label><br>
                            <input type="text" [(ngModel)]="name" [placeholder]="'basic.name' | translate" class="form-control">
                        </div>
                        <div class="col-4">
                            <div class="gray-text">{{'basic.filterSkills' | translate}}</div>
                            <div class="form-group">
                                <select class="form-select max-content" [ngStyle]="selectTextStyle" [(ngModel)]="selectedSkill" (change)="skillChanged($event.target.value)">
                                    <option [value]="undefined">{{'basic.selectSkills' | translate}}</option>
                                    <option *ngFor="let skill of allSkills" [value]="skill.name">{{skill.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                      <table class="mt-5 min-width">
                          <thead>
                              <tr>
                                  <th class="col-auto"></th>
                                  <th role="button" scope="col" [appSort]="workers" data-order="desc" data-name="name" class="col gray-text font-weight-normal">
                                      <span>{{'basic.name' | translate}}</span>
                                      <img src="assets/icons/unfold_more_gray_24dp.svg">
                                  </th>
                                  <th class="col-auto gray-text font-weight-normal">{{'basic.tags' | translate}}</th>
                                  <th class="col-auto gray-text font-weight-normal">{{'basic.efficiency' | translate}}</th>
                                  <th scope="col" class="col-2 gray-text font-weight-normal">{{'basic.teams' | translate}} </th>
                                  <th scope="col" class="col-2 gray-text font-weight-normal">{{'basic.skills' | translate}}</th>
                                  <th scope="col" class="col-3 gray-text font-weight-normal">{{'basic.contractors' | translate}}</th>
                              </tr>
                          </thead>
                          <tbody class="min-width">
                              <tr *ngFor="let item of workers | searchFilter: name; let i = index"  class="border-bottom border-top border-gray">
                                <td class="col-auto">
                                  <input type="checkbox" #checkboxes class="contractorchecker" value="{{item.userId}}" name="contractorchecker" (change)="onChange(item.userId)">
                                </td>
                                <td class="col">
                                    <a href='#' (click)="goToWorkerDetail($event, item.id)">
                                        <div class="bold-text black-text">{{item.name}}</div>
                                        <div *ngIf="item.hasLeadInstaller == true" class="gray-text small-text">{{'planner.workersPage.leadInstaller' | translate}}</div>
                                    </a>
                                </td>
                                <td class="col-auto">
                                      <div class="same-line">
                                          <div *ngFor="let tag of item.tags" class="gap-between-elements
                                              colors-skill text-align-center gray-text rounded-pill px-2">{{tag}}</div>
                                      </div>
                                </td>
                                <td class="col-auto">
                                    <circle-progress
                                    [percent]="item.efficiency"
                                    [radius]="25"
                                    [outerStrokeWidth]="4"
                                    [innerStrokeWidth]="4"
                                    [outerStrokeColor]="item.colorOuter"
                                    [innerStrokeColor]="item.colorInner"
                                    [space]="-4"
                                    [animation]="true"
                                    [animationDuration]="300"
                                    [titleFontSize]="'13'"
                                    [title]="item.efficiencyValue"
                                    [showUnits]=false
                                    [titleColor]="item.colorOuter"
                                    [titleFontWeight]="'600'"
                                >                                   </circle-progress>
                                </td>
                                <td class="col">

                                    <div>
                                        <div class="background-show-workerteams show-workerteams" *ngIf="item.showWorkerteam">
                                            <div *ngFor="let element of item.workerteams">{{element}}</div>
                                        </div>
                                        <div *ngIf="item.workerteams.length > 0" (mouseenter)="item.showWorkerteam = true" (mouseleave)="item.showWorkerteam = false" class="black-text bold-text" >{{item.workerteams.length}} {{'planner.workersPage.workerteams' | translate}}</div>
                                        <div *ngIf="item.workerteams.length === 0" class="black-text bold-text" >{{item.workerteams.length}} {{'planner.workersPage.workerteams' | translate}}</div>
                                    </div>
                                </td>
                                <td class=" col">
                                    <div class="same-line">
                                        <div *ngFor="let skill of item.qualifications" class="gap-between-elements
                                            colors-skill text-align-center gray-text rounded-pill px-2">{{skill}}</div>
                                    </div>
                                </td>
                                <td class="col">
                                  <div class="same-line">
                                    <div *ngFor="let contractor of item.contractors" class="gap-between-elements
                                              colors-skill text-align-center gray-text rounded-pill px-2">{{contractor}}</div>
                                  </div>
                                </td>
                              </tr>
                          </tbody>
                      </table>
                        <div *ngIf="contractors.length>0">
                            <div>{{'planners.addContractor' | translate}}</div>
                            <select class="form-control w-auto" #contractorselect (change)="onSelected(contractorselect.value)">
                                <option>-{{'planners.selectContractor' | translate}}-</option>
                                <option *ngFor="let item of contractors" value="{{item.id}}">{{item.name}} {{item.id == contractorId ? ('planners.currentContractor' | translate) : '' }}</option>
                            </select>
                            <br>
                            <button [disabled]="saving" (click)="addContractor()" class="btn btn-primary col-3">{{'planners.add' | translate}}</button>
                        </div>
                  </div>
                </div>
                <div *ngIf="workers.length == 0">
                    <div *ngIf="loading">{{'basic.loading' | translate}}</div>
                    <div *ngIf="!loading">{{'workers.noWorkers' | translate}}</div>
                </div>
                <button class="btn btn-primary text-white margin-top" id="newWorker" data-bs-toggle="modal" data-bs-target="#workerModal" (click)="setEventListenersForSecondModal()">+ {{'planner.workersPage.newWorker' | translate}}</button>
                <!-- Modal -->
                <div class="modal fade" id="workerModal" tabindex="-1" aria-labelledby="workerModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-body padding-modal">
                                <img src="assets/icons/person_black_24dp.svg" class="top-position-image">
                                <span class="title ms-2">{{'planner.workersPage.newWorker' | translate}}</span>
                                <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
                                <form ngNativeValidate (ngSubmit)="addNewWorker()">

                                    <div class="form-group mt-3 row">
                                        <div class="col-6">
                                            <label class="gray-text"><span class="mt-1">*</span>{{'basic.firstName' | translate}}</label><br>
                                            <input type="text" [(ngModel)]="personFirstName" [ngModelOptions]="{standalone: true}" class="form-control " required>
                                        </div>
                                        <div class="col-6">
                                            <label class="gray-text"><span class="mt-1">*</span>{{'basic.lastName' | translate}}</label><br>
                                            <input type="text" [(ngModel)]="personLastName" [ngModelOptions]="{standalone: true}" class="form-control" required>
                                        </div>
                                    </div>
                                    <div class="mt-3 row form-group">
                                        <div class="col-6">
                                            <div class="gray-text form-group">
                                              <label><span class="mt-1">*</span>{{'basic.email' | translate}}</label><br>
                                              <input type="email"  [(ngModel)]="email" [ngModelOptions]="{standalone: true}" class="form-control" required>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="gray-text form-group">
                                                <label><span class="mt-1">*</span>{{'basic.phoneNumber' | translate}}</label><br>
                                                <input type="tel"  [(ngModel)]="phoneNumber" [ngModelOptions]="{standalone: true}" class="form-control" required>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-3 form-group row">
                                        <div class="col-6">
                                            <p class="gray-text">{{'basic.interfaceLanguage' | translate}}</p>
                                            <select class="form-select" (change)="languageChange($event.target.value)">
                                                <option *ngFor="let language of interfaceLanguages" [value]="language.databaseLanguage">{{language.displayName}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="mt-4">
                                        <input class="form-check-input" type="checkbox" id="subcontractor" [(ngModel)]="subcontractor" [ngModelOptions]="{standalone: true}" (click)="setSubcontractorValue()">
                                        <label for="subcontractor" class="gray-text ml-1">{{'planner.workersPage.subcontractor' | translate}}</label>
                                    </div>
                                    <hr>
                                    <a (click)="toggleProfessionalInformation()" class="cursor-pointer">
                                        <div class="bold-text black-text">{{'planner.workersPage.profInfo' | translate}}</div>
                                        <div class="gray-text">{{'planner.workersPage.dropdownInfo' | translate}}<img class="float-end image-professional-information" [src]="imageProfessionalInformation"></div>

                                    </a>
                                    <hr>
                                    <div *ngIf="showProfessionalInformation">
                                        <div class="mt-3 form-group">
                                            <label class="gray-text">{{'planner.workersPage.workersEfficiency' | translate}}</label>
                                            <input type="number" min="0.5" max="2.0" step="0.01" value="1.0" placeholder="1.0" [(ngModel)]="efficiencyFactor" [ngModelOptions]="{standalone: true}" class="form-control max-content">
                                        </div>

                                        <div class="mt-3 row " *ngIf="isWindowTooSmall == false">
                                            <div class="gray-text">{{'workers.workingHours' | translate}}</div>
                                            <div class="table">
                                                <div class="table-cell gray-text">
                                                    <div class="height-60 width-day position-day d-flex justify-content-center">{{'workers.day' | translate}}</div>
                                                    <div class="height-60 width-day d-flex justify-content-center position-day">{{'basic.start' | translate}}</div>
                                                    <div class="height-60 width-day d-flex justify-content-center position-day">{{'basic.end' | translate}}</div>
                                                </div>
                                                <div *ngFor="let day of days; let i = index;" class="table-cell">
                                                    <div class="border-end border-bottom border-top border-gray only-height-60" [ngClass]="{'border-start' : i == 0}">
                                                        <div class="d-flex justify-content-center">{{day.name}}</div>
                                                        <div class="d-flex justify-content-center position-checkbox">
                                                            <label class="container">
                                                                <input type="checkbox" [(ngModel)]="day.checked" [ngModelOptions]="{standalone: true}" checked="checked">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="border-end border-bottom border-gray height-60 d-flex justify-content-center" [ngClass]="{'border-start' : i == 0}">
                                                        <input type="time" [(ngModel)]="day.start" [ngModelOptions]="{standalone: true}" class="input-number">
                                                    </div>
                                                    <div class="border-end border-bottom border-gray height-60 d-flex justify-content-center" [ngClass]="{'border-start' : i == 0}">
                                                        <input type="time" [(ngModel)]="day.end" [ngModelOptions]="{standalone: true}" class="input-number">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3" *ngIf="isWindowTooSmall == true">{{'workers.windowTooSmall' | translate}}</div>

                                        <div class="mt-3 ">
                                            <div class=" gray-text">{{'basic.selectSkills' | translate}}</div>
                                            <div class="border border-gray border-2  scrollbar">
                                                <div class="gray-text ms-2">
                                                    <a data-bs-toggle="modal" data-bs-target="#createNewSkillModal" class="gray-text cursor-pointer" data-bs-dismiss="modal">
                                                        <span class="fs-4">+</span>
                                                        <span class="ms-3 position">{{'workers.createNewSkill' | translate}}</span>
                                                    </a>

                                                </div>
                                                <div *ngIf="allSkills.length > 0">
                                                    <div *ngFor="let skill of allSkills; let i = index" >
                                                        <div class="border-top-alignment"></div>
                                                        <label class="container my-2">
                                                            <input type="checkbox" [(ngModel)]="allSkills[i].checked" [ngModelOptions]="{standalone: true}" (click)="addSkill(i)" checked="checked">
                                                            <span class="checkmark position-checkbox-skill"></span>
                                                            <span class="ms-3 skill-text ">{{skill.name}}</span>
                                                        </label>
                                                        <div *ngIf="allSkills.length < 3 && i == allSkills.length - 1" class="border-top-alignment"></div>
                                                    </div>
                                                </div>
                                                <div *ngIf="allSkills.length == 0">
                                                    <div class="border-top-alignment"></div>
                                                    <div class="ms-5 gray-text">{{'workers.noSkills' | translate}}</div>
                                                    <div class="border-top-alignment"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 row">
                                            <div class="col-12 gray-text">{{'basic.selectedSkills' | translate}}</div>
                                            <div class="colors-skill text-align-center gray-text max-content rounded-pill ms-2 px-2" *ngFor="let item of skillsToAdd">
                                                {{item.name}}
                                            </div>
                                            <div *ngIf="skillsToAdd.length === 0">{{'workers.noSkillsSelected' | translate}}</div>
                                        </div>
                                    </div>

                                    <div class="mt-5">
                                        <button type="submit" class="btn btn-primary col-3">{{'basic.save' | translate}}</button>
                                        <button type="button" id="modalClose" class="btn btn-secondary ms-3 col-3" data-bs-dismiss="modal">{{'basic.cancel' | translate}}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="createNewSkillModal" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-body">
                                <h3>{{'workers.enterNewSkill' | translate}}</h3>
                                <form ngNativeValidate (ngSubmit)="createNewSkill()">
                                    <div class="form-group">
                                        <input type="text" [(ngModel)]="newSkill" [ngModelOptions]="{standalone: true}" class="form-control max-content" required>
                                    </div>
                                    <button type="submit" id="modalSaveButton" class="btn btn-primary">{{'basic.save' | translate}}</button>
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="modalCloseNewSkill">{{'basic.cancel' | translate}}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

