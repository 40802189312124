import { Injectable, OnDestroy } from '@angular/core';
import { icon, Icon } from 'leaflet';
import { Subject, takeUntil } from 'rxjs';
import { WorkorderService } from '../workorder/workorder.service';

@Injectable({
  providedIn: 'root'
})

export class MapService implements OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()

  // Values for markers
  boltSize = 32
  boltShadowSize = 35
  boltAnchor = 16
  orderSize = 28
  orderShadowSize = 32
  orderAnchor = 14

  constructor(
    private workorderService: WorkorderService,
  ) { }
  
  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  // Map Icons
  boltIcon: Icon = icon({
    iconUrl: 'assets/leaflet/bolt/bolt.svg',
    shadowUrl: 'assets/leaflet/marker-shadow.png',
    iconSize: [this.boltSize, this.boltSize],
    shadowSize: [this.boltShadowSize, this.boltShadowSize],
    iconAnchor: [this.boltAnchor, this.boltAnchor],
  });

  /**
   * Builds a key/value pair object from msa id:s and their default time-parameters and returns the object
   */
  getMsaEstimates() {
    let allMsaEstimates = {}

    this.workorderService.getMsas()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      data => {
        for (let i = 0; i < data.length; i++) {
          allMsaEstimates[data[i].id] = data[i].default_time_parameters
        }
        return allMsaEstimates

      }
    )
 }

  /**
   * Returns a coloured shape as an SVG string based on 3 parameters.
   * @param shape 
   * @param color 
   * @param dots 
   * @returns 
   */
  getGeneratedSvg(shape, color, dots) {

    let svg = ''
    // First thing to check is the shape of the item. If no shape is provided we use the default one.
    if (!shape || shape == 'ball') {
      shape = 'default'
    }

    // Then we check if the item has a color. If no color is provided we use the default one.
    if (!color) {
      color = '#a2a2a2'
    }

    // We have two kinds of markers. Dotted and non-dotted markers. (dot is a colorder dot in the corner of the icon)
    if (shape && !dots) {
      if (shape == 'default') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32">  <defs>    <clipPath id="clip-Element_dot_1">      <rect width="32" height="32"/>    </clipPath>  </defs>  <g id="Element_dot_1" data-name="Element + dot – 1" clip-path="url(#clip-Element_dot_1)">    <g id="Ellipse_696" data-name="Ellipse 696" transform="translate(4 4)" fill="none" stroke="' + color + '" stroke-width="3">      <circle cx="12" cy="12" r="12" stroke="none"/>      <circle cx="12" cy="12" r="10.5" fill="none"/>    </g>  </g></svg>'
      } else if (shape == 'ball') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32"><defs><clipPath id="clip-_32x32px_1"><rect width="32" height="32"/></clipPath></defs><g id="_32x32px_1" data-name="32x32px – 1" clip-path="url(#clip-_32x32px_1)"><circle id="Ellipse_696" data-name="Ellipse 696" cx="12" cy="12" r="12" transform="translate(4 4)" fill="'+ color +'"/></g></svg>'
      } else if (shape == 'cross') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32"><defs><clipPath id="clip-_32x32px_10"><rect width="32" height="32"/></clipPath></defs><g id="_32x32px_10" data-name="32x32px – 10" clip-path="url(#clip-_32x32px_10)"><path id="Union_46" data-name="Union 46" d="M-1833.607-6798.465l-7.25,7.249-4.142-4.141,7.25-7.251-7.25-7.25,4.142-4.143,7.25,7.251,7.249-7.251,4.142,4.145-7.249,7.249,7.249,7.25-4.142,4.141Z" transform="translate(1849.607 6818.608)" fill="' + color + '"/></g></svg>'
      } else if (shape == 'diamond') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32"><defs><clipPath id="clip-_32x32px_10"><rect width="32" height="32"/></clipPath></defs><g id="_32x32px_10" data-name="32x32px – 10" clip-path="url(#clip-_32x32px_10)"><path id="Union_46" data-name="Union 46" d="M-1833.607-6798.465l-7.25,7.249-4.142-4.141,7.25-7.251-7.25-7.25,4.142-4.143,7.25,7.251,7.249-7.251,4.142,4.145-7.249,7.249,7.249,7.25-4.142,4.141Z" transform="translate(1849.607 6818.608)" fill="' + color + '"/></g></svg>'
      } else if (shape == 'hexagon') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32"><defs><clipPath id="clip-_32x32px_8"><rect width="32" height="32"/></clipPath></defs><g id="_32x32px_8" data-name="32x32px – 8" clip-path="url(#clip-_32x32px_8)"><path id="Subtraction_13" data-name="Subtraction 13" d="M-1816.285-6775.7h0l-12.866-3.447-3.447-12.867,9.42-9.42,12.867,3.451,3.447,12.863-9.419,9.418Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 3530.738, 6102.275)" fill="' + color + '"/></g></svg>'
      } else if (shape == 'north-star') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32"><defs><clipPath id="clip-_32x32px_12"><rect width="32" height="32"/></clipPath></defs><g id="_32x32px_12" data-name="32x32px – 12" clip-path="url(#clip-_32x32px_12)"><path id="Union_48" data-name="Union 48" d="M-1835.443-6796.59l-9.556-3.925,9.556-3.925,3.927-9.561,3.928,9.56,9.558,3.926-9.558,3.926-3.928,9.56Z" transform="translate(1847.515 6816.515)" fill="' + color + '"/></g></svg>'
      } else if (shape == 'octagon') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32"><defs><clipPath id="clip-_32x32px_7"><rect width="32" height="32"/></clipPath></defs><g id="_32x32px_7" data-name="32x32px – 7" clip-path="url(#clip-_32x32px_7)"><path id="Subtraction_12" data-name="Subtraction 12" d="M-1816.028-6778h-9.945l-7.026-7.026v-9.943l7.031-7.031h9.937l7.031,7.031v9.943l-7.026,7.025Z" transform="translate(1837 6806)" fill="' + color + '"/></g></svg>'
      } else if (shape == 'plumbob') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32"><defs><clipPath id="clip-_32x32px_11"><rect width="32" height="32"/></clipPath></defs><g id="_32x32px_11" data-name="32x32px – 11" clip-path="url(#clip-_32x32px_11)"><path id="Path_698" data-name="Path 698" d="M0,0,14.12,5.9l5.9,14.12L5.9,14.12Z" transform="translate(16 1.846) rotate(45)" fill="' + color + '"/></g></svg>'
      } else if (shape == 'reverse-triangle') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32"><defs><clipPath id="clip-_32x32px_6"><rect width="32" height="32"/></clipPath></defs><g id="_32x32px_6" data-name="32x32px – 6" clip-path="url(#clip-_32x32px_6)"><path id="Polygon_84" data-name="Polygon 84" d="M14,0,28,25H0Z" transform="translate(30 29) rotate(180)" fill="' + color + '"/></g></svg>'
      } else if (shape == 'square-star') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32"><defs><clipPath id="clip-_32x32px_9"><rect width="32" height="32"/></clipPath></defs><g id="_32x32px_9" data-name="32x32px – 9" clip-path="url(#clip-_32x32px_9)"><path id="Union_45" data-name="Union 45" d="M-1838.121-6792.356l-5.506-1.475,1.475-5.506-2.848-4.934,4.933-2.849,1.475-5.506,5.506,1.476,4.937-2.851,2.85,4.937,5.5,1.475-1.475,5.5,2.85,4.937-4.936,2.851-1.475,5.5-5.5-1.475-4.936,2.85Z" transform="translate(6821.443 1830.048) rotate(-60)" fill="' + color + '"/></g></svg>'
      } else if (shape == 'square') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32"><defs><clipPath id="clip-_32x32px_2"><rect width="32" height="32"/></clipPath></defs><g id="_32x32px_2" data-name="32x32px – 2" clip-path="url(#clip-_32x32px_2)"><rect id="Rectangle_1284" data-name="Rectangle 1284" width="24" height="24" transform="translate(4 4)" fill="' + color + '"/></g></svg>'
      } else if (shape == 'star') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32"><defs><clipPath id="clip-_32x32px_5"><rect width="32" height="32"/></clipPath></defs><g id="_32x32px_5" data-name="32x32px – 5" clip-path="url(#clip-_32x32px_5)"><path id="Union_36" data-name="Union 36" d="M8,19.5H0L4,13,0,6.5H8L12,0l4,6.5h8L20,13l4,6.5H16L12,26Z" transform="translate(4 3)" fill="' + color + '"/></g></svg>'
      } else if (shape == 'triangle') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32"><defs><clipPath id="clip-_32x32px_3"><rect width="32" height="32"/></clipPath></defs><g id="_32x32px_3" data-name="32x32px – 3" clip-path="url(#clip-_32x32px_3)"><path id="Polygon_84" data-name="Polygon 84" d="M14,0,28,25H0Z" transform="translate(2 3)" fill="' + color + '"/></g></svg>'
      }
    }

    if (shape && dots) {
      if (shape == 'default') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32">  <defs>    <clipPath id="clip-Element_dot_1">      <rect width="32" height="32"/>    </clipPath>  </defs>  <g id="Element_dot_1" data-name="Element + dot – 1" clip-path="url(#clip-Element_dot_1)">    <g id="Ellipse_696" data-name="Ellipse 696" transform="translate(4 4)" fill="none" stroke="' + color + '" stroke-width="3">      <circle cx="12" cy="12" r="12" stroke="none"/>      <circle cx="12" cy="12" r="10.5" fill="none"/>    </g>    <circle id="Ellipse_797" data-name="Ellipse 797" cx="6" cy="6" r="6" transform="translate(20)" fill="' + dots + '"/>  </g></svg>'
      } else if (shape == 'ball') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32">  <defs>    <clipPath id="clip-Element_dot">      <rect width="32" height="32"/>    </clipPath>  </defs>  <g id="Element_dot" data-name="Element + dot" clip-path="url(#clip-Element_dot)">    <circle id="Ellipse_696" data-name="Ellipse 696" cx="12" cy="12" r="12" transform="translate(4 4)" fill="' + color + '"/>    <circle id="Ellipse_797" data-name="Ellipse 797" cx="6" cy="6" r="6" transform="translate(20)" fill="' + dots + '"/>  </g></svg>'
      } else if (shape == 'cross') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32">  <defs>    <clipPath id="clip-Element_dot">      <rect width="32" height="32"/>    </clipPath>  </defs>  <g id="Element_dot" data-name="Element + dot" clip-path="url(#clip-Element_dot)">    <path id="Union_46" data-name="Union 46" d="M-1833.607-6798.465l-7.25,7.249-4.142-4.141,7.25-7.251-7.25-7.25,4.142-4.143,7.25,7.251,7.249-7.251,4.142,4.145-7.249,7.249,7.249,7.25-4.142,4.141Z" transform="translate(1849.607 6818.608)" fill="' + color + '"/>    <circle id="Ellipse_797" data-name="Ellipse 797" cx="6" cy="6" r="6" transform="translate(20)" fill="' + dots + '"/>  </g></svg>'
      } else if (shape == 'diamond') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32">  <defs>    <clipPath id="clip-Element_dot">      <rect width="32" height="32"/>    </clipPath>  </defs>  <g id="Element_dot" data-name="Element + dot" clip-path="url(#clip-Element_dot)">    <rect id="Rectangle_1285" data-name="Rectangle 1285" width="20.385" height="20.385" transform="translate(1.586 16) rotate(-45)" fill="' + color + '"/>    <circle id="Ellipse_797" data-name="Ellipse 797" cx="6" cy="6" r="6" transform="translate(20)" fill="' + dots + '"/>  </g></svg>'
      } else if (shape == 'hexagon') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32">  <defs>    <clipPath id="clip-Element_dot">      <rect width="32" height="32"/>    </clipPath>  </defs>  <g id="Element_dot" data-name="Element + dot" clip-path="url(#clip-Element_dot)">    <path id="Subtraction_13" data-name="Subtraction 13" d="M-1816.285-6775.7h0l-12.866-3.447-3.447-12.867,9.42-9.42,12.867,3.451,3.447,12.863-9.419,9.418Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 3530.738, 6102.275)" fill="' + color + '"/>    <circle id="Ellipse_797" data-name="Ellipse 797" cx="6" cy="6" r="6" transform="translate(20)" fill="' + dots + '"/>  </g></svg>'
      } else if (shape == 'north-gstar') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32">  <defs>    <clipPath id="clip-Element_dot">      <rect width="32" height="32"/>    </clipPath>  </defs>  <g id="Element_dot" data-name="Element + dot" clip-path="url(#clip-Element_dot)">    <path id="Union_48" data-name="Union 48" d="M-1835.443-6796.59l-9.556-3.925,9.556-3.925,3.927-9.561,3.928,9.56,9.558,3.926-9.558,3.926-3.928,9.56Z" transform="translate(1847.515 6816.515)" fill="' + color + '"/>    <circle id="Ellipse_797" data-name="Ellipse 797" cx="6" cy="6" r="6" transform="translate(20)" fill="' + dots + '"/>  </g></svg>'
      } else if (shape == 'octagon') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32">  <defs>    <clipPath id="clip-Element_dot">      <rect width="32" height="32"/>    </clipPath>  </defs>  <g id="Element_dot" data-name="Element + dot" clip-path="url(#clip-Element_dot)">    <path id="Subtraction_12" data-name="Subtraction 12" d="M-1816.028-6778h-9.945l-7.026-7.026v-9.943l7.031-7.031h9.937l7.031,7.031v9.943l-7.026,7.025Z" transform="translate(1837 6806)" fill="' + color + '"/>    <circle id="Ellipse_797" data-name="Ellipse 797" cx="6" cy="6" r="6" transform="translate(20)" fill="' + dots + '"/>  </g></svg>'
      } else if (shape == 'plumbob') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32">  <defs>    <clipPath id="clip-Element_dot">      <rect width="32" height="32"/>    </clipPath>  </defs>  <g id="Element_dot" data-name="Element + dot" clip-path="url(#clip-Element_dot)">    <path id="Path_698" data-name="Path 698" d="M0,0,14.12,5.9l5.9,14.12L5.9,14.12Z" transform="translate(16 1.846) rotate(45)" fill="' + color + '"/>    <circle id="Ellipse_797" data-name="Ellipse 797" cx="6" cy="6" r="6" transform="translate(20)" fill="' + dots + '"/>  </g></svg>'
      } else if (shape == 'reverse-triangle') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32">  <defs>    <clipPath id="clip-Element_dot">      <rect width="32" height="32"/>    </clipPath>  </defs>  <g id="Element_dot" data-name="Element + dot" clip-path="url(#clip-Element_dot)">    <path id="Polygon_84" data-name="Polygon 84" d="M14,0,28,25H0Z" transform="translate(30 29) rotate(180)" fill="' + color + '"/>    <circle id="Ellipse_797" data-name="Ellipse 797" cx="6" cy="6" r="6" transform="translate(20)" fill="' + dots + '"/>  </g></svg>'
      } else if (shape == 'square-star') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32">  <defs>    <clipPath id="clip-Element_dot">      <rect width="32" height="32"/>    </clipPath>  </defs>  <g id="Element_dot" data-name="Element + dot" clip-path="url(#clip-Element_dot)">    <path id="Union_45" data-name="Union 45" d="M-1838.121-6792.356l-5.506-1.475,1.475-5.506-2.848-4.934,4.933-2.849,1.475-5.506,5.506,1.476,4.937-2.851,2.85,4.937,5.5,1.475-1.475,5.5,2.85,4.937-4.936,2.851-1.475,5.5-5.5-1.475-4.936,2.85Z" transform="translate(6821.443 1830.048) rotate(-60)" fill="' + color + '"/>    <circle id="Ellipse_797" data-name="Ellipse 797" cx="6" cy="6" r="6" transform="translate(20)" fill="' + dots + '"/>  </g></svg>'
      } else if (shape == 'square') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32">  <defs>    <clipPath id="clip-Element_dot">      <rect width="32" height="32"/>    </clipPath>  </defs>  <g id="Element_dot" data-name="Element + dot" clip-path="url(#clip-Element_dot)">    <rect id="Rectangle_1284" data-name="Rectangle 1284" width="24" height="24" transform="translate(4 4)" fill="' + color + '"/>    <circle id="Ellipse_797" data-name="Ellipse 797" cx="6" cy="6" r="6" transform="translate(20)" fill="' + dots + '"/>  </g></svg>'
      } else if (shape == 'star') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32">  <defs>    <clipPath id="clip-Element_dot">      <rect width="32" height="32"/>    </clipPath>  </defs>  <g id="Element_dot" data-name="Element + dot" clip-path="url(#clip-Element_dot)">    <path id="Union_36" data-name="Union 36" d="M8,19.5H0L4,13,0,6.5H8L12,0l4,6.5h8L20,13l4,6.5H16L12,26Z" transform="translate(4 3)" fill="' + color + '"/>    <circle id="Ellipse_797" data-name="Ellipse 797" cx="6" cy="6" r="6" transform="translate(20)" fill="' + dots + '"/>  </g></svg>'
      } else if (shape == 'triangle') {
        svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 32 32">  <defs>    <clipPath id="clip-Element_dot">      <rect width="32" height="32"/>    </clipPath>  </defs>  <g id="Element_dot" data-name="Element + dot" clip-path="url(#clip-Element_dot)">    <path id="Polygon_84" data-name="Polygon 84" d="M14,0,28,25H0Z" transform="translate(2 3)" fill="' + color + '"/>    <circle id="Ellipse_797" data-name="Ellipse 797" cx="6" cy="6" r="6" transform="translate(20)" fill="' + dots + '"/>  </g></svg>'
      }
    }

    return svg
  }
}
