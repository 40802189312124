import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAccessTypeByContractor'
})
export class FilterAccessTypeByContractorPipe implements PipeTransform {

  transform(accessTypeList: any[], selectedContractorId: number | undefined): any[] {
    if (!accessTypeList || !selectedContractorId) {
      return accessTypeList;
    }
    return accessTypeList.filter(at => (at.contractorIds.some(pid => pid === selectedContractorId)) || (at.contractorIds[0] === 0));
  }

}
