import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CalendarsService } from '@modules/planner/services/calendars/calendars.service';
import { TranslateService } from '@ngx-translate/core';
import { ISOWeekTransformerService } from '@shared/services/iso-week-transformer/iso-week-transformer.service';
import { ToastService } from '@shared/services/toast/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-end-user-communication',
  templateUrl: './end-user-communication.component.html',
  styleUrls: ['./end-user-communication.component.scss'],
})
export class EndUserCommunicationComponent implements OnInit {

  constructor(
    private calendarsService: CalendarsService,
    private datePipe: DatePipe,
    private userService: UserService,
    private translateService: TranslateService,
    private ISOWeekTransformer: ISOWeekTransformerService,
    private toastService: ToastService
  ) {}

  // Global values
  currentMonth: Date = new Date();
  currentWeekStart: Date = new Date();
  currentWeekEnd: Date = new Date();
  weekSpinner: boolean = true;
  workorderAmountSpinner: boolean = true;
  contractorId: number = 0;
  contractorSubscription = new Subscription();
  callingCSVDownloaded = false;

  weeks: Array<any> = [
    {
      id: 1,
      number: 1,
      startdate: new Date(),
      enddate: new Date(),
      icon: 'assets/icons/forward_to_inbox_green_48.svg',
    },
    {
      id: 2,
      number: 2,
      startdate: new Date(),
      enddate: new Date(),
      icon: 'assets/icons/forward_to_inbox_green_48.svg',
    },
    {
      id: 3,
      number: 3,
      startdate: new Date(),
      enddate: new Date(),
      icon: 'assets/icons/forward_to_inbox_green_48.svg',
    },
    {
      id: 4,
      number: 4,
      startdate: new Date(),
      enddate: new Date(),
      icon: 'assets/icons/forward_to_inbox_green_48.svg',
    },
    {
      id: 5,
      number: 5,
      startdate: new Date(),
      enddate: new Date(),
      icon: 'assets/icons/mail_open_yellow_48.svg',
    },
  ];

  // Mailing file
  mailStatuses: Array<number> = [0, 1]
  forwardedMailStatus: string | boolean | null = false;
  downloadedMailStatus: string | boolean | null = false;
  downloadedAllMailStatus: string | boolean | null = false;

  // Calendars
  calendarAllWeeks = {};
  selectedWeek;
  weekClickedNr;
  workorderAmount: number = 0;
  lastCallingCSVDownloaded: boolean = true;

  ngOnInit(): void {
    this.getCalendars();
  }

  /**
   * Give date to this function and it changes weeks in interface top bar to match correct month. Changes quickly so can be
   * spammed with direction change.
   * Calculates amount of weeks first.
   * Sets first date and ending date to every week
   * Special treatment for first occurrence of week
   * @param givenDate date option
   */
  setWeeks(givenDate: Date) {

    this.weeks = Array()
    // Get first day of the month
    let startDate: Date = new Date(givenDate.getFullYear(), givenDate.getMonth(), 1);
    // Get last day of the month
    let finalDate: Date = new Date(givenDate.getFullYear(), givenDate.getMonth() + 1, 0);

    // Calculate the number of days from the start day to the end of the first week
    let daysInFirstWeek = 7 - startDate.getDay();
    // Calculate the number of days from the start of the last week to the end of the month
    let daysInLastWeek = finalDate.getDay() + 1;
    // Calculate the total days excluding the first and last weeks
    let totalMiddleDays = finalDate.getDate() - daysInFirstWeek - (7 - daysInLastWeek);

    let amountOfWeeks = (daysInFirstWeek + totalMiddleDays + (7 - daysInLastWeek)) / 7;

    // Logic that changes decimal to whole number
    if (amountOfWeeks > 4 && amountOfWeeks < 5) amountOfWeeks = 5;
    else if (amountOfWeeks <= 4) amountOfWeeks = 4;
    else if (amountOfWeeks > 5) amountOfWeeks = 6;
    let startingDate;
    let endingDate;
    // Set every week to month
    for (let i = 0; i < amountOfWeeks; i++) {
      // Different logic for first week
      if (i == 0) {
        let firstDay = new Date(
          givenDate.getFullYear(),
          givenDate.getMonth(),
          1
        );
        if (firstDay.getDay() != 0)
          firstDay.setDate(firstDay.getDate() - firstDay.getDay() + 1);
        else firstDay.setDate(firstDay.getDate() - 6);
        this.currentWeekStart = firstDay;
        this.currentWeekEnd = new Date(firstDay);
        this.currentWeekEnd.setDate(this.currentWeekEnd.getDate() + 6);
        startingDate = new Date(
          firstDay.getFullYear(),
          firstDay.getMonth(),
          firstDay.getDate()
        );
        endingDate = new Date(startingDate);
        endingDate.setDate(endingDate.getDate() + 6);
      }
      // Default logic for all other weeks
      else {
        startingDate.setDate(startingDate.getDate() + 7);
        endingDate.setDate(startingDate.getDate() + 6);
      }


      // JSON operations are needed because without them all values would be the same at end.
      this.weeks.push({
        startDate: JSON.parse(JSON.stringify(startingDate)),
        endDate: JSON.parse(JSON.stringify(endingDate)),
        icon: 'assets/icons/forward_to_inbox_green_48.svg',
        id: i,
        selected: false
      });
    }
  }

  getContractorCalendars() {
    if (this.contractorId != 0) {
      this.contractorSubscription.unsubscribe();
      this.contractorSubscription = this.calendarsService
        .getContractorCalendars(
          this.contractorId,
          this.datePipe.transform(
            new Date(this.currentWeekStart),
            'yyyy-MM-dd'
          ),
          this.datePipe.transform(new Date(this.currentWeekEnd), 'yyyy-MM-dd')
        )
        .subscribe((data) => {
          let allCalendars = data['message'];
          let workorderCount = 0;
          this.workorderAmount = 0;
          for (let i = 0; i < allCalendars.length; i++) {
            if (allCalendars[i].slots.length === 0) {
              this.workorderAmount = 0
              this.workorderAmountSpinner = false
            } else {
              for (const key in allCalendars[i].slots) {
                if (
                  Object.prototype.hasOwnProperty.call(allCalendars[i].slots, key)
                ) {
                  const element = allCalendars[i].slots[key];
                  if (element.ordercount > 0)
                    workorderCount = workorderCount + element.ordercount;
                  if (
                    allCalendars[i].slots[
                      Object.keys(allCalendars[i].slots)[
                        Object.keys(allCalendars[i].slots).length - 1
                      ]
                    ]
                  ) {
                    this.workorderAmount = workorderCount;
                    this.workorderAmountSpinner = false;
                  }
                } else {
                  this.workorderAmount = 0
                  this.workorderAmountSpinner = false
                }
              }
            }
          }
          if (allCalendars.length == 0) {
            this.workorderAmount = 0;
            this.workorderAmountSpinner = false;
          }
        this.checkMailingStatus()
        });
    } else {
      Swal.fire(
        this.translateService.instant('planner.endUserCommunications.contractorIdNotFound'),
        this.translateService.instant('planner.endUserCommunications.makeSureYouAreInContractor'));
      this.workorderAmountSpinner = false;
    }
  }

  getCalendarsInit() {
    this.getCurrentContractorId(() => {
      this.getContractorCalendars();
    });
  }

  /**
   * Gets current contractor id and calls a callback method to tell that it has finished
   * @param cb callback method
   */
  getCurrentContractorId(cb) {
    this.userService.getUserInfo().subscribe((data) => {
      this.contractorId = data.current_contractor;
      // cb() = callback to let the other function know that this one is finished
      cb();
    });
  }

  getIcon(i) {
    if (this.weekSpinner) return null;
    else {
      let date = new Date(i);
      let key = this.datePipe.transform(date, 'w') + '-' + date.getFullYear();
      let icon = 'assets/icons/forward_to_inbox_green_48.svg';
      if (this.calendarAllWeeks[key]) {
        for (let j = 0; j < this.calendarAllWeeks[key].length; j++) {
          const element = this.calendarAllWeeks[key][j];
          if (!element.forwardedBoolean)
            icon = 'assets/icons/mail_open_yellow_48.svg';
        }
      } else {
        icon = 'assets/icons/mail_open_yellow_48.svg';
      }
      return icon;
    }
  }

  moveMonth(direction: string) {
    if (direction == 'next') {
      let enableMutation = new Date(this.currentMonth);
      enableMutation.setMonth(this.currentMonth.getMonth() + 1);
      this.currentMonth = enableMutation;
      this.setWeeks(this.currentMonth);
    } else if (direction == 'previous') {
      let enableMutation = new Date(this.currentMonth);
      enableMutation.setMonth(this.currentMonth.getMonth() - 1);
      this.currentMonth = enableMutation;
      this.setWeeks(this.currentMonth);
    }
    this.weekClicked(0);
  }

  getCalendars() {
    this.calendarsService.getCalendars().subscribe((data) => {
      this.setCalendars(data);
    });
  }

  weekClicked(id: number) {
    this.weekClickedNr = id;
    for (let i = 0; i < this.weeks.length; i++) {
      if (this.weeks[i].id == id) {
        this.weeks[i].selected = true
        this.currentWeekStart = this.weeks[i].startDate;
        this.currentWeekEnd = this.weeks[i].endDate;
        let startDate = new Date(this.currentWeekStart);
        let weekNr = this.datePipe.transform(startDate, 'w');
        if (
          this.calendarAllWeeks[weekNr + '-' + startDate.getFullYear()] != undefined
        ) {
          this.selectedWeek = this.calendarAllWeeks[weekNr + '-' + startDate.getFullYear()];
        } else this.selectedWeek = null;
      } else this.weeks[i].selected = false
    }
    if (this.forwardedMailStatus) this.forwardedMailStatus = false;
    this.workorderAmountSpinner = true;
    if (this.contractorId == 0) this.getCalendarsInit();
    else this.getContractorCalendars();
  }

  changeForwarded(forwardedId, id) {
    for (let i = 0; i < this.selectedWeek.length; i++) {
      if (this.selectedWeek[i].id == id) {
        if (forwardedId === 1) {
          this.calendarsService.updateCalendarForwarded(id, null);
          this.selectedWeek[i].forwardedBoolean = false;
          this.selectedWeek[i].forwarded = null;
        } else {
          this.selectedWeek[i].forwarded = new Date();
          this.calendarsService.updateCalendarForwarded(
            id,
            this.datePipe.transform(
              this.selectedWeek[i].forwarded,
              'yyyy-MM-dd HH:mm'
            )
          );
          this.selectedWeek[i].forwardedBoolean = true;
        }
      }
    }
  }

  downloadMultipleCSV() {
      let ids = Array();
      for (let i = 0; i < this.selectedWeek.length; i++) {
          const element = this.selectedWeek[i];
          if (!element.csv_downloaded) {
              ids.push(element.id);
          }
      }
      Swal.fire({
        title: this.translateService.instant('planner.endUserCommunications.sendMessages'),
        showCancelButton: false,
        showDenyButton: true,
        confirmButtonText: this.translateService.instant('planner.endUserCommunications.send'),
        denyButtonText: this.translateService.instant('planner.endUserCommunications.dontSend'),
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.fetchMultipleMailingCSV(ids, true)
        }else if (result.isDenied) {
          this.fetchMultipleMailingCSV(ids, false)
        }
      })

  }

  fetchMultipleMailingCSV(ids, send) {
    let wnum = this.datePipe.transform(this.currentWeekStart, 'w')
    this.calendarsService.getMultipleMailingCSV(ids, send).subscribe({
      next: data => {
        this.exportCSV(data, 'Mailinglist_multiple_w'+wnum);
        this.downloadedMailStatus = this.datePipe.transform(
          new Date(),
          'dd.MM.yyyy HH:mm'
        );
        this.downloadedAllMailStatus = true
      },
      error: error => {
        if (error.message.includes('400 Bad Request')) {
          this.toastService.sendToast(false, this.translateService.instant('planner.endUserCommunications.errorMessages.400_3'));
        } else if (error.message.includes('err_location_number_not_found')) {
          this.toastService.sendToast(false, this.translateService.instant('planner.endUserCommunications.errorMessages.location_number_not_found'))
        } else if (error.message.includes('err_endusers_not_found')) {
          this.toastService.sendToast(false, this.translateService.instant('planner.endUserCommunications.errorMessages.endusers_not_found'))
        } else {
          this.toastService.sendToast(false, this.translateService.instant('planner.endUserCommunications.errorMessages.unknown_3'));
        }
        this.lastCallingCSVDownloaded = true;
      }
    })

  }

  downloadCSV(id, name, multipleCSV) {
    Swal.fire({
      title: this.translateService.instant('planner.endUserCommunications.sendMessages'),
      showCancelButton: false,
      showDenyButton: true,
      confirmButtonText: this.translateService.instant('planner.endUserCommunications.send'),
      denyButtonText: this.translateService.instant('planner.endUserCommunications.dontSend'),
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.fetchMailingCSV(id, name, multipleCSV, true)
      }else if (result.isDenied) {
        this.fetchMailingCSV(id, name, multipleCSV, false)
      }
    })

  }

  fetchMailingCSV(id, name, multipleCSV, send) {
    let currentCalendar: any = null;
    for (let j = 0; j < this.selectedWeek.length; j++) {
      const calendar = this.selectedWeek[j];
      if (calendar.id === id) {
        calendar.spinner = true;
        currentCalendar = calendar;
        break;
      }
    }
    this.calendarsService.getMailingCSV(id, send).subscribe({
      next: response => {
        let csvSet = false;
        for (const key in this.calendarAllWeeks) {
          if (
            Object.prototype.hasOwnProperty.call(this.calendarAllWeeks, key)
          ) {
            const element = this.calendarAllWeeks[key];
            if (csvSet == true) break;
            for (let i = 0; i < element.length; i++) {
              const item = element[i];
              if (item.id == id) {
                item.csv_downloaded = new Date();
                csvSet = true;
                break;
              }
            }
          }
        }
        this.exportCSV(response, name);

        if (multipleCSV && this.downloadedMailStatus != null)
          this.downloadedMailStatus = this.datePipe.transform(
            new Date(),
            'dd.MM.yyyy HH:mm'
          );
        if (currentCalendar) currentCalendar.spinner = false;
        this.checkMailingStatus()
      },
      error: error => {
        if (error.message.includes('400 Bad Request')) {
          this.toastService.sendToast(false, this.translateService.instant('planner.endUserCommunications.errorMessages.400_3'));
        } else if (error.message.includes('err_location_number_not_found')) {
          this.toastService.sendToast(false, this.translateService.instant('planner.endUserCommunications.errorMessages.location_number_not_found'))
        } else if (error.message.includes('err_endusers_not_found')) {
           this.toastService.sendToast(false, this.translateService.instant('planner.endUserCommunications.errorMessages.endusers_not_found'))
        } else {
            this.toastService.sendToast(false, this.translateService.instant('planner.endUserCommunications.errorMessages.unknown_3'));
        }
        this.downloadedMailStatus = null;
        if (currentCalendar) currentCalendar.spinner = false;
      }
    })
}

  exportCSV(response, name) {
    let fileName = name + '.csv';
    let fileContent = response;

    const file = new Blob([fileContent], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = fileName;
    link.click();
    link.remove();
  }

  downloadMultipleCallingCSV() {
    let wnum = this.datePipe.transform(this.currentWeekStart, 'w')
    let ids = Array();
    this.lastCallingCSVDownloaded = false;
    for (let i = 0; i < this.selectedWeek.length; i++) {
      const element = this.selectedWeek[i];
      ids.push(element.id);
    }
    this.calendarsService.getMultipleCallingCSV(ids).subscribe({
      next: data => {
        this.exportCSV(data, 'Callinglist_multiple_w'+wnum);
        this.callingCSVDownloaded = true;
        this.lastCallingCSVDownloaded = true;
      },
      error: error => {
        if (error['message'].includes('400 Bad Request')) {
          if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.400_2'));
        } else {
          if (!Swal.isVisible())
            Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.unknown_2'));
        }
        this.lastCallingCSVDownloaded = true;
      }
    })
  }

  downloadNotConfirmedCallingCSV() {
    let wnum = this.datePipe.transform(this.currentWeekStart, 'w')
    let ids = Array();
    this.lastCallingCSVDownloaded = false;
    for (let i = 0; i < this.selectedWeek.length; i++) {
      const element = this.selectedWeek[i];
      ids.push(element.id);
    }
    this.calendarsService.getNotConfirmedCallingCSV(ids).subscribe({
      next: data => {
        this.exportCSV(data, 'Callinglist_multiple_notconfirmed_w'+wnum);
        this.callingCSVDownloaded = true;
        this.lastCallingCSVDownloaded = true;
      },
      error: error => {
        if (error['message'].includes('400 Bad Request')) {
          if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.400_2'));
        } else {
          if (!Swal.isVisible())
            Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.unknown_2'));
        }
        this.lastCallingCSVDownloaded = true;
      }
    })
  }

  changeCurrentMailStatus(id) {
    for (let i = 0; i < this.selectedWeek.length; i++) {
      const element = this.selectedWeek[i];
      if (element.forwarded == null && id === 0) {
        this.forwardedMailStatus = this.datePipe.transform(
          new Date(),
          'dd.MM.yyyy HH:mm'
        );
        element.forwarded = new Date();
        this.selectedWeek[i].forwardedBoolean = true;
        this.calendarsService.updateCalendarForwarded(
          this.selectedWeek[i].id,
          this.datePipe.transform(
            this.selectedWeek[i].forwarded,
            'yyyy-MM-dd HH:mm'
          )
        );
      } else if (element.forwarded && id === 1) {
        this.forwardedMailStatus = false;
        this.calendarsService.updateCalendarForwarded(
          this.selectedWeek[i].id,
          null
        );
        element.forwardedBoolean = false;
        element.forwarded = null;
      }
    }
  }

  setCalendars(calendars) {
    for (const key in calendars) {
      if (Object.prototype.hasOwnProperty.call(calendars, key)) {
        const element = calendars[key];
        let starttime = new Date(element.startdate);
        let endtime = new Date(element.enddate);
        let startWeek = this.ISOWeekTransformer.getISOWeekNumber(starttime)
        let endWeek = this.ISOWeekTransformer.getISOWeekNumber(endtime)

        let weekCount = Array();
        if (startWeek && endWeek) {
          for (let i = startWeek; i <= endWeek; i++) {
            weekCount.push(i);
          }
        }

        for (let i = 0; i < weekCount.length; i++) {
          const element = weekCount[i];
          this.calendarAllWeeks[element + '-' + starttime.getFullYear()] = [];
        }
      }
    }

    for (const key in calendars) {
      if (Object.prototype.hasOwnProperty.call(calendars, key)) {
        const element = calendars[key];

        let starttime = new Date(element.startdate);
        let endtime = new Date(element.enddate);
        let startWeek = this.ISOWeekTransformer.getISOWeekNumber(starttime)
        let endWeek = this.ISOWeekTransformer.getISOWeekNumber(endtime)

        let countWeeks = Array();
        if (startWeek && endWeek) {
          for (let i = startWeek; i <= endWeek; i++) {
            countWeeks.push(i);
          }
        }

        for (let i = 0; i < countWeeks.length; i++) {
          const weekNr = countWeeks[i];

          for (const calendarKey in this.calendarAllWeeks) {
            if (
              Object.prototype.hasOwnProperty.call(
                this.calendarAllWeeks,
                calendarKey
              )
            ) {
              if (
                !this.calendarAllWeeks[
                  weekNr + '-' + starttime.getFullYear()
                ].includes(element)
              ) {
                element.spinner = false;
                if (element.forwarded != null) {
                  element.forwardedBoolean = true;
                } else {
                  element.forwardedBoolean = false;
                }
                this.calendarAllWeeks[
                  weekNr + '-' + starttime.getFullYear()
                ].push(element);
              }
            }
          }
        }
      }
    }
    this.setWeeks(this.currentMonth);
    this.weekSpinner = false;
    if (this.weekClickedNr) {
      this.weekClicked(this.weekClickedNr);
    } else {
      this.weekClicked(0);
    }
  }

  checkMailingStatus() {
    if (this.selectedWeek) {
      for (let i = 0; i < this.selectedWeek.length; i++) {
        const element = this.selectedWeek[i];
        if (!element.csv_downloaded) {
          this.downloadedAllMailStatus = false
          return false
        }
      }
      this.downloadedAllMailStatus = true
      return true
    } else return false
  }
}
