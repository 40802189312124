import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { CalendarsService } from '@modules/planner/services/calendars/calendars.service';
import { finalize, forkJoin, Observable, Subject, takeUntil } from 'rxjs';
import { WorkorderService } from '@modules/planner/services/workorder/workorder.service';
import { ToastService } from '@shared/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'app-weekly-planning-detail',
  templateUrl: './weekly-planning-detail.component.html',
  styleUrls: ['./weekly-planning-detail.component.scss']
})
export class WeeklyPlanningDetailComponent implements OnInit, OnDestroy {

  userInfo: any;

  componentDestroyed$: Subject<boolean> = new Subject()

  id;
  days = Array();
  toggleSlots = false;

  tempDays;
  slotOriginalStartTime = new Date('7:00')

  week;
  startDate
  endDate
  firstDayOfWeek;
  lastDayOfWeek;
  currentDay = new Date()

  currentDescription;
  currentSlot;
  editDescription = false

  toggleCopySlots = false;
  // Original copy, used in copying process to check which days to select for copying
  copy = [
    {
      name: 'monLong',
      checked: true
    },
    {
      name: 'tueLong',
      checked: true
    },
    {
      name: 'wedLong',
      checked: true
    },
    {
      name: 'thuLong',
      checked: true
    },
    {
      name: 'friLong',
      checked: true
    },
    {
      name: 'satLong',
      checked: true
    },
    {
      name: 'sunLong',
      checked: true
    },
  ]
  copyWholeWeek = true;
  disabledSlotsCopy = true;
  copyDisabled = false;
  copiedWeek = false;
  weekNumber;

  templateName;
  templatesArray = Array()
  toggleTemplates = false;

  slotAddSlot = [
    {
      id: 0,
      start: null,
      duration: null,
      slot_max: null
    },
    {
      id: 1,
      start: null,
      duration: null,
        slot_max: null
    },
    {
      id: 2,
      start: null,
      duration: null,
        slot_max: null
    },
    {
      id: 3,
      start: null,
      duration: null,
        slot_max: null
    },
    {
      id: 4,
      start: null,
      duration: null,
        slot_max: null
    },
    {
      id: 5,
      start: null,
      duration: null,
        slot_max: null
    },
    {
      id: 6,
      start: null,
      duration: null,
        slot_max: null
    },

  ]

  widthSlot = [
    {
      id: 0,
      width:'14.2%'
    },
    {
      id: 1,
      width:'14.2%'
    },
    {
      id: 2,
      width:'14.2%'
    },
    {
      id: 3,
      width:'14.2%'
    },
    {
      id: 4,
      width:'14.2%'
    },
    {
      id: 5,
      width:'14.2%'
    },
    {
      id: 6,
      width:'14.2%'
    },
  ]

  calendarName;
  originalCalendarName;
  defaultmax: number = 1000;
  calendarSlotFill: number = 150;
  originalCalendarSlotFill: number = 150;
  teamId: any;
  mode;
  saveCalendarText = null
  calendarId;
  originalSlots = Array()
  moveToMap: boolean = false;
  // Cleanup calendar selection for edit mode
  cleanupCalendars = Array()
  selectedCleanupCalendar: any = {name: null, id: 0}
  cleanupCalendarChanged = false;
  cleanupCalendarSpinner = true;

  // Cleanup calendar
  cleanupCalendar = false;
  msas: any[] = [{name: null, id: null}]
  msa: any = this.msas[0]
  msaId;
  msaChanged = false;
  expand = "assets/icons/expand_more_black_24dp.svg"
  expand2 = "assets/icons/expand_more_black_24dp.svg"
  calendarStartCleanup;
  calendarEndCleanup;
  timeWrong: boolean = false;
  saveSpinner: boolean = false;
  msaSpinner: boolean = true;

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private calendarsService: CalendarsService,
    private route: ActivatedRoute,
    private workorderService: WorkorderService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  slotDays = [
    {
      name: "monday",
      checked: false,
      slot: null,
      disabled: false,
      cursor: 'pointer'
    },
    {
      name: "tuesday",
      checked: false,
      slot: null,
      disabled: false,
      cursor: 'pointer'
    },
    {
      name: "wednesday",
      checked: false,
      slot: null,
      disabled: false,
      cursor: 'pointer'
    },
    {
      name: "thursday",
      checked: false,
      slot: null,
      disabled: false,
      cursor: 'pointer'
    },
    {
      name: "friday",
      checked: false,
      slot: null,
      disabled: false,
      cursor: 'pointer'
    },
    {
      name: "saturday",
      checked: false,
      slot: null,
      disabled: false,
      cursor: 'pointer'
    },
    {
      name: "sunday",
      checked: false,
      slot: null,
      disabled: false,
      cursor: 'pointer'
    }
  ]

  slots: any = []

  // Initialize days for week
  ngOnInit() {
    this.getTranslations()
    this.route.data.subscribe(data => {
      const userInfo = data.userInfo;
      const language = userInfo.language;
      this.translateService.use(language);
    });
    this.route.queryParams.subscribe(params => {
      this.mode = params['mode']
      if (this.mode == 'create') {
        this.getCleanupCreate()
        this.getMsas()
      }
      if (this.mode == 'update') {
        this.calendarId = params['calendarId']
        this.getCalendar(this.calendarId, true)
      }
      else {
        this.getDaysOfWeek(new Date(), 0);
      }
    })
    this.route.paramMap.subscribe(pmap =>  {
      this.id = pmap.get('id')
      if (this.id == 'null') {
        this.cleanupCalendar = true;

      }
      else this.setTeamId(this.id)
    })
    //this.getMsas()

  }

  getTranslations() {

    this.translateService.get('basic.save').subscribe(
      () => {
        // Slots
        this.slotDays.forEach(day => {
          day.name = this.translateService.instant(`basic.weekDays.${day.name}`)
        })
        this.slots.push(
          {
            name: this.translateService.instant('weeklyPlanningDetails.slotType') + " 1",
            days: JSON.parse(JSON.stringify(this.slotDays)),
            selectedSlots: 1,
            slotDuration: 1,
            firstSlotStartTime: '07:00'
          }
        )
        // Save changes
        this.saveCalendarText = this.translateService.instant('calendar.saveCalendar')
        // Copy slots
        this.copy.forEach(day => {
          day.name = this.translateService.instant(`basic.weekDays.${day.name}`)
        })
        this.msas[0].name = this.translateService.instant('weeklyPlanningDetails.noMsa')
      }
    )


  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  /**
   * Simple navigation back to dashboard page
   */
  backToDashboard() {
    this.router.navigate(['planner/weekly-planning'])
  }

  /**
   * Saving calendar function. Go through days array to check which weeks have slots put. Make
   * daysToSave array to save correct slots. Configure dates to fit backend demands
   */
  saveCalendar() {
    if(this.calendarSlotFill > this.defaultmax) {
      this.calendarSlotFill = this.defaultmax
    }
    if (this.cleanupCalendar) {
      let startDateCreate = new Date(this.calendarStartCleanup)
      let endDateCreate = new Date(this.calendarEndCleanup)
      this.timeWrong = startDateCreate.getTime() >= endDateCreate.getTime();
    }
    if (!this.timeWrong) {
      if (this.mode == 'update' && this.cleanupCalendarSpinner && !this.cleanupCalendar) Swal.fire(this.translateService.instant('weeklyPlanningDetails.waitForSpinner'))
      else {
        this.saveSpinner = true
        let daysToSave = Array()
        let calendarType;
        if (this.cleanupCalendar) calendarType = 2;
        else calendarType = 1;
        let startdateCalendar;
        let enddateCalendar;
        if (this.cleanupCalendar) {
          startdateCalendar = this.calendarStartCleanup
          enddateCalendar = this.calendarEndCleanup
        } else {
          startdateCalendar = ""
          enddateCalendar = ""
        }
        let calendar = {
          name: this.calendarName,
          type: calendarType,
          startdate:  startdateCalendar,
          enddate: enddateCalendar,
          slots: Array(),
          msaId: null,
          cleanupCalendarId: null,
          slot_max_default: this.calendarSlotFill
        }
        // Set msaId if cleanup calendar
        if (this.msa.id !== 0) calendar.msaId = this.msa['id']
        if (!this.cleanupCalendar) {
          if (this.selectedCleanupCalendar.id !== 0) calendar.cleanupCalendarId = this.selectedCleanupCalendar['id']
        }
        // Check which weeks have slots
        for (let i = 0; i < this.days.length; i++) {
          let boolean = false;
          for (let j = 0; j < this.days[i].array.length; j++) {
            if (this.days[i].array[j].slot.length > 0) {
              boolean = true
              break;
            }
          }
          // Push to daysToSave if has slots
          if (boolean) daysToSave.push(this.days[i])
        }
        let comparison = false;
        if(this.compareObjects(daysToSave)) comparison = true
        if (!this.cleanupCalendar && daysToSave.length == 0) {
          calendar.startdate = this.days[0].firstDayOriginal
          calendar.enddate = this.days[0].lastDayOriginal
        }

        if (!this.cleanupCalendar) {
          // Get correct startdate and enddate
          for (let j = 0; j < daysToSave.length; j++) {
            // If startdate has not been intialized run this
            if (calendar.startdate == "") calendar.startdate = daysToSave[j].firstDayOriginal

            // Compare times to see which one of startdates is earlier
            if (calendar.startdate) {
              let firstDay = new Date(calendar.startdate)
              if (firstDay.getTime() > daysToSave[j].startDateOriginal.getTime()) {
                calendar.startdate = daysToSave[j].firstDayOriginal
              }

            }
            // Do the same for enddate what we did for startdate
            if (calendar.enddate == "") calendar.enddate = daysToSave[j].lastDayOriginal
            if (calendar.enddate) {

              let lastDay = new Date(calendar.enddate)


              let lastDayOriginal = new Date(daysToSave[j].lastDayOriginal)

              if (lastDay.getTime() < lastDayOriginal.getTime()) {
                calendar.enddate = daysToSave[j].lastDayOriginal
              }
            }

          }
        }

        let slotsFinal = Array()
        let idValue = 0;
        // Go through slots array and configure it
        for (let k = 0; k < daysToSave.length; k++) {
          for (let j = 0; j < daysToSave[k].array.length; j++) {
            let finalDate = new Date(daysToSave[k].firstDayOriginal)
            // Set date to be correct according to index of for loop (Monday is first and wednesday is third for example)
            finalDate.setDate(finalDate.getDate() + j)
            // Configure slots's disabled value, start time and end time
            for (let n = 0; n < daysToSave[k].array[j].slot.length; n++) {
              // Get the disabled reason
              let disabledReasonText = null
              if (daysToSave[k].array[j].slot[n].description) disabledReasonText = daysToSave[k].array[j].slot[n].description
              // Get the disabled value if it is disabled or not
              let disabledValue
              if (daysToSave[k].array[j].slot[n].disabled == false) {
                  disabledValue = 0
              }  else {
                  disabledValue = 1
              }
                // Get the slot max
              let slotmax: null | number = null
              if (daysToSave[k].array[j].slot[n].slot_max) slotmax = daysToSave[k].array[j].slot[n].slot_max
              if (slotmax && slotmax > this.defaultmax) {
                  slotmax = this.defaultmax
                  daysToSave[k].array[j].slot[n].slot_max = slotmax
              }
              // Configure ending and starting time
              let timestart = this.datePipe.transform(finalDate, 'yyyy-MM-dd') + " " +  daysToSave[k].array[j].slot[n].timeStart
              let timeend = this.datePipe.transform(finalDate, 'yyyy-MM-dd') + " " + daysToSave[k].array[j].slot[n].timeEnd
              slotsFinal.push({id: idValue,
                  disabled: disabledValue,
                  starttime: timestart,
                  endtime: timeend,
                  disabled_reason: disabledReasonText,
                  slot_max: slotmax
              })
              idValue++;
            }

          }
        }
        // Push slots to calendar.slots
        calendar.slots = slotsFinal;

        // Configure start- and enddate so they are in proper form for database
        let datesChanged = false
        if (calendar.startdate) {
          let startDateString = this.datePipe.transform(this.startDate, 'yyyy-MM-dd')
          calendar.startdate = this.datePipe.transform(new Date(calendar.startdate), 'yyyy-MM-dd')

          if (calendar.startdate != startDateString) datesChanged = true
        }
        if (calendar.enddate) {
          let endDateString = this.datePipe.transform(this.endDate, 'yyyy-MM-dd')
          calendar.enddate = this.datePipe.transform(new Date(calendar.enddate), 'yyyy-MM-dd')
          if (calendar.enddate != endDateString) datesChanged = true
        }


        let boolean = true;
        let calendarId;

        // If mode is update meaning we are in edit page
        if(this.mode == 'update') {
          if (this.msaChanged) {
            Swal.fire({
              title: 'Msa changed',
              text: "Changing msa removes workorders from calendar, are you sure?",
              icon: 'warning',
              showCancelButton: true
            }).then((result) => {
              if (result.isConfirmed) {
                this.finishSaveCalendar(comparison, calendar, daysToSave, datesChanged)
              } else if (result.isDismissed) {
                this.saveSpinner = false
                return
              }
            });
            
          } else this.finishSaveCalendar(comparison, calendar, daysToSave, datesChanged)
        } else if (this.mode == 'create') {
          this.calendarsService.createCalendar(calendar)
            .pipe(
              finalize(() => {
                if (boolean && this.id != 'null') {
                  // Link workerteam to calendar api call
                  this.calendarsService.linkToWorkerteam(this.id, calendarId)
                    .pipe(takeUntil(this.componentDestroyed$))
                    .subscribe(
                      data => {
                        // If api call was successful move to map
                        if (data != false) this.toMap(calendarId)
                      }
                    )
                }
                if (boolean && this.id == 'null') {
                  this.toMap(calendarId)
                }
              }),
              takeUntil(this.componentDestroyed$)
            )
            .subscribe(
              data => {
                // If api call returns fail if check
                if (data == false) {
                  boolean = false;
                }
                // If data returns successful
                else {
                  // If calendar had slots run this
                  if (data.length > 1) {
                    calendarId = data.split(":")[0]
                  }
                  // If calendar does not have slots run this
                  else {
                    calendarId = data
                  }
                }
              }
            )
        }
      }
    }
  }

  finishSaveCalendar(comparison, calendar, daysToSave, datesChanged) {
    if (comparison) {
      if (datesChanged || this.cleanupCalendarChanged || this.msaChanged || this.originalCalendarSlotFill != this.calendarSlotFill || this.originalCalendarName != this.calendarName) {
        Swal.fire({
          title: 'Do you want to update calendar info?',
          text: "Updating msa info resets calendar's workorders.",
          showCancelButton: true,
          cancelButtonText: 'No',
          showConfirmButton: true,
          confirmButtonText: 'Yes'
        }).then(result => {
          if (result.isConfirmed) {
            this.updateCalendarDate(calendar, datesChanged, this.msaChanged, this.cleanupCalendarChanged, this.originalCalendarSlotFill != this.calendarSlotFill, this.originalCalendarName != this.calendarName)
            this.moveToMap = true
            this.updateCalendar(daysToSave)
          } else this.saveSpinner = false
        })
      } else {
        this.moveToMap = true
        this.updateCalendar(daysToSave)
      }
    } else {
      if (datesChanged || this.cleanupCalendarChanged || this.msaChanged || this.originalCalendarSlotFill != this.calendarSlotFill || this.originalCalendarName != this.calendarName) {
        Swal.fire({
          title: 'Do you want to update calendar info?',
          text: "Updating msa info resets calendar's workorders.",
          showCancelButton: true,
          cancelButtonText: 'No',
          showConfirmButton: true,
          confirmButtonText: 'Yes'
        }).then(result => {
          if (result.isConfirmed) {
            this.updateCalendarDate(calendar, datesChanged, this.msaChanged, this.cleanupCalendarChanged, this.originalCalendarSlotFill != this.calendarSlotFill, this.originalCalendarName != this.calendarName)
            this.moveToMap = true
          } else this.saveSpinner = false
        })
      } else {
        this.toastService.sendToast(false, this.translateService.instant('basic.nothingToSave'))
        this.saveSpinner = false;
      }
    }
  }

  toMap(id) {
    let url = "planner/weekly-planning-map/" + id;
    this.router.navigate([url])
  }

  /**
   * Open/close generate slots with this function
   * @param command message to transmit
   */
  generateSlotsToggle(command) {
    if (command == 'open') {
      this.toggleSlots = true;
      let days = this.getCorrectDays()
      let slotsExist = false
      for (let i = 0; i < days.array.length; i++) {
          if (days.array[i].slot.length > 0) {
            slotsExist = true
            break;
        }
      }
      if (this.mode == 'update' && slotsExist) {
        Swal.fire({
          title: this.translateService.instant('weeklyPlanningDetails.deleteWeekConfirmation'),
          showConfirmButton: true,
          showCancelButton: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.resetCalendar('reset')
            this.removeSlots(null)
          } else {
            this.generateSlotsToggle('close')
          }
        })
      }
    }
    if (command == 'close') this.toggleSlots = false;
  }

  /**
   * Make a new slot for generate slots window
   */
  addNewSlot() {
    // Copy slotDays and not use = because it makes a reference not a copy
    let newDays = JSON.parse(JSON.stringify(this.slotDays))
    // If slots is more than 0
    if (this.slots.length > 0) {
      // Check what slots are checked prior of adding new slot
      for (let i = 0; i < this.slots[0].days.length; i++) {
        // Check phase
        if (this.slots[0].days[i].checked == true) {
          // Add these values if check is true
          newDays[i].checked = true
          newDays[i].disabled = true;
        }
      }
    }

    // Add new slot wih correct information in newDays
    this.slots.push({
      name: this.translateService.instant('weeklyPlanningDetails.slotType') + " " + (this.slots.length + 1),
      days: newDays,
      selectedSlots: 1,
      slotDuration: 1,
      firstSlotStartTime: '07:00'
    })
  }

  /**
   * Delete slot and remove checked marks from slot
   * @param slotId what slot was clicked
   * @param slot slot to pass to clickedCheckbox function
   */
  deleteSlot(slotId, slot) {
    // Loop through slot days to check what slots were checked and then perform clickedCheckbox function
    // for those slots. It removes check mark from all slots
    for (let i = 0; i < slot.days.length; i++) {
      if (slot.days[i].checked == true && slot.days[i].disabled == false) this.clickedCheckbox(i, slot)
    }

    // Remove slot from slots list
    this.slots.splice(slotId, 1)

    // Change names of all slots according to deleted slot
    for (let i = 1; i <= this.slots.length; i++) {
      if (this.slots[i - 1].name != this.translateService.instant('weeklyPlanningDetails.slotType') + " " + i) this.slots[i - 1].name = this.translateService.instant('weeklyPlanningDetails.slotType') + " " + i
    }

  }

  /**
   * Main function of this page. Sets days of week correctly. Also gives firstDayOfWeek, lastDayOfWeek and
   * weekNumber variables correct information
   * @param d new date
   * @param added number that needs to be added to date when moving in weeks
   */
  async getDaysOfWeek(d, added) {
    // Set the date correctly
    let date = new Date(d);
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    date.setDate(date.getDate() + added)
    let day = date.getDay();

    // Calculate difference
    let diff = date.getDate() - day + (day === 0 ? -6 : 1);

    // Set firstDay and lastDay
    // Get month needs to have added + 1 because it's a zero index count which is wrong
    let firstDay =  new Date(date.setDate(diff));
    let lastDay = new Date(firstDay)
    lastDay.setDate(firstDay.getDate() + 6)
    this.firstDayOfWeek = firstDay
    this.lastDayOfWeek = lastDay

    // Set week number
    this.weekNumber = this.datePipe.transform(firstDay, 'w')

    // Reset tempDays variable
    this.tempDays = Array()
    // For loop for everyday of week
    for (let i = 0; i < 7; i++) {
      // Make a new date with firstDay
      let newDate = new Date(firstDay)
      // Add index to day so that it is correct day
      newDate.setDate(firstDay.getDate() + i)
      const name = this.translateService.instant('basic.weekDays.' + newDate.toString().split(' ')[0])

      // Push day to tempDays array with correct information
      this.tempDays.push({name, date: newDate, slot: Array(), showAddSlot: false})
    }

    for (let j = 0; j < this.tempDays.length; j++) {
      const element = this.tempDays[j];
      if (element.name === this.translateService.instant('basic.weekDays.Mon')) {
        let correctDate = element.date
        correctDate.setHours(0,0)
        // If monday
        this.days.push({
          array: JSON.parse(JSON.stringify(this.tempDays)),
          startDateOriginal: correctDate,
          boolean: true,
          firstDayOriginal: firstDay,
          lastDayOriginal: lastDay,
          showAddSlotMain: false
        })
      }

    }



  }

  /**
   * Function that is used to set new information for next or previous week in calendar
   * @param message message telling is user clicked backwards or forwards in interface
   */
  moveWeek(message) {
    // Initialize date
    let firstDay = new Date(this.firstDayOfWeek)
    let newDate = new Date(this.firstDayOfWeek)

    // User clicked backwards in interface
    if (message == 'back') {
      // Minus seven days for date
      newDate.setDate(newDate.getDate() - 7)
      // Check if week has been alreydy intialized and use that if it does, otherwise perform getDaysOfWeek
      // initialization
      if (!this.checkIfExists(newDate)) this.getDaysOfWeek(firstDay, -7)
    }
    // User clicked forward in interface
    else if (message == 'forward') {
      // Add seven days to date
      newDate.setDate(newDate.getDate() + 7)
      // Check if week exists and perform getDaysOfWeek if it doesn't
      if (!this.checkIfExists(newDate)) this.getDaysOfWeek(firstDay, 7)
    }

    // Set week number
    this.weekNumber = this.datePipe.transform(newDate, 'w')

    // Set slots to basic state
    this.slots = [
      {
        name: this.translateService.instant('weeklyPlanningDetails.slotType') + " 1",
        days: JSON.parse(JSON.stringify(this.slotDays)),
        selectedSlots: 1,
        slotDuration: 1,
        firstSlotStartTime: '07:00'
      }
    ]

    //let splittedFirstDay = this.firstDayOfWeek.split(".")
    let dateMatches = false;
    for (let j = 0; j < this.templatesArray.length; j++) {
      if (this.templatesArray[j].startDateOriginal.getTime() == newDate.getTime()) {
        if (this.templatesArray[j].array[0].date == this.firstDayOfWeek) {
          dateMatches = true
        }
      }
    }
    if (dateMatches) this.copiedWeek = true
    else this.copiedWeek = false

    // Check if days have any slots in them meaning they have days that have slots. If does not have do
    // not toggle copySlots on. If they have slots turn copySlots on
    let day = this.getCorrectDays()
    let isSlotLengthMoreThanZero = false;
    for (let l = 0; l < day.array.length; l++) {
      if (day.array[l].slot.length > 0) isSlotLengthMoreThanZero = true
    }
    if (isSlotLengthMoreThanZero) this.toggleCopySlots = true;
    else this.toggleCopySlots = false;

    // Close add slot that is open
    for (let o = 0; o < this.days.length; o++) {
      if (this.days[o].showAddSlotMain == true) {
        for (let index = 0; index < this.days[o].array.length; index++) {
          if (this.days[o].array[index].showAddSlot == true) {
            this.days[o].array[index].showAddSlot = false
            this.days[o].showAddSlotMain = false;
            this.widthSlot[index].width = '14.2%'
          }
        }
      }
    }
  }

  /**
   * When user has done generate slots selections and is ready to generate calendar, perform this
   * function. User needs to click apply in interface
   */
  applyToCalendar() {
    // Reset the calendar so that we can apply new information to it
    this.resetCalendar(null)
    let correctDays = this.getCorrectDays()
    // Loop through days array
    for (let y = 0; y < correctDays.array.length; y++) {
      // Loop through slots
      for (let x = 0; x < this.slots.length; x++) {
        // Loop through slots days
        for (let h = 0; h < this.slots[x].days.length; h++) {
          // Check if days arrays index has same name as slots days index and checked is also same
          const a = correctDays.array[y].name;
          const b = this.slots[x].days[h].name;
          const c = this.slots[x].days[h].checked;
          const d = this.slots[x].days[h].disabled != true;
          if (correctDays.array[y].name == this.slots[x].days[h].name && this.slots[x].days[h].checked == true && this.slots[x].days[h].disabled != true) {
            // Set starting time and ending time for slot one
            this.slots[x].slotDuration = parseInt(this.slots[x].slotDuration.toFixed())
            let timeS = this.slots[x].firstSlotStartTime
            let calculatedTime = parseInt(this.slots[x].firstSlotStartTime.split(":")[0]) + this.slots[x].slotDuration
            let timeE;
            if (calculatedTime < 10) {
              timeE = "0" + calculatedTime + ":" + this.slots[x].firstSlotStartTime.split(":")[1]
            } else {
              timeE = calculatedTime + ":" + this.slots[x].firstSlotStartTime.split(":")[1]
            }

            // Set starting and ending times for all slots
            for (let j = 0; j < this.slots[x].selectedSlots; j++) {
              // if starting time or ending time is more than 24, default to 23:59 time
              if (parseInt(timeS.split(":")[0]) > 24) timeS = "23:59"
              if (parseInt(timeE.split(":")[0]) > 24) timeE = "23:59"
              // Push information to slot
              correctDays.array[y].slot.push({disabled: false, timeStart: timeS, timeEnd: timeE, slot_max: null})
              // Calculate time start
              let calculatedTimeStart = parseInt(timeS.split(":")[0]) + this.slots[x].slotDuration
              if (calculatedTimeStart < 10) {
                timeS = "0" + calculatedTimeStart + ":" + this.slots[x].firstSlotStartTime.split(":")[1]
              } else {
                timeS = calculatedTimeStart + ":" + this.slots[x].firstSlotStartTime.split(":")[1]
              }
              // Calculate time end
              let calculatedTimeEnd = parseInt(timeE.split(":")[0]) + this.slots[x].slotDuration
              if (calculatedTimeEnd < 10) {
                timeE = "0" + calculatedTimeEnd + ":" + this.slots[x].firstSlotStartTime.split(":")[1]
              } else {
                timeE = calculatedTimeEnd + ":" + this.slots[x].firstSlotStartTime.split(":")[1]
              }

            }
          }

        }
      }
    }

    // Toggle correct buttons to show
    this.toggleCopySlots = true;
    this.toggleSlots = false;
    this.copyDisabled = false;
    this.copiedWeek = false;


  }

  checkIfExists(day) {
    // Set boolean to false
    let boolean = false;
    // Go through days and check if time is right to get correct week
    for (let i = 0; i < this.days.length; i++) {
      if (this.days[i].startDateOriginal.getTime() == day.getTime()) {
        // Set these value if correct week is found
        boolean = true;
        this.days[i].boolean = true;
        this.firstDayOfWeek = this.days[i].firstDayOriginal
        this.lastDayOfWeek = this.days[i].lastDayOriginal
      }
      else {
        // Set this value if time does not match
        this.days[i].boolean = false;
      }
    }
    // Return true if week was found and false if not
    if (boolean == true) return true
    else return false;

  }

  /**
   * Function that is run when checkbox is clicked in user interface
   * @param index index of clicked checkbox
   * @param slot slot of clicked checkbox
   */
  clickedCheckbox(index, slot) {
    // Primary for loop
    for (let x = 0; x < this.slots.length; x++) {
      // Secondary loop
      // Go through days of slot
      for (let y = 0; y < this.slots[x].days.length; y++) {
        // Needs to be checked if false because it does not register to true when clicked
        if (slot.days[index].checked == false) {
          // Disable all other checkboxes from slots array
          // This is run when user clicks checkbox when it is not checked
          if (index == y && this.slots[x].name != slot.name) {
            this.slots[x].days[y].disabled = true;
            this.slots[x].days[y].checked = true;
          }
        } else {
          // Put disable off when this is run. This is run when user clicks
          // checkbox when it is checked
          if (index == y && this.slots[x].name != slot.name) {
            this.slots[x].days[y].disabled = false;
            this.slots[x].days[y].checked = false;
          }
        }
      }
    }

  }

  /**
   * Disable slot and toggle correct css according to on/off
   * @param slot slot of clicked disable
   */
  disabledClicked(slot) {
    // If slot is not disabled run this
    if (slot.background != 'gray') {
      slot.styling = {'background-color': 'gray'}
      slot.background = 'gray'
      slot.border = 'solid 1px white'
    }
    // If slot is disabled run this
    else {
      slot.styling = {'background-color': 'white'}
      slot.background = 'white'
      slot.border = 'solid 1px #EAEDF2'
    }
  }

  /**
   * Ran when user clicks description icon
   * @param message
   */
  addDescription(message) {

    this.currentSlot.description = this.currentDescription
    this.currentDescription = null;

    // When first modal (create) is clicked
    if (message == 'main') {
      let button = document.getElementById('modalClose')
      button?.click();
    }

    // When second modal (edit) is clicked
    if (message == 'edit') {
      this.editDescription = false
      let button = document.getElementById('modalCloseEdit')
      button?.click();
    }

  }

  /**
   * Save slot to slot's description
   * @param slot the point where description is saved to
   */
  saveSlotToDescription(slot) {
    this.currentSlot = slot;
  }

  /**
   * Open or close slot description
   * @param slot
   * @param message
   */
  toggleSlotDescription(slot, message) {
    // Set currentDescription to slot's description for saving data
    if (message == 'open') {
      this.currentDescription = slot.description
      this.currentSlot = slot
    }
    // Reset values on close
    if (message == 'close') {
      this.currentDescription = null
      this.currentSlot = null
    }
  }

  /**
   * When cancel is clicked
   * @param message
   */
  modalCancel(message) {
    // Only run when edit is in message. Other option is 'main'.
    // Reset values
    if (message == 'edit') {
      this.editDescription = false
      this.currentDescription = null;
    }
  }

  /**
   * Toggle edit description to true
   */
  toggleEditDescription() {
    this.editDescription = true;
  }

  /**
   * Reset calendar to original state
   * @param message 'reset' performs actually resetting
   */
  resetCalendar(message) {
    // Split first day of week to get a new date
    let date = new Date(this.firstDayOfWeek)
    date.setHours(0,0)
    // For loop for days
    let correctDays = this.getCorrectDays()
    if (correctDays.startDateOriginal.getTime() == date.getTime()) {
      // Copy tempDays. Can't copy with normal assignment because it just makes a reference and not
      // refresh for data
      let dates = Array()
      for (let i = 0; i < correctDays.array.length; i++) {
        dates.push(correctDays.array[i].date)
      }
      correctDays.array = JSON.parse(JSON.stringify(this.tempDays))
      for (let i = 0; i < correctDays.array.length; i++) {
        correctDays.array[i].date = dates[i]
      }
    }

    // When actual reset calls function run this
    if (message == 'reset') {
      // Reset values and set slots to original state
      this.toggleCopySlots = false;
      this.copyDisabled = false;
      this.copiedWeek = false;
      this.slots = [
        {
          name: this.translateService.instant('weeklyPlanningDetails.slotType') + " 1",
          days: JSON.parse(JSON.stringify(this.slotDays)),
          selectedSlots: 1,
          slotDuration: 1,
          firstSlotStartTime: '07:00'
        }
      ]
    }
  }

  /**
   * Save copy of this week to copyWeek variable for further usage
   */
  copyThisWeek() {
    let currentDays = this.getCorrectDays()
    // Make a new copy of array
    let tempArray = JSON.parse(JSON.stringify(currentDays.array))

    // Reset slots if checked is false meaning you make a new copy of week according
    // to user selected days
    for (let x = 0; x < this.copy.length; x++) {
      if (this.copy[x].checked == false) tempArray[x].slot = Array()
    }
    let newDate = this.firstDayOfWeek
    if (!this.disabledSlotsCopy) {
      for (let i = 0; i < tempArray.length; i++) {
        const day = tempArray[i];
        day.slot = day.slot.filter(slot => !slot.disabled)
      }
    }
    // Push new array to all templates array
    let templateId = this.templatesArray.length
    this.templatesArray.push({array: tempArray, name: this.templateName, startDateOriginal: newDate, id: templateId})


    this.copiedWeek = true;
    this.toggleTemplates = true;

    // Reset values for copy after generating copy
    for (let h = 0; h < this.copy.length; h++) {
      if (this.copy[h].checked == false) this.copy[h].checked = true
    }
    //this.disabledSlotsCopy = true;
    this.copyWholeWeek = true;
    this.templateName = null;

    // Click button to close modal
    let button = document.getElementById('copyCancel')
    button?.click();
  }

  /**
   * Go through copy variable to check or uncheck all days
   */
  toggleAllDaysCopy() {
    for (let i = 0; i < this.copy.length; i++) {
      // If checked
      if (!this.copyWholeWeek) this.copy[i].checked = true
      // If not checked
      else this.copy[i].checked = false
    }
  }

  /**
   * Apply template to given week with correct information
   * @param id what template to use for adding information
   */
  applyTemplate(id) {
    let currentDays = this.getCorrectDays()
    // Loop through days array's single days (monday, tuesday, so on...)
    for (let j = 0; j < currentDays.array.length; j++) {
      // Make a new copy of slot from template for single day
      currentDays.array[j].slot = JSON.parse(JSON.stringify(this.templatesArray[id].array[j].slot))

    }
    this.toggleCopySlots = true;
  }

  /**
   * Get correct array and then check if showAddSlotMain is false meaning add slot is not opened. Open
   * add slot window if showAddSlotMain is false, otherwise show pop up telling to close window before continuing
   * @param id clicked index of add slot
   */
  showAddSlot(id) {
    // Get correct days array
    let correctDays = this.getCorrectDays()
    // Check if add slot is closed
    if (correctDays.showAddSlotMain == false) {
      correctDays.array[id].showAddSlot = true;
      correctDays.showAddSlotMain = true;
      this.widthSlot[id].width = '14rem'
    }
    // If add slot is open show pop up
    else {
      Swal.fire('Close currently open add slot before opening a new one.')
    }



  }

  /**
   * Save add slot after making changes to duration and start time, adding slot to days slots
   * Then close add slot after adding it and reset values in closeAddSlot function
   * Also toggle correct buttons visible
   * @param id current slot id
   */
  saveAddSlot(id) {
    // Get correct days array
    let correctDays = this.getCorrectDays()
    // Set starting time
    let timeStartSlot: String = this.slotAddSlot[id].start!
    // Set ending time
    let calculatedTime = parseInt(timeStartSlot.split(":")[0]) + this.slotAddSlot[id].duration!
    let timeE;
    // Logic if ending time is less than 10
    if (calculatedTime < 10) {
      timeE = "0" + calculatedTime + ":" + timeStartSlot.split(":")[1]
    } else {
      timeE = calculatedTime + ":" + timeStartSlot.split(":")[1]
    }
    // Logic if ending time is more than 24 hours
    if (calculatedTime >= 24) Swal.fire(this.translateService.instant('weeklyPlanningDetails.endingTooMuch'))
    else {
    // Add slot
    correctDays.array[id].slot.push({disabled: false, timeStart: timeStartSlot, timeEnd: timeE, slot_max: null })
    // Close add slot window and reset values
    this.closeAddSlot(id)
    // Toggle buttons visible
    this.toggleCopySlots = true;
    this.toggleTemplates = true;
    }
  }

  /**
   * Function to get correct days of current week
   * @returns correct days reference
   */
  getCorrectDays() {
    // Loop through days finding correct days with reading boolean value
    for (let i = 0; i < this.days.length; i++) {
      if (this.days[i].boolean == true) {
        return this.days[i]
      }
    }
  }

  /**
   * Close window of add slot adding
   * Update values according and reset width of div
   * @param id current slot id
   */
  closeAddSlot(id) {
    let correctDays = this.getCorrectDays()
    correctDays.array[id].showAddSlot = false;
    correctDays.showAddSlotMain = false;
    this.widthSlot[id].width = '14.2%'
    this.slotAddSlot[id].start = null;
    this.slotAddSlot[id].duration = null;
  }

  /**
   * Remove slot that is in slots
   * @param slot slot that needs to be removed
   */
  deleteSlotFromSlots(slot) {
    let currentDays = this.getCorrectDays()
    for (let i = 0; i < currentDays.array.length; i++) {
      for (let j = 0; j < currentDays.array[i].slot.length; j++) {
        if (currentDays.array[i].slot[j] == slot) currentDays.array[i].slot.splice(j, 1)
      }

    }
  }

  setTeamId(id) {
    this.teamId = id;
  }

  getMsas() {
    this.workorderService.getMsas()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          if (data && data.length > 0) this.msas = this.msas.concat(data)
          for (let i = 0; i < this.msas.length; i++) {
            if (this.msas[i].id == this.msaId && this.msaId !== undefined) this.msa = this.msas[i]
          }
          if (this.msaId != null && this.msa == null) this.msa = {name: this.translateService.instant('weeklyPlanningDetails.msaRemoved'), id: 0}
          this.msaSpinner = false
        }
      )
  }

  selectMsa(id) {
    for (let i = 0; i < this.msas.length; i++) {
      if (id == this.msas[i].id) {
        if (this.msaId === undefined) this.msaChanged = true
        else if (this.msaId !== id) this.msaChanged = true
        else this.msaChanged = false
        this.msa = this.msas[i]
        if (this.expand == "assets/icons/expand_less_black_24dp.svg") this.toggleExpand()
      }
    }
  }

  toggleExpand() {
    if (this.expand == "assets/icons/expand_more_black_24dp.svg") this.expand = "assets/icons/expand_less_black_24dp.svg"
    else this.expand = "assets/icons/expand_more_black_24dp.svg"
  }

  toggleExpand2() {
    if (this.expand2 == "assets/icons/expand_more_black_24dp.svg") this.expand2 = "assets/icons/expand_less_black_24dp.svg"
    else this.expand2 = "assets/icons/expand_more_black_24dp.svg"
  }

  getCalendar(id, getCleanupCalendar) {
    this.calendarsService.getCalendarById(id)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.msaId = data.msa_id
          if (getCleanupCalendar) this.getCleanupCalendars(data.cleanup_id)
          this.getMsas()
          if (Object.keys(data.slots).length != 0) {
            this.toggleCopySlots = true;
            this.toggleSlots = false;
            this.copyDisabled = false;
            this.copiedWeek = false;
          }
          this.calendarName = this.originalCalendarName = data.name
          this.calendarSlotFill = data.slot_max_default
          this.originalCalendarSlotFill = data.slot_max_default
          this.startDate = new Date(data.startdate)
          this.calendarStartCleanup = data.startdate
          this.endDate = new Date(data.enddate)
          this.calendarEndCleanup = data.enddate
          this.firstDayOfWeek = new Date(data.enddate)
          this.lastDayOfWeek = new Date(data.enddate)
          let differenceInMilliseconds = Math.abs(this.lastDayOfWeek - this.startDate)
          let differenceInDays = Math.ceil( differenceInMilliseconds / (1000 * 60 * 60 * 24))
          differenceInDays = differenceInDays - 6
          let differenceInWeeks = differenceInDays / 7

          this.firstDayOfWeek.setDate(this.lastDayOfWeek.getDate() - 6)
          let locale = 'en-GB'
          if (this.translateService.currentLang === 'de') locale = 'de-DE'
          this.lastDayOfWeek = this.lastDayOfWeek.toLocaleDateString(locale)
          this.firstDayOfWeek = this.firstDayOfWeek.toLocaleDateString(locale)
          this.getDaysOfWeek(new Date(data.enddate), -6)
          for (let i = 0; i < differenceInWeeks; i++) {
            this.moveWeek('back')
          }
          for (let i = 0; i < this.days.length; i++) {
            for (let j = 0; j < this.days[i].array.length; j++) {
              let date = new Date(this.days[i].firstDayOriginal)
              date.setDate(date.getDate() + j)
              date.setHours(0)
              for (let key in data.slots) {
                let slotDate = new Date(data.slots[key].starttime)
                slotDate.setHours(0)
                slotDate.setMinutes(0)
                if (date.getTime() == slotDate.getTime()) {
                  let disabledValue;
                  let styling;
                  let background;
                  let border;
                  let description = null;
                  if (data.slots[key].disabled == 1) {
                    disabledValue = true
                    styling = {'background-color': 'gray'}
                    background = 'gray'
                    border = 'solid 1px white'
                    description = data.slots[key].disabled_reason
                  }  else {
                    disabledValue = false;
                    styling = {'background-color': 'white'}
                    background = 'white'
                    border = 'solid 1px #EAEDF2'
                  }
                  this.days[i].array[j].slot.push({
                    id: key,
                    timeStart: this.datePipe.transform(new Date(data.slots[key].starttime), ('HH:mm')),
                    timeEnd: this.datePipe.transform(new Date(data.slots[key].endtime), ('HH:mm')),
                    disabled: disabledValue,
                    styling: styling,
                    background: background,
                    border: border,
                    description: description,
                    slot_max: data.slots[key].slot_max
                  })

                }
              }
              this.days[i].array[j].slot.sort(this.compareSlots)
            }


          }
          this.originalSlots = JSON.parse(JSON.stringify(this.days))
        }
      )
  }

  /**
  * 2.11.2022
  * Compare function for slots.
  * Compares starttime and sorts according to them calendar slots.
  * Inside try block, because if we can't split starttime for some reason.
  * Error in try block return 0, so it won't affect sort.
  * @param a first element
  * @param b second element
  * @returns sort order
  * @author Jesse Lindholm
  */
   compareSlots(a, b) {
    try {
      let aStartTimeMinutes: number = a.timeStart.split(":")[0] * 60 + a.timeStart.split(":")[1]
      let bStartTimeMinutes: number = b.timeStart.split(":")[0] * 60 + b.timeStart.split(":")[1]
      if (aStartTimeMinutes < bStartTimeMinutes) return -1
      else if (aStartTimeMinutes > bStartTimeMinutes) return 1
      else return 0
    } catch(err) {
      return 0
    }
  }


  /**
   * Compare two arrays to another checking slots from each one and returning true if there is differences
   * @param object1 daysToSave array which is used in saving slots
   * @returns
   */
  compareObjects(object1) {
    // Go through daysTosave objects
    for (let i = 0; i < object1.length; i++) {
      for (let j = 0; j < object1[i].array.length; j++) {
          for (let x = 0; x < object1[i].array[j].slot.length; x++) {
            let object = object1[i].array[j].slot[x]
            let noTimeFound = true
            for (let t = 0; t < this.originalSlots.length; t++) {
              if (new Date(this.originalSlots[t].startDateOriginal).getTime() == object1[i].startDateOriginal.getTime()) {
                noTimeFound = false
                if (this.originalSlots[t].array[j].slot.some(slot => slot.id === object.id
                  && ((slot.timeStart != object.timeStart)
                  || (slot.timeEnd != object.timeEnd)
                  || (slot.disabled != object.disabled)
                  || (slot.slot_max != object.slot_max)
                  || (slot.description != object.description)))) return  true
                else if (!this.originalSlots[t].array[j].slot.some(slot => slot.id === object.id)) return  true
              }
              if (t == this.originalSlots.length - 1 && noTimeFound) return true
            }
          }
      }
    }
    // Go through originalSlots
    for (let i = 0; i < this.originalSlots.length; i++) {
      for (let j = 0; j < this.originalSlots[i].array.length; j++) {
          for (let x = 0; x < this.originalSlots[i].array[j].slot.length; x++) {
            let object = this.originalSlots[i].array[j].slot[x]
            let noTimeFound = true
            for (let t = 0; t < object1.length; t++) {
              if (object1[t].startDateOriginal.getTime() == new Date(this.originalSlots[i].startDateOriginal).getTime()) {
                noTimeFound = false
                if (object1[t].array[j].slot.some(slot => slot.id === object.id
                  && ((slot.timeStart != object.timeStart)
                  || (slot.timeEnd != object.timeEnd)
                  || (slot.disabled != object.disabled)
                  || (slot.slot_max != object.slot_max)
                  || (slot.description != object.description)))) return  true
                else if (!object1[t].array[j].slot.some(slot => slot.id === object.id)) return  true
              }
              if (t == object1.length - 1 && noTimeFound) return true
            }
          }
      }
    }
    return false
  }

  updateCalendar(daysToSave) {
    let observables: Observable<any>[] = [];
    for (let i = 0; i < daysToSave.length; i++) {
      for (let j = 0; j < daysToSave[i].array.length; j++) {
        for (let l = 0; l < daysToSave[i].array[j].slot.length; l++) {
          let object = daysToSave[i].array[j].slot[l]
          object.slotId = l
          object.arrayId = j
          object.date =  this.datePipe.transform(new Date(daysToSave[i].array[j].date), 'yyyy-MM-dd')

          let update = false;
          let create = false;
          let doesOriginalSlotsHave = false;


          for (let t = 0; t < this.originalSlots.length; t++) {
            if (new Date(this.originalSlots[t].startDateOriginal).getTime() == daysToSave[i].startDateOriginal.getTime()) {
              if (this.originalSlots[t].array[j].slot.some(slot => slot.id === object.id
                && ((slot.timeStart != object.timeStart)
                || (slot.timeEnd != object.timeEnd)
                || (slot.disabled != object.disabled)
                || (slot.slot_max != object.slot_max)
                || (slot.description != object.description)))) update = true
              else if (!this.originalSlots[t].array[j].slot.some(slot => slot.id === object.id)) create = true
              doesOriginalSlotsHave = true;
              break;
            }
          }
          if (doesOriginalSlotsHave == false) create = true;
          if (update) {
            observables.push(this.calendarsService.updateCalendarSlotInformation(object))
          }
          else if (create) {
            observables.push(this.calendarsService.createSlot(object, this.calendarId))
          }
        }



      }
    }
    forkJoin(observables)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      () => {
        this.removeSlots('reset')
      }
    );
    if (observables.length == 0) this.removeSlots('reset')
  }

  /**
   * 2.11.2022
   * Compare days and originalSlots arrays to know, which slots have been removed by the user.
   * Use forkJoin to make multiple api-calls at the same time.
   * TODO: Make a new backend api call that takes IDs to remove slots from calendar.
   * @param message parameter to define are we saving calendar or just resetting via "Reset week" button in UI.
   * @author Jesse Lindholm
   */
  removeSlots(message) {
    let observables: Observable<any>[] = [];
    this.originalSlots.forEach(originalWeek => {
      for (let i = 0; i < this.days.length; i++) {
        const week = this.days[i]
        if (week.firstDayOriginal.getTime() === new Date(originalWeek.firstDayOriginal).getTime()) {
          for (let j = 0; j < originalWeek.array.length; j++) {
            const day = originalWeek.array[j]
            for (let x = 0; x < day.slot.length; x++) {
              const slot = day.slot[x]
              let found = week.array[j].slot.find(newSlot => newSlot.id === slot.id)
              if (found === undefined) observables.push(this.calendarsService.removeSlot(slot.id))
            }
          }
        }
      }
    })
    forkJoin(observables)
    .subscribe(
      () => {
        if (message == 'reset') this.finishUpdateCalendar()
      }
    )
    if (observables.length == 0) this.toMap(this.calendarId)
    this.originalSlots = JSON.parse(JSON.stringify(this.days))
  }

  finishUpdateCalendar() {
    this.originalSlots = Array()
    this.days = Array()
    //this.toMap(this.calendarId)
    this.getCalendar(this.calendarId, false)
    if (this.moveToMap) this.toMap(this.calendarId)
  }

  updateCalendarDate(calendar, datesChanged: boolean, msaChanged: boolean, cleanupCalendarChanged: boolean, fillChanged: boolean, nameChanged: boolean) {
    this.calendarsService.updateCalendar(calendar, this.calendarId, datesChanged, msaChanged, cleanupCalendarChanged, fillChanged, nameChanged)
    this.msaChanged = false;
    if (!this.cleanupCalendar) this.cleanupCalendarChanged = false;
    if (this.moveToMap) this.toMap(this.calendarId)
  }

  getCleanupCalendars(cleanupId) {
    this.calendarsService.getCalendars()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          if (cleanupId == null) this.selectedCleanupCalendar = {name: this.translateService.instant('weeklyPlanningDetails.noSelectedCleanup'), id: 0}
          for (let i = 0; i < data.length; i++) {
            if (data[i].type == 2) this.cleanupCalendars.push(data[i])
            if (cleanupId != null && cleanupId == data[i].id) this.selectedCleanupCalendar = data[i]
          }
          this.cleanupCalendarSpinner = false;
        }
      )
  }

  selectCleanupCalendar(id) {
    this.cleanupCalendarChanged = true;
    for (let i = 0; i < this.cleanupCalendars.length; i++) {
      if (id == this.cleanupCalendars[i].id) this.selectedCleanupCalendar = this.cleanupCalendars[i]
    }
    if (this.expand2 == "assets/icons/expand_less_black_24dp.svg") this.toggleExpand()
  }

  getCleanupCreate() {
    this.calendarsService.getCalendars()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.selectedCleanupCalendar = {name: this.translateService.instant('weeklyPlanningDetails.noSelectedCleanup'), id: 0}
          for (let i = 0; i < data.length; i++) {
            if (data[i].type == 2) this.cleanupCalendars.push(data[i])
          }
          this.cleanupCalendarSpinner = false;
        }
      )
  }

}
