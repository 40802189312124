<nav id="sidebar" class="active">
    <div class="sidebar-wrapper active sidenavstyle">
      <div class="sidebar-menu">
        <ul class="menu">
          <!-- <li class="sidebar-title">Menu</li> -->
          <li routerLinkActive="active" class="sidebar-item has-sub">
            <a id="nav-map" routerLink="map"  class='sidebar-link'>
                <img class="nav-icon" src="assets/icons/location_on_black_24dp.svg">
                <span>{{'sideNavigation.titleMap' | translate }}</span>
            </a>
            <ul class="submenu" id="mapSubmenu">
              <li class="submenu-item" routerLinkActive="active">
                <a routerLink="msa-map">
                  <span id="msa-title">{{'sideNavigation.titleMsa' | translate }}</span>
                </a>
              </li>
              <li class="submenu-item" routerLinkActive="active">
                <a routerLink="workorders-without-coordinates">
                  <span id="workorders-without-coordinates">{{'planner.workordersWithoutCoordinates.title' | translate}}</span>
                </a>
              </li>
            </ul>
          </li>
          <!-- <li class="sidebar-title">Menu</li> -->
          <li routerLinkActive="active" class="sidebar-item has-sub">
              <a id="nav-dashboard" routerLink="dashboard" class='sidebar-link' >
                  <img class="nav-icon" src="assets/icons/list_alt_black_24dp.svg">
                  <span>{{'sideNavigation.titleDashboard' | translate }}</span>
              </a>
              <ul class="submenu" id="dashSubmenu">
                <li class="submenu-item" id="reports" routerLinkActive="active">
                  <a routerLink="reports">
                    <span>{{'basic.reports' | translate }}</span>
                  </a>
                </li>
                <li class="submenu-item" id="contractor-settings" routerLinkActive="active">
                  <a routerLink="contractor">
                    <span>{{'basic.settings' | translate }}</span>
                  </a>
                </li>
                <li class="submenu-item" id="weekly-planning" routerLinkActive="active">
                  <a routerLink="weekly-planning">
                    <span>{{'sideNavigation.titleWeeklyPlanning' | translate }}</span>
                  </a>
                </li>
                <li class="submenu-item" id="new-contractor" routerLinkActive="active">
                  <a routerLink="new-contractor">
                    <span>{{'sideNavigation.titleNewContractor' | translate }}</span>
                  </a>
                </li>
                <li class="submenu-item" id="import-data" routerLinkActive="active">
                  <a routerLink="import-data">
                    <span>{{'sideNavigation.titleImportData' | translate }}</span>
                  </a>
                </li>
                <li class="submenu-item" id="end-user-communication" routerLinkActive="active">
                  <a routerLink="end-user-communication">
                    <span>{{'sideNavigation.titleEndUser' | translate }}</span>
                  </a>
                </li>
              </ul>
          </li>
          <!-- <li class="sidebar-title">Menu</li> -->
          <li routerLinkActive="active" class="sidebar-item has-sub">
            <a id="user-management" routerLink="user-management"  class='sidebar-link'>
              <img class="nav-icon" src="assets/icons/groups_black_24dp.svg">
              <span>{{'sideNavigation.titleUserManagement' | translate }}</span>
            </a>
            <ul class="submenu" id="user-managementSubmenu">
              <li class="submenu-item" routerLinkActive="active">
                <a routerLink="planner-management">
                  <span id="planners-title">{{'sideNavigation.titlePlanners' | translate }}</span>
                </a>
              </li>
              <li class="submenu-item" routerLinkActive="active">
                <a routerLink="callservice-management">
                  <span id="callService-title">{{'sideNavigation.titleCallService' | translate }}</span>
                </a>
              </li>
              <!--
              <li class="submenu-item" routerLinkActive="active">
                <a routerLink="admin-management">
                  <span id="adminUsers-title">{{'sideNavigation.titleAdminUsers' | translate }}</span>
                </a>
              </li>
            -->
            </ul>
          </li>
          <!-- <li class="sidebar-title">Menu</li> -->
          <li routerLinkActive="active" class="sidebar-item has-sub">
            <a id="nav-human-resources" routerLink="human-resources" class='sidebar-link'>
                <img class="nav-icon" src="assets/icons/groups_black_24dp.svg">
                <span>{{'sideNavigation.titleHumanResources' | translate }}</span>
            </a>
            <ul class="submenu" id="humanResourcesSubmenu">
              <li class="submenu-item" routerLinkActive="active">
                <a routerLink="workers">
                  <span>{{'sideNavigation.titleWorkers' | translate }}</span>
                </a>
              </li>
              <li class="submenu-item" routerLinkActive="active">
                <a routerLink="teams">
                  <span>{{'basic.teams' | translate }}</span>
                </a>
              </li>
            </ul>
          </li>
          <!-- <li class="sidebar-title">Menu</li> -->
        </ul>
      </div>
      <button class="sidebar-toggler btn x"><i data-feather="x"></i></button>
  </div>
</nav>
