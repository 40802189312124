import {Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core'
import {finalize, Subject, takeUntil} from "rxjs";
import { UserService } from '@shared/services/user/user.service';
import {Language} from "@shared/models/languages";
import languages from "../../../../../assets/i18n/language-list.json";
import {ToastService} from "@shared/services/toast/toast.service";
import Swal from "sweetalert2";

@Component({
    selector: 'app-callService-management',
    templateUrl: './callService-management.component.html',
    styleUrls: ['./callService-management.component.scss']
})

export class CallServiceManagementComponent implements OnInit {
    componentDestroyed$: Subject<boolean> = new Subject()
    interfaceLanguages: Array<Language> = []
    currentContractorId: number = 0;
    contractorId: number = 0;
    contractors = Array();
    callServices = Array();
    all_selected_callServices = Array();

    callServiceLanguage;
    firstName;
    lastName;
    password;
    password2;
    username;
    email;
    selectedContractors = Array();
    unselectedContractors = Array();
    @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef> | undefined;

    searchInput = "";
    loading = true
    confirmationForResettingValues = true;
    realClose = true;

    constructor(
        private router: Router,
        private userService: UserService,
        private translateService: TranslateService,
        private toastService: ToastService,
    ) { }


    ngOnInit(): void {
        this.getCallServices(1);
        this.getContractors();

        this.translateService.get('basic.weekDays.monday').subscribe(
            () => {
                languages.languages.forEach(language => {
                    if (this.translateService.instant(`languages.${language}`) !== `languages.${language}`) this.interfaceLanguages.push({databaseLanguage: language, displayName: this.translateService.instant(`languages.${language}`)})
                    else this.interfaceLanguages.push({databaseLanguage: language, displayName: `No translation (${language})`})
                })
            }
        )
        this.userService.getUserInfo().subscribe((data) => {
            this.currentContractorId = data.current_contractor;
        });
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true)
        this.componentDestroyed$.complete()
    }

    /**
     * 12.12.2022
     * Navigate to workers or teams page
     * @param destination name of landing page
     * @author Jesse Lindholm
     */
    navigate(destination: string) {
        // todo ??
        // todo sama planner-managementissa
        if (destination === 'workers' || destination === 'teams') this.router.navigate([`/callService/${destination}`])
    }

    getContractors() {
        // this.contractors = [{id:0,name:'Loading'}]
        this.userService.getAllContractors()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                data => {
                    this.contractors = data
                    this.unselectedContractors = data
                }
            )
    }

    goToCallServiceDetail(event,id) {
        event.stopPropagation();
        let uri = "planner/callService-detail/" + id;
        this.router.navigate([uri])
        return false;
    }

    addNewCallService() {
        // Disable save button briefly to prevent adding worker more than once
        let saveButton = <HTMLButtonElement>document.getElementById('modalSaveButton')
        saveButton.disabled = true

        if(this.password.length < 3 || this.password != this.password2) {
            this.toastService.sendToast(false, this.translateService.instant('basic.passwordsDontMatch'))
            return false
        }

        let response
        this.username = this.username.trim()
        this.userService.checkUsername(this.username)
            .pipe(
                finalize(() => {
                    if (response != "username_in_use") {
                        // Close modal when this is run by clicking modal close
                        this.confirmationForResettingValues = true;
                        let button = document.getElementById('modalClose')
                        button?.click();

                        if (this.callServiceLanguage === "") this.callServiceLanguage = 'de'
                        this.userService.createUser(
                            this.firstName,
                            this.lastName,
                            this.email,
                            this.username,
                            this.password,
                            this.callServiceLanguage,
                            this.selectedContractors,
                            3
                        )
                            .pipe(takeUntil(this.componentDestroyed$))
                            .subscribe(data => {
                                if(data.status == 200) {
                                    this.callServices = Array();
                                    this.getCallServices(1);
                                    saveButton.disabled = false
                                } else {
                                    saveButton.disabled = false
                                    // todo älä sulje modaalia jos tulee virhe
                                }
                            });
                    } else {
                        Swal.fire(this.translateService.instant('services.usernameInUse'))
                        saveButton.disabled = false
                    }
                })
            )
            .subscribe(
                data => response = data
            )

        return true
    }


    /**
     * Get workers from database to make a base for page
     * Go through values of data to put correct data to correct variables
     * Finally make a single new worker for every data value in database
     */
    getCallServices(allCallServices = 0) {
        this.callServices = []
        this.userService.getUsers('phoneservice', allCallServices)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                data => {
                    for (const id in data) {
                        let callService = {name: "", tags: Array(), contractors: Array(), id: 0, disabled:0};
                        callService.name = data[id].firstname + " " + data[id].lastname
                        callService.contractors = this.setContractors(data[id].contractors) // todo tarvitaanko tätä funktiota?
                        callService.id = parseInt(id)
                        callService.disabled = data[id].disabled
                        if (data[id].tags) callService.tags = JSON.parse(data[id].tags);
                        this.callServices.push(callService)
                    }
                    for (let i = 0; i < data.length; i++) {
                    }
                    this.loading = false
                }
            )
    }

    /**
     * Go through contractors and get the values and save the values to current worker
     * @param contractorsJSON contractors from database
     */
    setContractors(contractorsJSON) {
        let contractors = Array()
        for (let i = 0; i < Object.keys(contractorsJSON).length; i++) {
            if(Object.values(contractorsJSON)[i]) {
                contractors.push(Object.values(contractorsJSON)[i])
            }
        }
        return contractors;
    }

    /**
     * Change workers language to parameters value
     * @param language chosen language to new worker
     */
    languageChange(language: string) {
        this.callServiceLanguage = language
    }

    selectContractor(id) {
        if(id) {
            for(let i = 0; i < this.unselectedContractors.length; i++) {
                let contractor = this.unselectedContractors[i];
                if (contractor.id == id) {
                    this.selectedContractors.push(contractor);
                    this.unselectedContractors.splice(i, 1);
                    break
                }
            }
        }
    }

    unselectContractor(id) {
        if(id) {
            for(let i = 0; i < this.selectedContractors.length; i++) {
                let contractor = this.selectedContractors[i];
                if (contractor.id == id) {
                    this.unselectedContractors.push(contractor);
                    this.selectedContractors.splice(i, 1);
                    break
                }
            }
            this.unselectedContractors.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        }
    }
    onSelected(value:string): void {
        this.contractorId = parseInt(value);
    }
    addContractor() {
        if(this.contractorId > 0) {
            if(this.all_selected_callServices.length > 0) {
                for(let i = 0; i < this.all_selected_callServices.length; i++) {
                    let callService = this.all_selected_callServices[i]
                    this.userService.setContractorToUser(
                        callService,
                        this.contractorId
                    )
                        .pipe(takeUntil(this.componentDestroyed$))
                        .subscribe({
                                next: response =>  {
                                    this.getCallServices(1)
                                    // console.log('planner')
                                    // console.log(planner)
                                    // console.log('added to contractor id '+this.contractorId)
                                },
                                error: error => {
                                    // todo case 400: err_link_exists

                                    // if (error['message'].includes('404 Not Found')) {
                                    //     if (!Swal.isVisible())
                                    //         Swal.fire(
                                    //             this.translateService.instant('planner.endUserCommunications.errorMessages.404')
                                    //         );
                                    // } else if (error['message'].includes('400 Bad Request')) {
                                    //     if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.400_1'));
                                    // } else {
                                    //     if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.unknown'));
                                    // }
                                }
                            }
                        )
                }

                // todo odota että edellä tehty on valmis

                // empty checkboxes
                // @ts-ignore
                this.checkboxes.forEach((element) => {
                    element.nativeElement.checked = false;
                });

                // todo alert success / error / partial success
                this.toastService.sendToast(true, this.translateService.instant('planners.contractorAddedSuccessText'))
                // todo refresh page
                // this.getCallServices(1);

            } else {
              Swal.fire(this.translateService.instant('callService.noCallService'))
            }
        } else {
          Swal.fire(this.translateService.instant('planners.noContractorSelected'))
        }
    }

    // checkbox functionality
    onChange(id) {
        if (this.all_selected_callServices.includes(id)) {
            this.all_selected_callServices = this.all_selected_callServices.filter((item) => item !== id);
        } else {
            this.all_selected_callServices.push(id);
        }

    }
}
