<div *ngIf="showDashboard">
    <nav class="navbar navbar-expand ps-3 contractor-select dual-container cursor-pointer" *ngIf="!searchActive">
        <div class="contractor dropdown">
              <a href="#" data-bs-toggle="dropdown" aria-expanded="false">
                  <div class="contractor-menu">
                      <span class="contractorName">{{currentContractor}}</span>
                  </div>
              </a>
              <ul class="contractor-dropdown dropdown-menu dropdown-menu-end" aria-labelledby="contractorDropdownMenuButton">
                  <li id="contractorDropdownMenuItems" *ngFor="let item of contractors">
                      <a class="dropdown-item"  (click)="switchContractor(item.id)">{{item.name}}</a>
                  </li>
              </ul>
        </div>
        <div class="right">
            <img src="assets/icons/search.svg" (click)="searchActive = true;">
        </div>
    </nav>
    <div class="d-flex" *ngIf="!searchActive">
        <nav class="workerteam navbar navbar-expand col-6">
            <div class="contractor dropdown" *ngIf="workerteams.length > 0">
                <a href="#" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="contractor-menu">
                        <div class="d-flex bd-highlight">
                            <div class="bd-highlight fw-bold" *ngIf="!loading">
                                <ng-container *ngIf="filterWorkerteams(workerteams).length === workerteams.length && workerteams.length !== 1">
                                    {{ 'fieldReporting.dashboard.allWorkerteams' | translate }}
                                </ng-container>
                                <ng-container *ngIf="filterWorkerteams(workerteams).length === 1">
                                    <ng-container *ngFor="let team of filterWorkerteams(workerteams)">
                                        {{ team.name }} - {{team.mainOrders}} ({{'basic.main' | translate}}) - {{team.extraOrders}} ({{'basic.extra' | translate}})
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="filterWorkerteams(workerteams).length > 1 && filterWorkerteams(workerteams).length < workerteams.length">
                                    {{ filterWorkerteams(workerteams).length }} {{ 'selected' | translate }}
                                </ng-container>
                                <ng-container *ngIf="filterWorkerteams(workerteams).length === 0">
                                    0 {{ 'selected' | translate }}
                                </ng-container>
                            </div>
                            <div *ngIf="loading">
                                <load-spinner></load-spinner>
                            </div>
                        </div>
                    </div>
                </a>
                <ul class="contractor-dropdown dropdown-menu dropdown-menu-end" aria-labelledby="contractorDropdownMenuButton">
                    <li *ngFor="let item of workerteams">
                        <div class="dropdown-item d-flex align-items-center">
                            <input type="checkbox" [checked]="item.checked" (change)="toggleWorkerteam(item)">
                            <label class="ms-2" (click)="toggleWorkerteam(item)">
                                {{item.name}} - {{item.mainOrders}} ({{'basic.main' | translate}}) - {{item.extraOrders}} ({{'basic.extra' | translate}})
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
        
        <div class="workerteam col-6 d-flex justify-content-end">
            <img [hidden]="topIcon == 1" (click)="topIconSwitch(1)" class="top-icon" src="assets/icons/key_gray_24dp.svg">
            <img [hidden]="topIcon == 0 || topIcon == 2 || topIcon == 3" (click)="topIconSwitch(1)" class="top-icon top-icon-chosen" src="assets/icons/key_blue_24dp.svg">
            <img [hidden]="topIcon == 2" (click)="topIconSwitch(2)" class="top-icon ms-3" src="assets/icons/person_lock_gray_24dp.svg">
            <img [hidden]="topIcon == 0 || topIcon == 1 || topIcon == 3" (click)="topIconSwitch(2)" class="top-icon top-icon-chosen ms-3" src="assets/icons/person_lock_blue_24dp.svg">
            <img [hidden]="topIcon == 3" (click)="topIconSwitch(3)" class="top-icon ms-3" src="assets/icons/lock_unlocked_gray_24dp.svg">
            <img [hidden]="topIcon == 0 || topIcon == 1 || topIcon == 2" (click)="topIconSwitch(3)" class="top-icon top-icon-chosen ms-3" src="assets/icons/lock_unlocked_blue_24dp.svg">
        </div>
    </div>
    <div class="main" *ngIf="!searchActive">
        <nav class="nav justify-content-between pt-3">
            <!-- The second part of ngStyle can later be adjusted accoring to design when the tab functionalities are completed -->
            <a class="ms-3 nav-tab" [ngStyle]="styleObject(1)" (click)="tabChanger(1)">{{'basic.all' | translate }}</a>
            <a class="nav-tab" [ngStyle]="styleObject(2)" (click)="tabChanger(2)">{{'fieldReporting.dashboard.mustDoForToday' | translate }}</a>
            <a class="me-3 nav-tab" [ngStyle]="styleObject(3)" (click)="tabChanger(3)">{{'basic.done' | translate }}</a>
        </nav>

        <hr>

        <div class="pt-2">
            <!-- Placeholder -->
            <div *ngIf="tab == 2" class="ml-5">
                <div class="blue-text fw-500 dual-container" (click)="toggleInventoryList()">
                    <div class="left cursor-pointer">
                        <img src="assets/icons/inventory_blue_24dp.svg">
                        <span class="ms-1">{{'fieldReporting.dashboard.todaysInventoryList' | translate }}</span>
                    </div>
                    <img class="right mr-5" [src]="expandInventoryList">
                </div>
                <div class="mt-2" *ngIf="showInventoryList">
                    <div *ngFor="let item of typeArray">
                        <div class="row">
                            <span class="blue-text col-1">{{item.count}}</span>
                            <span class="ms-3 font-weight-meter col-10">{{item.name}}</span>
                        </div>
                    </div>
                    <div *ngIf="typeArray.length == 0" class="margin-left-no-meters">{{'fieldReporting.dashboard.noMetersOrToolsNeeded' | translate }}</div>
                </div>
            </div>
            <hr *ngIf="tab == 2">
            <div class="pb-1 d-flex" [ngClass]="tab !== 3 ? 'justify-content-between' : 'justify-content-end'">
              <div ngbDropdown class="d-inline-block ml-5-percentage" *ngIf="tab !== 3">
                <button class="btn" id="dropdownWorklist" ngbDropdownToggle>
                  <span class="bold-text">{{tabTitle}}</span>
                  <span class="gray-text"> ({{currentWorkordersCount}})</span>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownWorklist" >
                  <button ngbDropdownItem (click)="setTabTitle('main')">{{'basic.mainWorklist' | translate}}</button>
                  <button ngbDropdownItem (click)="setTabTitle('extra')">{{'basic.extraWorklist' | translate}}</button>
                </div>
              </div>
              <div class="sort-button">
                <a data-bs-toggle="modal" data-bs-target="#filterModal" class="gray-text cursor-pointer sortByBtn" [ngClass]="{'active': sortChoice !== 'default'}" data-bs-dismiss="modal">
                  <img class="filter-icon" src="assets/icons/sort.svg">
                  <span class="ms-2 position">{{'basic.filter' | translate}}</span>
                  <img *ngIf="sortChoice !== 'default'" class="filter-icon" src="assets/icons/sort_blue.svg">
                </a>
              </div>
                <div class="modal fade" id="filterModal" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-body">
                                <button id="closeModalButton" data-toggle="modal"
                                        data-target="#filterModal" class="btn btn-default right"
                                        data-bs-dismiss="modal">Close</button>
                                <h4>
                                    <span id="forgotPassword-title" class="ms-1">{{'basic.sortBy' | translate }}</span>
                                </h4>
                                <div>
                                    <input class="sortRadio" type="radio" id="default" name="sortBy" [checked]="sortChoice == 'default'" value="default" (change)="changeFilter($event.target.value);" />
                                    <label for="default"> {{'basic.default' | translate }}</label>
                                    <hr>
                                    <input class="sortRadio" type="radio" id="address" name="sortBy" [checked]="sortChoice == 'address'" value="address" (change)="changeFilter($event.target.value)" />
                                    <label for="address"> {{'basic.address' | translate }}</label>
                                    <hr>
                                    <input class="sortRadio" type="radio" id="contactperson" name="sortBy" [checked]="sortChoice == 'contactperson'" value="contactperson" (change)="changeFilter($event.target.value);" />
                                    <label for="contactperson"> {{'basic.contactPerson' | translate }}</label>
                                    <hr>
                                    <input class="sortRadio" type="radio" id="starttime" name="sortBy" [checked]="sortChoice == 'starttime'" value="starttime" (change)="changeFilter($event.target.value);" />
                                    <label for="starttime"> {{'basic.startTime' | translate }}</label>
                                    <hr>
                                    <input class="sortRadio" type="radio" id="distance" name="sortBy" [checked]="sortChoice == 'distance'" value="distance" (change)="changeFilter($event.target.value);" />
                                    <label for="distance"> {{'basic.distance' | translate }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="displayedWorkorders.length > 0">
                <div *ngIf="tab !== 3">
                    <div *ngIf="showWorklist">
                      <div *ngFor="let order of filterOutQueued(displayedWorkorders)" class="content sharp-shadow rounded-card-sm mb-2" [ngClass]="{'reserved': order.reserver_id && order.reserver_id != this.userid}">
                        <ng-container *ngIf="order.time_window_start === null">
                          <div class="p-75 blue-text">{{'fieldReporting.dashboard.workorderIsInWorklist' | translate}}</div>
                          <app-work-order
                              [workorder]="order"
                              [userid]="userid"
                              [contractorType]="contractorType"
                              [search]="false"
                              (workorderClicked)="toSingleWorkorder(order)"
                              [report]="false"
                          ></app-work-order>
                        </ng-container>
                      </div>
                    </div>
                    <div *ngIf="!showWorklist">
                      <div *ngFor="let order of filterOutQueued(displayedWorkorders)" class="content sharp-shadow rounded-card-sm mb-2" [ngClass]="{'reserved': order.reserver_id && order.reserver_id != this.userid}">
                        <ng-container *ngIf="order.time_window_start !== null">
                          <app-work-order
                              [workorder]="order"
                              [userid]="userid"
                              [contractorType]="contractorType"
                              [search]="false"
                              (workorderClicked)="toSingleWorkorder(order)"
                              [report]="false"
                          ></app-work-order>
                        </ng-container>
                      </div>
                    </div>
                </div>
                <div *ngIf="tab === 3">
                    <div *ngFor="let report of filterOutQueued(displayedWorkorders)" class="content sharp-shadow rounded-card-sm mb-2" [ngClass]="{'reserved': report.reserver_id && report.reserver_id != this.userid, 'disabled': report.disabled}">
                        <app-work-order
                              [workorder]="report"
                              [userid]="userid"
                              [contractorType]="contractorType"
                              [search]="false"
                              (workorderClicked)="toSingleWorkorder(report)"
                              [report]="true"
                          ></app-work-order>
                    </div>
                </div>
            </div>
            <div class="pt-2" *ngIf="displayedWorkorders.length == 0">
                <div *ngIf="loading" class="ms-5 pb-2">{{'fieldReporting.dashboard.loading' | translate }}</div>
                <div *ngIf="!loading" class="ms-5 pb-2">{{'fieldReporting.dashboard.noWorkordersFound' | translate }}</div>
            </div>
        </div>
    </div>

<!--    &#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;-->

    <nav class="navbar navbar-expand ps-3 contractor-select dual-container cursor-pointer" *ngIf="searchActive">

        <div class="d-flex w-100">
            <div class="flex-shrink-1 mt-3" >
                <img src="assets/icons/chevron_left_gray_24dp.svg" (click)="searchActive = false;">
            </div>
            <div class="flex-grow-1 mx-2 mt-2">
                <input type="text" (keydown.enter)="search()" name="searchField" placeholder="{{'fieldReporting.dashboard.search' | translate }}" class="form-control max-content" [(ngModel)]="searchField">
            </div>
            <div class="flex-shrink-1">
                <button id="modal-submit" type="button" [disabled]="listSpinner" (click)="search()"  class="btn btn-primary mt-2">{{'basic.search' | translate }}</button>
            </div>
        </div>

    </nav>
    <div class="main" *ngIf="searchActive">
    <div class="pt-2">
      <div *ngIf="searchedWorkorders.length > 0">
          <div class="content card p-2">
              {{searchedWorkorders.length}} {{'basic.results' | translate }}
              <br>
              {{'fieldReporting.dashboard.clickWorkorders' | translate }}
              <div *ngIf="selectedWorkorders.length > 0">
                  <button id="interrupt" type="button" (click)="interruptSelected()" class="btn btn-danger mt-2">{{'fieldReporting.dashboard.interruptSelected' | translate }} ({{selectedWorkorders.length}})</button>
              </div>
          </div>
          <div *ngFor="let order of searchedWorkorders" class="content sharp-shadow rounded-card-sm mb-2"
           [ngClass]="{'reserved': order.reserver_id && order.reserver_id != this.userid}"
           [class.selected]="selectedWorkorders.includes(order)">
            <ng-container>
              <div *ngIf="order.time_window_start === null" class="p-75 blue-text">{{'fieldReporting.dashboard.workorderIsInWorklist' | translate}}</div>
              <app-work-order 
                  [workorder]="order"
                  [userid]="userid"
                  [contractorType]="contractorType"
                  [search]="true"
                  (workorderClicked)="toSingleWorkorder(order)"
                  (mouseDownEmit)="onMouseDown(order)"
                  (mouseUpEmit)="onMouseUp()"
              ></app-work-order>
            </ng-container>
          </div>
      </div>
      <div class="pt-2" *ngIf="searchedWorkorders.length == 0">
        <div class="ms-5 pb-2">{{'fieldReporting.dashboard.noWorkordersFound' | translate }}</div>
      </div>
    </div>
  </div>
</div>
<app-single-order-detail
[hidden]="!showSingleOrderDetail"
(showDashboardToggle)="showDashboardToggle()"
[currentTab]="tabTitle"
[id]="workorderId"
[runGetWorkorder]="runGetWorkorder">
</app-single-order-detail>

