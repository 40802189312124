import {Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators  } from '@angular/forms';
import { Router } from '@angular/router';
import {baseUrl} from '@environments/environment';
import { AuthService } from '@core/auth/auth.service';
import {Subject, takeUntil} from 'rxjs';
import Swal from "sweetalert2";
import {ToastService} from "@shared/services/toast/toast.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

/**
 * Functionality of login component can be found here.
 */

export class LoginComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()

  formGroup!: UntypedFormGroup
  forgotFormGroup!: UntypedFormGroup
  role!: string
  passwordShow = false
  authFail = false

  constructor(
      private authService: AuthService,
      private router: Router,
      private toastService: ToastService,
      private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.redirectIfToken()
    this.initForm()
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  initForm() {
    this.formGroup = new UntypedFormGroup({
      username: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required])
    })

    this.forgotFormGroup = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required])
    })
  }

  /**
   *
   */
  loginProcess() {
    if(this.formGroup.valid){
      // @ts-ignore
      let remember = document.getElementById("flexCheckDefault").checked ? 1 : 0;
      this.authService.login(`${baseUrl}/api/login`, this.formGroup.value)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(result => {
        if(result) {
          if(remember == 1) {
            localStorage.setItem('remember', String(remember));
          }
          this.authService.directByRole()
        } else {
          this.authFail = true
          document.getElementById("login-form")?.classList.add('mt-3');
          document.getElementById("login-form")?.classList.remove('mt-5');
        }
      })
    }
  }

  redirectIfToken() {
    if(this.authService.isLoggedIn(false)) {
      this.authService.directByRole()
    }
  }

  hideShowPassword() {
    let passwordField = document.getElementById("login-password");

    this.passwordShow = !this.passwordShow

    const type = passwordField!.getAttribute("type") === "password" ? "text" : "password";
    passwordField!.setAttribute("type", type);

  }

  forgotPassword() {
      if(this.forgotFormGroup.valid){
          this.authService.forgotPassword(this.forgotFormGroup.value)
              .pipe(takeUntil(this.componentDestroyed$))
              .subscribe(result => {
                  this.toastService.sendToast(true, this.translateService.instant('basic.emailSent'))
                  // @ts-ignore
                  document.getElementById("closeModalButton").click();
              })
      } else {
          Swal.fire(this.translateService.instant('basic.emailMissing'))
      }
  }
}
