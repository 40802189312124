import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@core/auth/components/login/login.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { PlannerComponent } from '@layout/planner/planner.component';
import { MapViewComponent } from '@modules/planner/components/map-view/map-view.component';
import { ContractorPageComponent } from '@modules/planner/components/contractor-page/contractor-page.component';
import { NewContractorComponent } from '@modules/planner/components/new-contractor/new-contractor.component';
import { UserManagementComponent } from '@modules/planner/components/user-management/user-management.component';
import { PlannerManagementComponent } from '@modules/planner/components/planner-management/planner-management.component';
import { CallServiceManagementComponent } from '@modules/planner/components/callService-management/callService-management.component';
import { CustomerAdminManagementComponent } from '@modules/planner/components/customerAdmin-management/customerAdmin-management.component';
import { HumanResourcesComponent } from '@modules/planner/components/human-resources/human-resources.component';
import { WorkersComponent } from '@modules/planner/components/workers/workers.component';
import { WorkerDetailComponent } from '@modules/planner/components/worker-detail/worker-detail.component';
import { FieldReportingComponent } from '@layout/field-reporting/field-reporting.component';
import { DashboardComponent } from '@modules/field-reporting/components/dashboard/dashboard.component';
import { ProjectImportComponent } from '@modules/planner/components/import-data/import-data.component';
import { WeeklyPlanningComponent } from '@modules/planner/components/weekly-planning/weekly-planning.component';
import { WeeklyPlanningDetailComponent } from '@modules/planner/components/weekly-planning-detail/weekly-planning-detail.component';
import { WeeklyPlanningMapComponent } from '@modules/planner/components/weekly-planning-map/weekly-planning-map.component';
import { ContractorDashboardComponent } from '@modules/planner/components/contractor-dashboard/contractor-dashboard.component';
import { ReportComponent } from '@modules/field-reporting/components/report/report.component';
import { TeamsComponent } from '@modules/planner/components/teams/teams.component';
import { EndUserCommunicationComponent } from '@modules/planner/components/end-user-communication/end-user-communication.component';
import { TeamsDetailComponent } from '@modules/planner/components/teams-detail/teams-detail.component';
import { CallServiceComponent } from '@layout/call-service/call-service.component';
import { CallServiceDashboardComponent } from '@modules/call-service/components/call-service-dashboard/call-service-dashboard.component';
import { SingleEnduserComponent } from '@modules/call-service/components/single-enduser/single-enduser.component';
import { EndUserLoginComponent } from '@modules/end-user/components/end-user-login/end-user-login.component';
import { ConfirmSlotComponent } from '@modules/end-user/components/confirm-slot/confirm-slot.component';
import { EndUserComponent } from '@layout/end-user/end-user/end-user.component';
import { InterruptComponent } from '@modules/field-reporting/components/interrupt/interrupt.component';
import { ReportsComponent } from '@shared/components/reports/reports.component';
import { ReportWorkorderDetailComponent } from '@modules/planner/components/report-workorder-detail/report-workorder-detail.component';
import { WorkordersWithoutCoordinatesComponent } from '@modules/planner/components/workorders-without-coordinates/workorders-without-coordinates.component';
import { ForgotPasswordComponent } from '@core/auth/components/forgot-password/forgot-password.component';
import { UserInfoResolver } from '@shared/services/user/user.info.resolver';
import { PlannerDetailComponent } from '@modules/planner/components/planner-detail/planner-detail.component';
import { CallServiceDetailComponent } from '@modules/planner/components/callService-detail/callService-detail.component';
import { WeeklyPlanningCompareComponent } from '@modules/planner/components/weekly-planning-compare/weekly-planning-compare.component';
import { ProjectLeadComponent } from '@layout/project-lead/project-lead.component';
import { ProjectLeadDashboardComponent } from '@modules/project-lead/components/project-lead-dashboard/project-lead-dashboard.component';
/**
 * This is our routing module which handles the routing of our application.
 */

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'confirm',
    component: EndUserComponent,
    children: [
      {
        path: '',
        component: EndUserLoginComponent
      },
      {
        path: 'installation-time',
        component: ConfirmSlotComponent,
      }
    ]
  },
  {
    path: 'konfirmere',
    component: EndUserComponent,
    children: [
      {
        path: '',
        component: EndUserLoginComponent,
        data: {
          language: 'da'
        },
      },
      {
        path: 'installation-time',
        component: ConfirmSlotComponent,
        data: {
          language: 'da'
        },
      }
    ]
  },
  {
    path: 'planner',
    component: PlannerComponent,
    canActivate: [ AuthGuard ],
    canActivateChild: [ AuthGuard ],
    data: {
      role: [1, 5]
    },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: ContractorDashboardComponent,
        data: {
          role: [1, 5]
        }
      }, {
        path: 'map',
        component: MapViewComponent,
        data : {msa : false, role: [1, 5]}
      },
      {
        path: 'msa-map',
        component: MapViewComponent,
        data : {msa : true, role: [1, 5]}
      },
      {
        path: 'contractor/:newcontractor',
        component: ContractorPageComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'contractor',
        component: ContractorPageComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'new-contractor',
        component: NewContractorComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'user-management',
        component: UserManagementComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'planner-management',
        component: PlannerManagementComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'callservice-management',
        component: CallServiceManagementComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'admin-management',
        component: CustomerAdminManagementComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'human-resources',
        component: HumanResourcesComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'workers',
        component: WorkersComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'teams',
        component: TeamsComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'teams-detail/:id',
        component: TeamsDetailComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'worker-detail/:id',
        component: WorkerDetailComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'planner-detail/:id',
        component: PlannerDetailComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'callService-detail/:id',
        component: CallServiceDetailComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'import-data',
        component: ProjectImportComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'weekly-planning',
        component: WeeklyPlanningComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'weekly-planning-detail/:id',
        component: WeeklyPlanningDetailComponent,
        resolve: {
          userInfo: UserInfoResolver
        },
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'weekly-planning-map/:id',
        component: WeeklyPlanningMapComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'end-user-communication',
        component: EndUserCommunicationComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'reports',
        component: ReportsComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'report-workorder-detail/:id',
        component: ReportWorkorderDetailComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'workorders-without-coordinates',
        component: WorkordersWithoutCoordinatesComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'weekly-planning-compare',
        component: WeeklyPlanningCompareComponent,
        data: {
          role: [1, 5]
        }
      }
    ]
  },{
    path: 'field-reporting',
    component: FieldReportingComponent,
    canActivate: [ AuthGuard ],
    canActivateChild: [ AuthGuard ],
    data: {
      role: [2]
    },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          role: [2]
        }
      },
      {
        path: 'report/:id',
        component: ReportComponent,
        data: {
          role: [2]
        }
      },
      {
        path: 'interrupt/:id',
        component: InterruptComponent,
        data: {
          role: [2]
        }
      }
    ]
  },
  {
    path: 'call-service',
    component: CallServiceComponent,
    canActivate: [ AuthGuard ],
    canActivateChild: [ AuthGuard ],
    data: {
      role: [3]
    },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: CallServiceDashboardComponent,
        data: {
          role: [3]
        }
      },
      {
        path: 'enduser/:id',
        component: SingleEnduserComponent,
        data: {
          role: [3]
        }
      }
    ]
  },
  {
    path: 'project-lead',
    component: ProjectLeadComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      role: [4]
    },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: ProjectLeadDashboardComponent,
        data: {
          role: [4]
        }
      },
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
