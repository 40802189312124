<div *ngIf="!showWOM">
    <div class="justify-content-between d-flex">
        <h3>Project status</h3>
        <div><button type="button" class="btn btn-xs btn-secondary" (click)="logout()">Logout</button></div>
    </div>
    <div *ngIf="projects.length === 0">No projects found</div>
    <div *ngFor="let project of projects" class="main">
        <div class="justify-content-between d-flex">
            <h4>{{project.name}}</h4>
            <button type="button" class="btn btn-primary btn-xs font-size-small" (click)="toggleWOM(project.id)">Workorder management</button>
        </div>
        <div class="container">
            <div class="row">
                <!-- Left Column -->
                <div class="col-12 col-sm-7 d-flex flex-column flex-sm-row align-items-center align-items-sm-start">
                    <!-- First Box -->
                    <div class="font-size-small text-center text-sm-left col-12 col-sm-4 mb-3 mb-sm-0">
                        <app-ngx-circle-custom
                            [efficiency]="project.progress.confirmed / project.progress.total"
                            [efficiencyTitle]="project.confirmedPercent + '%'"
                            color="green">
                        </app-ngx-circle-custom>
                        <div class="gray-text">Workorders confirmed</div>
                        <div class="fw-bold">{{project.progress.confirmed}} / {{project.progress.total}}</div>
                    </div>
                    <!-- Second Box -->
                    <div class="font-size-small text-center text-sm-left col-12 col-sm-4 mb-3 mb-sm-0">
                        <app-ngx-circle-custom
                            [efficiency]="project.progress.done / project.progress.total"
                            [efficiencyTitle]="project.donePercent + '%'"
                            color="green">
                        </app-ngx-circle-custom>
                        <div class="gray-text">Workorders done</div>
                        <div class="fw-bold">{{project.progress.done}} / {{project.progress.total}}</div>
                    </div>
                    <!-- Third Box -->
                    <div class="font-size-small text-center text-sm-left col-12 col-sm-4">
                        <app-ngx-circle-custom
                            [efficiency]="project.progress.interrupted / project.progress.total"
                            [efficiencyTitle]="project.interruptedPercent + '%'"
                            color="red">
                        </app-ngx-circle-custom>
                        <div class="gray-text">Workorders interrupted</div>
                        <div class="fw-bold">{{project.progress.interrupted}} / {{project.progress.total}}</div>
                    </div>
                </div>
                <!-- Right Column -->
                <div class="col-12 col-sm-4 font-size-small d-flex flex-column text-center text-sm-left mt-sm-0 mt-2">
                    <div>
                        <div class="gray-text">Contractor</div>
                        <div class="fw-bold">{{project.contractor_name}}</div>
                    </div>
                    <div class="mt-auto">
                        <div class="gray-text">Total amount of workorders</div>
                        <div class="fw-bold">{{project.progress.total}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showWOM">
    <app-reports
    [projectId]="projectId"
    ></app-reports>
</div>
