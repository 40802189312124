import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorkersService } from '@modules/planner/services/workers/workers.service';
import { finalize, forkJoin, Observable, Subject, takeUntil } from 'rxjs';
import { Worker } from '@shared/models/worker';
import { TeamsService } from '@modules/planner/services/teams/teams.service';
import { ToastService } from '@shared/services/toast/toast.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Team } from '@shared/models/team';
import { Calendar } from '@shared/models/calendar';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()

  allSkills: Array<string> = Array()
  teams: Team[] = []
  originalTeams: Array<Team> = Array()
  name: string = ""
  pageSpinner: boolean = true;

  // Create team
  createTeamName: string = ""
  allWorkers: Array<Worker> = Array()
  selectedWorkers: Array<any> = Array()
  currentTeamEfficiency: number = 0
  colorOuter = '#FF0000'
  colorInner = '#ffcccb'
  popupSpinner: boolean = false
  showTotalEfficiency: boolean = false

  constructor(
    private teamsService: TeamsService,
    private workers: WorkersService,
    private toastService: ToastService,
    private router: Router,
    private translateService: TranslateService
  ) { }



  /**
   * Get teams data and get workers data.
   * Runs when component is loaded.
   * @author Jesse Lindholm
   */
  ngOnInit(): void {
    this.getTeams()
    this.getWorkers()
  }

  /**
   * Destroy subscriptions to free space.
   * Runs when moved away from component.
   * @author Jesse Lindholm
   */
  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  /**
   * Get teams from api call. Assign information correctly to be displayed in HTML. Set name, initials and skills. Also
   * set skill to allSkills to be displayed in filter skills selection box
   */
  getTeams() {
    this.teamsService.getTeams()
     .pipe(takeUntil(this.componentDestroyed$))
     .subscribe(
      data => {
        let finalTeams = Array()
        for (let j = 0; j < Object.keys(data).length; j++) {
          let teamsData = data[j]
          teamsData.skills = Array()
          teamsData.finalWorkers = Array()
          teamsData.efficiencyNumber = parseFloat(teamsData.efficiency)
          teamsData.showCalendars = false
          if (teamsData.hasOwnProperty('calendars')) {
            // Get an array of [key, value] pairs from the calendars object
            const entries = Object.entries(teamsData.calendars);

            // Transform each [key, value] pair into a new object, setting the key as the id
            teamsData.calendars = entries.map(([key, value]) => {
              return {
                ...value, // Spread the original calendar properties
                id: parseInt(key), // Set the key as the id property after transforming it to number. Comes as string from backend
                checked: false, // Add or overwrite the checked property to false
                disabled: false
              };
            });
          }
          if (teamsData.hasOwnProperty('workers')) {
            // Go through workers in workerteam, setting name, initials and skills
            for (let i = 0; i < Object.keys(teamsData.workers).length; i++) {
              let object = <any>Object.values(teamsData.workers)[i]
              object.id = Object.keys(teamsData.workers)[i]
              object.name = object.firstname + " " + object.lastname
              object.initials = object.firstname.charAt(0) + object.lastname.charAt(0)
              object.efficiencyNumber = parseFloat(object.efficiency)
              let skills = Array();
              skills = this.returnSkills(object.qualifications)
              skills.forEach(element => {
                if (teamsData.skills.length > 0) {
                  if (!teamsData.skills.some(s => s.name == element.name))  teamsData.skills.push(element)
                } else teamsData.skills.push(element)
              })
              object.skills = skills
              teamsData.finalWorkers.push(object)
            }
          }
          finalTeams.push(teamsData)
        }
        this.teams = finalTeams;
        this.originalTeams = JSON.parse(JSON.stringify(finalTeams))
        this.pageSpinner = false
      }
    )
  }

  /**
   * Go through parsed qualifications and add skills to allSkills. Also return skills so that they can be assigned to team or worker.
   * @param skills usually data.qualifications, skills of worker or team
   * @returns skills in proper format
   */
  returnSkills(skills) {
    let skillsToReturn = Array()
    // If not null
    if (skills != null) {
      // Parse JSON
      try {
        let parsedQualifications = JSON.parse(skills)
        // Loop through parsed JSON
        for (let i = 0; i < parsedQualifications.length; i++) {
          let found = false
          for (let h = 0; h < this.allSkills.length; h++) {
            if (parsedQualifications[i] == this.allSkills[h] || parsedQualifications[i].name == this.allSkills[h]) {
              found = true
              break
            }
          }
          // Push to skills both instances if .name exists or is old version that does not have .name variables
          if (parsedQualifications[i].name == null) {
            skillsToReturn.push({name: parsedQualifications[i], checked: true})
            if (!found) this.allSkills.push(parsedQualifications[i])
          }
          else {
            skillsToReturn.push({name: parsedQualifications[i].name, checked: true})
            if (!found) this.allSkills.push(parsedQualifications[i].name)
          }
        }
      } catch (e) {
        // console.log(e)
      }
    }
    return skillsToReturn
  }

  /**
   * Get data of workers.
   */
  getWorkers() {
    this.workers.getWorkers()
     .pipe(takeUntil(this.componentDestroyed$))
     .subscribe(
      data => {
        for (let i = 0; i < data.length; i++) {
          data[i].efficiencyNumber = parseFloat(data[i].efficiency)
          data[i].skills = this.returnSkills(data[i].qualifications)
          this.allWorkers.push(data[i])
        }
      }
    )
  }

  /**
   * Navigate to correct team page.
   */
  goToTeamDetail(id) {
    this.router.navigate(['/planner/teams-detail/' + id])
  }

  /**
   * Function for making a new list with selected skills in workerteams.
   * @param value skill
   */
  skillChanged(value) {
    if (value != 'undefined') {
      let newList = Array()
      for (let i = 0; i < this.originalTeams.length; i++) {
        for (let j = 0; j < this.originalTeams[i].skills.length; j++) {
          if (this.originalTeams[i].skills[j].name == value) newList.push(this.originalTeams[i])
        }
      }
      this.teams = newList;
    }
    else {
      this.teams = this.originalTeams;
    }
  }

  /**
   * Saving function for workerteam. First make team with given name. Name is required before clicking submit. Set workerteamId from return and
   * use it in linking selected workers to correct workerteam. If we get no workerteamId send toast, telling that something failed. Use
   * forkJoin to put together all api calls for workers. After they have completed reset teams array resetting interface and get new teams with
   * added team. Also reset createTeamName and selectedWorkers array.
   */
  createNewTeam() {
    this.pageSpinner = true
    this.popupSpinner = true
    let workerteamId: number | null = null;
    this.teamsService.createTeam(this.createTeamName).pipe(
      finalize(() => {
        if (workerteamId) {
          let observables: Observable<any>[] = [];
          for (let i = 0; i < this.selectedWorkers.length; i++) {
            observables.push(this.workers.setWorkerToWorkerteam(this.selectedWorkers[i].id, workerteamId))
          }
          forkJoin(observables)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(
            () => {
              this.teams = Array()
              for (let i = 0; i < this.selectedWorkers.length; i++) {
                this.selectedWorkers[i].checked = false
              }
              this.getTeams()
              document.getElementById('hiddenClose')?.click()
            }
          )
          if (observables.length == 0) {
            this.getTeams()
            document.getElementById('hiddenClose')?.click()
          }
        } else {
          this.toastService.sendToast(false, this.translateService.instant('teams.teamSaveError'))
          document.getElementById('hiddenClose')?.click()
          this.pageSpinner = false
        }


      }),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe(
      data => {
        if (typeof data == 'number') workerteamId = data
        else workerteamId = null
      }
    )
  }

  /**
   * Find correct worker with id.
   * Check if it is checked or not, pushing it to selectedWorkers array if its checked.
   * If not checked remove from selectedWorkers array
   * Calculate total efficiency and display it at bottom of popup if it is more than zero.
   * Use break in for loops because there might be a lot of workers in one contractor.
   * Transform number to fixed 2 decimal number.
   * @param id id of clicked worker
   */
  workerClicked(id) {
    this.showTotalEfficiency = false
    let workerCount: number = 0.00;
    let efficiencyTotal: number = 0.00;
    for (let j = 0; j < this.allWorkers.length; j++) {
      if (this.allWorkers[j].id == id) {
        if (this.allWorkers[j].checked == false || this.allWorkers[j].checked == undefined) {
          this.allWorkers[j].checked = true
          this.selectedWorkers.push(this.allWorkers[j])
        } else {
          this.allWorkers[j].checked = false
          for (let i = 0; i < this.selectedWorkers.length; i++) {
            if (this.selectedWorkers[i].id == id) {
              this.selectedWorkers.splice(i, 1)
              break;
            }
          }
        }
        break;
      }
    }
    if (this.selectedWorkers.length == 0) this.currentTeamEfficiency = 0
    else {
      for (let i = 0; i < this.selectedWorkers.length; i++) {
        workerCount++;
        efficiencyTotal = efficiencyTotal + parseFloat(this.selectedWorkers[i].efficiency)
      }
      if (workerCount != 0 && efficiencyTotal != 0) {
        let total = efficiencyTotal / workerCount
        this.currentTeamEfficiency = parseFloat(total.toFixed(2))
      }
    }
    setTimeout(() => {
      this.showTotalEfficiency = true
    }, 100);

  }

  /**
   * Cancel creation of workerteam.
   * Reset values.
   */
  cancelCreateTeam() {
    this.createTeamName = ""
    this.currentTeamEfficiency = 0
    for (let i = 0; i < this.selectedWorkers.length; i++) {
      this.selectedWorkers[i].checked = false
    }
    this.selectedWorkers = Array()
    this.popupSpinner = false
  }

  toggleCalendarView(event: MouseEvent, index: number) {
    event.stopPropagation()
    if (this.teams[index].showCalendars === true) {
      this.teams[index].calendars.forEach(calendar => {
        if (calendar.checked === true) calendar.checked = false
      })
    }
    this.teams[index].showCalendars = !this.teams[index].showCalendars
  }

  calculateTotalCalendars() {
    let totalCheckedCalendarsIds = new Set<number>(); // Use a Set to automatically handle unique IDs

    this.teams.forEach(team => {
      if (team.calendars) {
        team.calendars.forEach(calendar => {
          if (calendar.checked === true && calendar.id !== null) {
            totalCheckedCalendarsIds.add(calendar.id); // Adds only unique IDs due to the nature of Sets
          }
        });
      }
    });

    // The size property of a Set gives the number of unique elements
    return totalCheckedCalendarsIds.size;
  }

  // GPT explanation: 
  // Loop:
  //  This code snippet processes a list of teams, each containing a list of calendars, to find and collect the IDs of all calendars that are marked as 'checked' and have a valid 'id'. 
  //  It starts by flattening the array of team calendars into a single array using `reduce` and `concat`, creating a combined list of all calendars from all teams.
  //  Then, it filters this list to include only those calendars that are checked (`calendar.checked === true`) and have a non-null/undefined `id`.
  //  Finally, it maps over the filtered calendars to extract just their `id` values.
  //  The result is an array of selected calendar IDs, which are those that are checked and have valid IDs across all teams.

  // Normal explanation: 
  // Loop:
  //  Goes through teams => calendars
  //  Generates selectedCalendarsIds from every teams every calendar, which has true in selected checkbox in UI.
  //  Filter duplicates out from array

  // Then navigate to weekly-planning-compare with correct queryParams
  compareCalendars() {
    
    const selectedCalendarIds = this.teams
    .reduce((acc: Calendar[], team) => acc.concat(team.calendars), [] as Calendar[])
    .filter((calendar): calendar is Calendar => Boolean(calendar)) // Safely exclude undefined or null calendars
    .filter(calendar => calendar.checked && calendar.id !== null) // Ensure id is not null, calendar is guaranteed to be defined here
    .map(calendar => calendar.id as number) // TypeScript understands id cannot be null here
    .reduce((acc: Set<number>, id) => acc.add(id), new Set<number>());

    // Convert the Set back to an array if needed
    const uniqueSelectedCalendarIds = Array.from(selectedCalendarIds);

    if (uniqueSelectedCalendarIds.length >= 2) {
      this.router.navigate(['/planner/weekly-planning-compare'], {
        queryParams: { calendarIds: uniqueSelectedCalendarIds.join(',') }
      });
    } else Swal.fire(this.translateService.instant('teams.twoUniqueCalendarsError'))
  }

  filterOnlyMsa(originalCalendar: Calendar) {
    const shouldDisable = this.calculateTotalCalendars() > 0;
    this.teams.forEach(team => {
      if (team.calendars) {
        team.calendars.forEach(calendar => {
          // Directly set the disabled status based on the condition
          calendar.disabled = shouldDisable && calendar.msa_id !== originalCalendar.msa_id;
        });
      }
    });
  }
  

}
