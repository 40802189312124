import { KeyValue } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlannerContractorService } from '@modules/planner/services/planner-contractor/planner-contractor.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@shared/services/toast/toast.service';
import { UserService } from '@shared/services/user/user.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-new-contractor',
  templateUrl: './new-contractor.component.html',
  styleUrls: ['./new-contractor.component.scss']
})
export class NewContractorComponent implements OnInit {

  tabindex: any
  fileError: any
  fileName: any
  dragAreaClass: any
  fileSelected: any

  uniqueData: any
  uniqueDataHeaders: any
  uniqueDataRows: any
  selectedUnique: any
  selectedAttribute = Array()
  excelMatchArray = Array()
  chosenColumn: string = "";
  userInformation: any
  newContractorData: any
  newContractorDefaults: any

  //Columns matched?
  showMatchColumns = false
  timeEstimateChosen = false
  oneOrSomeColumnsMatched = false
  latChosen = false
  lonChosen = false
  addressChosen = false
  zipChosen: boolean = false
  cityChosen: boolean = false


  // Stuff that is sent to backend
  contractorName = ''
  contractorType = 1
  contractorInfo: any
  excelFile: File | undefined
  headlineRow: any
  dataRow: any
  timeEstimates: any
  contractorId: any

  // Stuff for building the datasheet in HTML
  excelData: any
  rowsData: any
  dataStartIndex = 0
  headlineIndex = 0
  rowsAreSelected = false
  customRowsData: any

  headlineModel: any
  elementModel: any
  parametersCount = [1];

  // Generate meters
  generateMeters: boolean = true

  constructor(
    private contractorService: PlannerContractorService,
    private userService: UserService,
    private router: Router,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.tabindex = 1
    this.dragAreaClass = "drag-area";
    this.fileSelected = false
    this.fileName = ""
  }

  /**
   * Is called when the file inside the fileInput has changed.
   * @param event
   */
  onFileChange(event: any) {
    let files: FileList = event.target.files;
    this.saveFiles(files);
  }

  /**
   * These HostListeners act as eventlisteners.
   * They are listening to different drag events.
   */
  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "drag-area active";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "drag-area active";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "drag-area";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "drag-area";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "drag-area active";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      this.saveFiles(files);
    }
  }

  /**
   * Handles the file that has been input by the user.
   * Handles the styling related to fileupload.
   * @param files
   */
  saveFiles(files: FileList) {

    if (files.length > 1) {
      this.fileError = this.translateService.instant('planner.newContractor.oneFile')
    } else {
      this.excelFile = files[0]
      this.fileSelected = true
      this.dragAreaClass = "drag-area active";
      this.fileError = ""
      this.fileName = files[0].name
    }
  }

  postExcelFile() {
    if(this.excelFile) this.contractorService.uploadExcel(this.excelFile)
  }

  tabChanger(tab) {
    this.tabindex = tab
  }

  /**
   * Slices the rowsData to kill the relation to it before we splice customRowsData.
   * Otherwise splice applies to all related arrays aswell.
   */
  rowSelectHandler() {
    if (this.headlineIndex == 0 || this.dataStartIndex == 0) {
      this.toastService.sendToast(false, this.translateService.instant('import.importError1'))

    } else if (this.headlineIndex > this.dataStartIndex) {
      this.toastService.sendToast(false, this.translateService.instant('import.importError2'))

    } else {
      this.rowsAreSelected = true
      this.customRowsData = this.rowsData.slice()
      this.customRowsData.splice(0, this.dataStartIndex)
      this.showMatchColumns = true
    }
  }

  /**
   * Preserve original property order.
   * https://stackoverflow.com/questions/52793944/angular-keyvalue-pipe-sort-properties-iterate-in-order
   * @param a
   * @param b
   * @returns
   */
  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  onHeadlineChange($event) {
    this.selectedUnique = this.uniqueDataRows[this.headlineModel]
  }

  /**
   * Reads and handles the selected value and puts it in to an array accordingly.
   * Example:
   *    columnKey = lat-3
   *    primary = lat
   *    secondary = 3
   * @param columnKey
   */
  matchColumns(columnKey) {

    let primary = columnKey.substring(0, columnKey.indexOf("-"))
    let secondary = columnKey.split('-')[1]

    this.oneOrSomeColumnsMatched = true

    switch(primary) {
      case "lat": {
        this.excelMatchArray['lat'] = secondary
        this.latChosen = true
         break;
      }
      case "lon": {
        this.excelMatchArray['lon'] = secondary
        this.lonChosen = true
        break;
      }
      case "address": {
        this.excelMatchArray['address'] = secondary
        this.addressChosen = true
        break;
      }
      case "zip": {
        this.excelMatchArray['zip'] = secondary
        this.zipChosen = true
        break;
      }
      case "city": {
        this.excelMatchArray['city'] = secondary
        this.cityChosen = true
        break;
      }
      case "workorder": {
        this.excelMatchArray['workorder_identifier'] = secondary
        break;
      }
      case "timeparameter": {
        let tempArray = Array()
        let oneRemoved = false
        if (!this.excelMatchArray['time_parameter_columns']) this.excelMatchArray['time_parameter_columns'] = tempArray

        for (let index = 0; index < this.excelMatchArray['time_parameter_columns'].length; index++) {
          const element = this.excelMatchArray['time_parameter_columns'][index];
            if (element === secondary) {
              oneRemoved = true
              this.excelMatchArray['time_parameter_columns'].splice(index, 1);
            }
        }

        if(!oneRemoved) this.excelMatchArray['time_parameter_columns'].push(secondary)

        if (this.excelMatchArray['time_parameter_columns']) this.timeEstimateChosen = true
        break;
      }

      default: {
        this.excelMatchArray[primary] = secondary
        break;
      }
   }
  }

  /**
   * This is called when the user clicks on a button to bring him to the next available step in creating a contractor
   * 1. The user gives contractor name, type and info
   * 2. The user uploads an excel file to the browser
   * 3. Excel is sent to integration-api, and we wait for a response. The contractor is being created at the same time.
   *      When the api responds the user can select specific rows from the excel that Earth requires. The API will also respond with the contractor id.
   * 4. The user pairs the excel columns with DATABASE columns so that integration-api knows which columns posess addresses, coordinates etc
   * 5. We send filename, headerline, and startat to integration-api. (headerline and startat are row key indexes that the user selects in step 4)
   *      In this step the user can start adding time estimates to the contractor.
   * 6. The final step is to send time estimates to the contractor.
   */
  nextStep(next) {

    if(next == 1) {
      this.fileSelected = null
    }

    if (next == 2 && this.contractorName && this.contractorType) {
      this.tabindex = 2
    }

    if (next == 3 && this.fileSelected) {

      // We switch to 'Match columns' tab
      this.tabindex = 3

      // Send file to backend
      //console.log('excel is sending')

      this.contractorService.uploadExcel(this.excelFile!).subscribe(data => {
        this.excelData = data
        this.rowsData = data['rowsdata']
        this.rowsData.splice(0, 0, this.translateService.instant('import.selectRow'))
      })

      // next 4 is the final step
    } else if (next == 3 && !this.fileSelected) {
      this.toastService.sendToast(false, this.translateService.instant('planner.newContractor.noFile'))
    }

    if (next == 4 && this.headlineIndex != 0 && this.dataStartIndex != 0) {
      // In step 4, we dont set next to tabindex because there is no 4th tab
      if (!this.oneOrSomeColumnsMatched) {
        this.toastService.sendToast(false, this.translateService.instant('import.didNotMatchColumns'))
        this.rowsAreSelected = true

      } else if (this.generateMeters && (!this.addressChosen || !this.cityChosen || !this.zipChosen)) this.toastService.sendToast(false, this.translateService.instant('import.generateMetersError'))
      else if (!this.generateMeters && (!this.addressChosen && (!this.latChosen || !this.lonChosen) || (!this.latChosen && !this.lonChosen))){

        this.toastService.sendToast(false, this.translateService.instant('planner.newContractor.error3'))

        this.rowsAreSelected = true
      }
      else if (!this.timeEstimateChosen){

        Swal.fire({
          title: this.translateService.instant('planner.newContractor.noTimeEstimates'),
          showCancelButton: true,
          confirmButtonText: this.translateService.instant('basic.yes'),
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isDismissed) {
            this.rowsAreSelected = true

          } else if (result.isConfirmed) {
            this.continueToContractor()
          }
        })
      } else {
        this.continueToContractor()
      }
    }
  }

  /**
   * importHeadlinesAndReturnUniqueQueue()
   * Sends information for the backend that it needs to start making entries in database
   * based on the excel file and the parameters that the user has selected.
   */
   importHeadlinesAndReturnUniqueQueue() {

    let ufile = this.excelData['filename']
    // We take -1 from the chosen index to not choose faulty index. "Select row" that is added to rowdata takes index 0 place.
    let headerline = this.headlineIndex-1
    let startat = this.dataStartIndex-1

    this.excelMatchArray['contractor_id'] = this.contractorId

    if(this.excelMatchArray['time_parameter_columns']) {
      let jsonArray

      jsonArray = '[' + this.excelMatchArray['time_parameter_columns'].toString() + ']'

      this.excelMatchArray['time_parameter_columns'] = jsonArray
    }

    this.excelMatchArray['headerline'] = Number(headerline)

    this.contractorService.returnUnique(ufile, headerline, startat).subscribe(data => {
      //console.log(data)
    }).add(() => {
      this.contractorService.importHeadlines(ufile, headerline, startat, this.excelMatchArray, this.generateMeters).subscribe(d => {
        //console.log(d)
      })
    })
  }

  /**
   * Switches contractor to the newly created contractor
   */
  continueToContractor() {

    Swal.fire({
      title: this.translateService.instant('planner.newContractor.newlyCreatedContractor'),
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('basic.continue'),
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.contractorService.createContractor(this.contractorName, "Customer", this.contractorInfo, this.contractorType, null, null).subscribe(data => {
          this.contractorId = data
        }).add(() => {
          this.userService.getUserInfo().subscribe(
            data => {
              this.userInformation = data

              this.importHeadlinesAndReturnUniqueQueue()

              this.userService.updateCurrentContractor(this.userInformation.id, this.contractorId).subscribe(
                () => {
                  let newContractorData = 'newcontractor'
                  this.router.navigate(['/planner/contractor', newContractorData])
              })
          })
        })
      }
    })
  }

  /**
   * Can be called if the contractor is created to the backend. This deletes the contractor from the database.
   */
  cancelCreation() {

    Swal.fire({
      title: this.translateService.instant('planner.newContractor.abandonContractor'),
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('basic.yes'),
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire(this.translateService.instant('planner.newContractor.creationAbandoned'), '', 'info')
        //TODO: API kutsu joka pysäyttäis queuet ja poistais ufile?
        this.router.navigate(['/planner/dashboard'])
      }
    })
  }

  /**
   * Returns keys of key-value pairs of an object
   * Used in nested ngFor to display data correctly
   * @param obj
   * @returns
   */
  getKeys(obj){
    return Object.keys(obj)
  }

  /**
   * Returns values of key-value pairs of an object
   * Used in nested ngFor to display data correctly
   * @param obj
   * @returns
   */
  getValues(obj){
    return Object.values(obj)
  }

  /**
   * 30.11.2022
   * Move back to selecting rows in UI
   * @author Jesse Lindholm
   */
  moveBack() {
    this.showMatchColumns = false
  }
}
