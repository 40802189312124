<div class="container wider-container">
  <div class="row">
    <div class="col-md-12 offset-md-0">
      <div class="bg-white p-4">
        <div class="d-flex justify-content-between">
          <h5 [hidden]="tab != 1">{{ 'call-service.shared.searchCustomers' | translate }}</h5>
          <h5 [hidden]="tab != 2">{{ 'call-service.shared.callingList' | translate }}</h5>
          <h5 [hidden]="tab != 3">{{ 'basic.logOut' | translate }}</h5>

          <div class="d-flex">
            <div [ngClass]="tab == 1 ? 'blue-select' : 'gray-select'" (click)="switchTab(1)">
              {{ 'call-service.shared.searchCustomers' | translate }}
            </div>
            <div [ngClass]="tab == 2 ? 'blue-select' : 'gray-select'" class="ms-2" (click)="switchTab(2)">
              {{ 'call-service.shared.callingList' | translate }}
            </div>
            <div [ngClass]="tab == 3 ? 'blue-select' : 'gray-select'" class="ms-2" (click)="switchTab(3)">
              {{ 'basic.logOut' | translate }}
            </div>
          </div>
        </div>
        <div [hidden]="tab != 1">
          <div class="form-group mt-4">
            <div class="d-flex flex-column">
              <div class="control-label">{{ 'call-service.dashboard.searchCustomer' | translate }}</div>
              <div class="d-flex flex-column">
                <input type="text" id="search-input"
                  placeholder="{{ 'call-service.dashboard.searchCustomer' | translate }}" [(ngModel)]="searchWord"
                  class="form-control width-input">
                <div ngbDropdown class="d-inline-block mt-1">
                  <button type="button" class="btn btn-outline-secondary border-color" id="dropdownInputType"
                    ngbDropdownToggle>{{ selectedInputType.text }}</button>
                  <div ngbDropdownMenu aria-labelledby="dropdownInputType">
                    <div *ngFor="let inputType of inputTypes">
                      <button ngbDropdownItem type="button" (click)="changeInputType(inputType)">{{ inputType.text
                        }}</button>
                    </div>
                  </div>
                </div>
                <!-- Contractor dropdown -->
                <div ngbDropdown class="d-inline-block mt-1">
                  <button type="button" class="btn btn-outline-secondary border-color" id="dropdownContractorTab1"
                    ngbDropdownToggle>
                    {{ getSelectedContractorNames() || 'Select Contractors' }}
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownContractorTab1">
                    <div *ngFor="let contractor of contractors">
                      <label class="dropdown-item">
                        <input type="checkbox" [ngModel]="isContractorSelected(contractor)"
                          (ngModelChange)="toggleContractorSelection(contractor)">
                        {{ contractor.name }}
                      </label>
                    </div>
                  </div>
                </div>
                <!-- Week dropdown -->
                <div ngbDropdown class="d-inline-block mt-1">
                  <button type="button" class="btn btn-outline-secondary border-color" id="dropdownWeek"
                    ngbDropdownToggle>
                    {{ selectedWeek && selectedWeek.number !== 0 ? selectedWeek.number + ' - ' + selectedWeek.label :
                    'All weeks' }}
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownWeek" class="week-dropdown-menu">
                    <div class="week-dropdown-item" *ngFor="let week of weeks"
                      [class.active]="week.label === selectedWeek?.label">
                      <button ngbDropdownItem type="button" (click)="selectWeek(week)">
                        {{ week.number !== 0 ? week.number + ' - ' + week.label : 'All weeks' }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mt-1">
                  <button type="button" class="btn btn-primary" (click)="searchCustomer()">{{
                    'call-service.dashboard.search' | translate }}</button>
                </div>
              </div>
            </div>
          </div>
          <load-spinner [hidden]="!listSpinner"></load-spinner>
          <div class="shadow max-width-enduser-list-tab1" [hidden]="listSpinner || searchResults.length == 0">
            <div>
              <div class="border-all d-flex p-2 item-hover">
                <div class="col-3"><b>{{ 'basic.name' | translate }}</b></div>
                <div class="col-3"><b>{{ 'basic.address' | translate }}</b></div>
                <div class="col-3"><b>{{ 'basic.meteringPointAddress' | translate }}</b></div>
                <div class="col-3"><b>{{ 'basic.phoneNumber' | translate }}</b></div>
              </div>
            </div>
            <div *ngFor="let item of searchResults; let i = index">
              <a (click)="moveToEnduserPage(item.id, item.workorder_id)">
                <div [ngClass]="i == 0 ? 'border-all' : 'border-no-top'" class="d-flex p-2 item-hover">
                  <div class="col-3">{{ item.contact_person_first }} {{ item.contact_person_last }}</div>
                  <div class="col-3">{{ item.address }}</div>
                  <div class="col-3">{{ item.woaddress }}</div>
                  <div class="col-3" type="tel">{{ item.phone }}</div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div [hidden]="tab != 2">
          <div class="mt-3">
            <button class="btn btn-primary" (click)="downloadCallingCSVForAll()">{{
              'call-service.dashboard.downloadCSV' | translate }}</button>
          </div>
          <div class="mt-2">
            <div ngbDropdown class="d-inline-block">
              <button type="button" class="btn btn-outline-secondary border-color" id="dropdownContractor"
                ngbDropdownToggle>{{ selectedContractorFilter.name }}</button>
              <div ngbDropdownMenu aria-labelledby="dropdownContractor">
                <div *ngFor="let contractor of contractorsFilterGroup">
                  <button ngbDropdownItem type="button" (click)="filterCallingList('contractor', contractor)">{{ contractor.name}}</button>
                </div>
              </div>
            </div>
            <div ngbDropdown class="d-inline-block mx-2">
              <button type="button" class="btn btn-outline-secondary border-color" id="dropdownMSA"
                ngbDropdownToggle>{{ selectedMSAFilter.name }}</button>
              <div ngbDropdownMenu aria-labelledby="dropdownMSA">
                <div *ngFor="let msa of msaFilterGroup | filterMsaByContractor:selectedContractorFilter.id">
                  <button ngbDropdownItem type="button" (click)="filterCallingList('msa', msa)">{{ msa.name}}</button>
                </div>
              </div>
            </div>
            <div ngbDropdown class="d-inline-block me-2">
              <button type="button" class="btn btn-outline-secondary border-color" id="dropdownAccessType"
                ngbDropdownToggle>{{ selectedAccessTypeFilter.name }}</button>
              <div ngbDropdownMenu aria-labelledby="dropdownAccessType">
                <div *ngFor="let accessType of accessTypeFilterGroup | filterAccessTypeByContractor:selectedContractorFilter.id">
                  <button ngbDropdownItem type="button" (click)="filterCallingList('access-type', accessType)">{{ accessType.name}}</button>
                </div>
              </div>
            </div>
            <div ngbDropdown class="d-inline-block me-2">
              <button type="button" class="btn btn-outline-secondary border-color" id="dropdownEnduserType"
                ngbDropdownToggle>{{ selectedEnduserTypeFilter.name }}</button>
              <div ngbDropdownMenu aria-labelledby="dropdownAccessType">
                <div *ngFor="let enduserType of enduserTypes">
                  <button ngbDropdownItem type="button" (click)="filterCallingList('enduser-type', enduserType)">{{ enduserType.name}}</button>
                </div>
              </div>
            </div>
            <div ngbDropdown class="d-inline-block">
              <button type="button" class="btn btn-outline-secondary border-color" id="dropdownEnduserType"
                ngbDropdownToggle>{{ selectedPhaseFilter.text }}</button>
              <div ngbDropdownMenu aria-labelledby="dropdownAccessType">
                <div *ngFor="let phase of phases">
                  <button ngbDropdownItem type="button" (click)="filterCallingList('phase', phase)">{{ phase.text}}</button>
                </div>
              </div>
            </div>
            <div class="mt-2 d-flex">
              <input class="form-control w-auto" placeholder="{{'call-service.dashboard.filterByName' | translate}}" [(ngModel)]="filterName" (change)="filterCallingList(null, null)">
              <button class="btn btn-primary ms-1" (click)="filterCallingList(null, null)">{{'call-service.dashboard.filterByName' | translate}}</button>
            </div>
          </div>
          <!-- "Mandatory amount of calls done" content -->
          <app-call-log
          [title]="'Mandatory amount of calls'"
          [callingList]="mandatoryCallingList"
          ></app-call-log>

          <app-call-log
          [title]="'Calling list'"
          [callingList]="callingList"
          ></app-call-log>

          <app-call-log
          [title]="'Time passed'"
          [callingList]="timePassedCallingList"
          ></app-call-log>

          <!--
          <div class="mt-4">
            <h5>{{ 'call-service.dashboard.mandatoryCalls' | translate }}</h5>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.customer' | translate }}</th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.phone' | translate }}</th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.contractor' | translate }}</th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.accessType' | translate }}</th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.msa' | translate }}</th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.timeSlot' | translate }}</th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.daysLeft' | translate }}</th>
                  <th class="border-right sort min-height-th d-flex align-items-center" (click)="sortByCallLog()">
                    <span>{{ 'call-service.dashboard.callLog' | translate }}</span>
                    <img src="assets/icons/swap_vert_24.svg">
                  </th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.additionalInfo' | translate }}</th>
                  <th>{{ 'call-service.dashboard.actions' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of mandatoryCallingList">
                  <tr *ngIf="item.show" [ngClass]="getPhoneCommunicatedValue(item.phone_communicated)"
                    class="border-bottom">
                    <td class="call-log-name border-right">{{ item.contact_name }} ({{'personTypes.' + item.person_type | translate }})</td>
                    <td class="column-width border-right">
                      {{ item.phone }}
                      <img src="assets/icons/Copy_icon.svg" alt="Copy Icon" (click)="copyToClipboard(item.phone)" />
                      <img src="assets/icons/Call_icon.svg" alt="Call Icon" (click)="callPhoneNumber(item.phone)" />
                    </td>
                    <td class="column-width border-right">{{ item.contractor_name }}</td>
                    <td class="border-right column-width" *ngIf="item.access_type">{{'accessTypes.' + item.access_type | translate }}</td>
                    <td class="border-right column-width" *ngIf="!item.access_type">{{'call-service.accessTypeNotFound' | translate }}</td>
                    <td class="column-width border-right">{{item.msa_name}}</td>
                    <td class="installation-time-passed-list-time-window border-right">
                      <ng-container
                        *ngIf="item.time_start && item.time_window_start && item.time_end && item.time_window_end">
                        {{ item.time_window_start | date: 'dd.MM.yyyy' }} {{ formatTime(item.time_start) }} - {{
                        formatTime(item.time_end) }}
                      </ng-container>
                      <ng-container
                        *ngIf="!item.time_start || !item.time_window_start || !item.time_end || !item.time_window_end">
                        {{'planner.endUserCommunications.timeNotFound' | translate }}
                      </ng-container>
                    </td>
                    <td class="column-width border-right">
                      <ng-container *ngIf="item.time_window_start">
                        {{ calculateDaysUntilDate(item.time_window_start) }}
                        {{ calculateDaysUntilDate(item.time_window_start) === 1 ? ('call-service.day' | translate) :
                        ('call-service.days' | translate) }}
                      </ng-container>
                      <ng-container *ngIf="!item.time_window_start">
                        {{'planner.endUserCommunications.timeNotFound' | translate }}
                      </ng-container>
                    </td>
                    <td class="column-width border-right">
                      <span>{{ item.call_count }}/3</span>
                      <img *ngIf="item.call_count > 0" src="assets/icons/Call_not_answered_icon.svg" alt="Call Icon" />
                      <img *ngIf="item.call_count === 0" src="assets/icons/Call_not_called_icon.svg"
                        alt="No Call Icon" />
                    </td>
                    <td class="notes column-width border-right">
                      <div *ngIf="item.notes" class="tooltip-container">
                        <img src="assets/icons/info_blue_24dp.svg" class="info-icon">
                        <span class="tooltip-text">{{item.notes}}</span>
                      </div>
                    </td>
                    <td class="installation-time-passed-list-log-a-call-button">
                      <button *ngIf="!item.callStatus" class="btn btn-primary"
                        (click)="moveToEnduserPage(item.enduser_id, item.workorder_id)">
                        {{ 'call-service.openDetails' | translate }}
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>


          <div class="mt-4">
            <h5>{{ 'call-service.dashboard.callingList' | translate }}</h5>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.customer' | translate }}</th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.phone' | translate }}</th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.contractor' | translate }}</th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.accessType' | translate }}</th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.msa' | translate }}</th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.timeSlot' | translate }}</th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.daysLeft' | translate }}</th>
                  <th class="border-right sort min-height-th d-flex align-items-center" (click)="sortByCallLog()">
                    <span>{{ 'call-service.dashboard.callLog' | translate }}</span>
                    <img src="assets/icons/swap_vert_24.svg">
                  </th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.additionalInfo' | translate }}</th>
                  <th>{{ 'call-service.dashboard.actions' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of callingList">
                  <tr *ngIf="item.show" [ngClass]="getPhoneCommunicatedValue(item.phone_communicated)"
                    class="border-bottom">
                    <td class="call-log-name border-right">{{ item.contact_name }} ({{'personTypes.' + item.person_type | translate }})</td>
                    <td class="column-width border-right">
                      {{ item.phone }}
                      <img src="assets/icons/Copy_icon.svg" alt="Copy Icon" (click)="copyToClipboard(item.phone)" />
                      <img src="assets/icons/Call_icon.svg" alt="Call Icon" (click)="callPhoneNumber(item.phone)" />
                    </td>
                    <td class="column-width border-right">{{ item.contractor_name }}</td>
                    <td class="border-right column-width" *ngIf="item.access_type">{{'accessTypes.' + item.access_type | translate }}</td>
                    <td class="border-right column-width" *ngIf="!item.access_type">{{'call-service.accessTypeNotFound' | translate }}</td>
                    <td class="column-width border-right">{{item.msa_name}}</td>
                    <td class="installation-time-passed-list-time-window border-right">
                      <ng-container
                        *ngIf="item.time_start && item.time_window_start && item.time_end && item.time_window_end">
                        {{ item.time_window_start | date: 'dd.MM.yyyy' }} {{ formatTime(item.time_start) }} - {{
                        formatTime(item.time_end) }}
                      </ng-container>
                      <ng-container
                        *ngIf="!item.time_start || !item.time_window_start || !item.time_end || !item.time_window_end">
                        {{'planner.endUserCommunications.timeNotFound' | translate }}
                      </ng-container>
                    </td>
                    <td class="column-width border-right">
                      <ng-container *ngIf="item.time_window_start">
                        {{ calculateDaysUntilDate(item.time_window_start) }}
                        {{ calculateDaysUntilDate(item.time_window_start) === 1 ? ('call-service.day' | translate) :
                        ('call-service.days' | translate) }}
                      </ng-container>
                      <ng-container *ngIf="!item.time_window_start">
                        {{'planner.endUserCommunications.timeNotFound' | translate }}
                      </ng-container>
                    </td>
                    <td class="column-width border-right">
                      <span>{{ item.call_count }}/3</span>
                      <img *ngIf="item.call_count > 0" src="assets/icons/Call_not_answered_icon.svg" alt="Call Icon" />
                      <img *ngIf="item.call_count === 0" src="assets/icons/Call_not_called_icon.svg"
                        alt="No Call Icon" />
                    </td>
                    <td class="notes column-width border-right">
                      <div *ngIf="item.notes" class="tooltip-container">
                        <img src="assets/icons/info_blue_24dp.svg" class="info-icon">
                        <span class="tooltip-text">{{item.notes}}</span>
                      </div>
                    </td>
                    <td class="installation-time-passed-list-log-a-call-button">
                      <button *ngIf="!item.callStatus" class="btn btn-primary"
                        (click)="moveToEnduserPage(item.enduser_id, item.workorder_id)">
                        {{ 'call-service.openDetails' | translate }}
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>

          <div class="mt-4">
            <h5>{{ 'call-service.dashboard.timePassed' | translate }}</h5>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.customer' | translate }}</th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.phone' | translate }}</th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.contractor' | translate }}</th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.accessType' | translate }}</th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.msa' | translate }}</th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.timeSlot' | translate }}</th>
                  <th class="border-right min-height-th">{{ 'call-service.dashboard.daysLeft' | translate }}</th>
                  <th class="border-right sort min-height-th d-flex align-items-center" (click)="sortByCallLog()">
                    <span>{{ 'call-service.dashboard.callLog' | translate }}</span>
                    <img src="assets/icons/swap_vert_24.svg">
                  </th>
                  <th class="border-right">{{ 'call-service.dashboard.additionalInfo' | translate }}</th>
                  <th>{{ 'call-service.dashboard.actions' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of timePassedCallingList">
                  <tr *ngIf="item.show" [ngClass]="getPhoneCommunicatedValue(item.phone_communicated)"
                    class="border-bottom">
                    <td class="call-log-name border-right">{{ item.contact_name }} ({{'personTypes.' + item.person_type | translate }})</td>
                    <td class="column-width border-right">
                      {{ item.phone }}
                      <img src="assets/icons/Copy_icon.svg" alt="Copy Icon" (click)="copyToClipboard(item.phone)" />
                      <img src="assets/icons/Call_icon.svg" alt="Call Icon" (click)="callPhoneNumber(item.phone)" />
                    </td>
                    <td class="column-width border-right">{{ item.contractor_name }}</td>
                    <td class="border-right column-width" *ngIf="item.access_type">{{'accessTypes.' + item.access_type | translate }}</td>
                    <td class="border-right column-width" *ngIf="!item.access_type">{{'call-service.accessTypeNotFound' | translate }}</td>
                    <td class="column-width border-right">{{item.msa_name}}</td>
                    <td class="installation-time-passed-list-time-window border-right">
                      <ng-container
                        *ngIf="item.time_start && item.time_window_start && item.time_end && item.time_window_end">
                        {{ item.time_window_start | date: 'dd.MM.yyyy' }} {{ formatTime(item.time_start) }} - {{
                        formatTime(item.time_end) }}
                      </ng-container>
                      <ng-container
                        *ngIf="!item.time_start || !item.time_window_start || !item.time_end || !item.time_window_end">
                        {{'planner.endUserCommunications.timeNotFound' | translate }}
                      </ng-container>
                    </td>
                    <td class="border-right column-width">
                      <ng-container *ngIf="item.time_window_start">
                        {{ calculateDaysUntilDateAbs(item.time_window_start) }}
                        {{ calculateDaysUntilDateAbs(item.time_window_start) === 1 ? ('call-service.dayAgo' | translate)
                        : ('call-service.daysAgo' | translate) }}
                      </ng-container>
                      <ng-container *ngIf="!item.time_window_start">
                        {{'planner.endUserCommunications.timeNotFound' | translate }}
                      </ng-container>
                    </td>
                    <td class="column-width border-right">
                      <span>{{ item.call_count }}/3</span>
                      <img *ngIf="item.call_count > 0" src="assets/icons/Call_not_answered_icon.svg" alt="Call Icon" />
                      <img *ngIf="item.call_count === 0" src="assets/icons/Call_not_called_icon.svg"
                        alt="No Call Icon" />
                    </td>
                    <td class="notes column-width border-right">
                      <div *ngIf="item.notes" class="tooltip-container">
                        <img src="assets/icons/info_blue_24dp.svg" class="info-icon">
                        <span class="tooltip-text">{{item.notes}}</span>
                      </div>
                    </td>
                    <td class="installation-time-passed-list-log-a-call-button">
                      <button *ngIf="!item.callStatus" class="btn btn-primary"
                        (click)="moveToEnduserPage(item.enduser_id, item.workorder_id)">
                        {{ 'call-service.openDetails' | translate }}
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>

        -->

        </div>



        <button class="btn btn-primary" [hidden]="tab != 3" (click)="logout()">{{ 'basic.logOut' | translate }}</button>
      </div>
    </div>
  </div>
</div>
