<div class="page-container">
    <div class="page-heading">
        <h3>
            <img src="assets/icons/person_black_24dp.svg">
            <span class="page-title">{{'basic.plannerManagement' | translate}}</span>
        </h3>
    </div>
    <div class="page-content">
        <div class="card">
            <div class="card-body">
                <div>
                    <div class="row">
                        <div class="col-4 form-group max-content">
                            <label class="gray-text">{{'planners.search' | translate}}</label><br>
                            <input type="text" [(ngModel)]="searchInput" placeholder="{{'basic.name' | translate}}" class="form-control">
                        </div>
                        <div class="col-8 form-group text-right">
                            <button [disabled]="!superuser" class="btn btn-primary text-white mt-4" id="newPlanner" data-bs-toggle="modal" data-bs-target="#plannerModal">+ {{'planners.newPlanner' | translate}}</button>
                        </div>
                    </div>
                    <div class="row">
                        <table class="mt-5 min-width">
                            <thead>
                            <tr>
                                <th role="button" scope="col" [appSort]="planners" data-order="desc" data-name="name" class="col-3 gray-text font-weight-normal min-width">
                                    <span>{{'planners.nameAndUsername' | translate}}</span>
                                    <img src="assets/icons/unfold_more_gray_24dp.svg">
                                </th>
                                <th scope="col" class="col-3 gray-text font-weight-normal min-width">{{'basic.tags' | translate}}</th>
                                <th scope="col" class="col-3 gray-text font-weight-normal min-width">{{'basic.contractors' | translate}}</th>
                            </tr>
                            </thead>
                            <tbody *ngIf="planners.length > 0" class="min-width">
                            <tr *ngFor="let item of planners | searchFilter: searchInput; let i = index"  class="border-bottom border-top border-gray">
                                <td class="col-4 d-flex w-100">
                                    <input type="checkbox" #checkboxes class="contractorchecker" value="{{item.id}}" name="contractorchecker" (change)="onChange(item.id)">
                                    <a href='#' class="" (click)="goToPlannerDetail($event, item.id)">
                                        <div class="bold-text black-text d-flex">
                                            <img *ngIf="!item.disabled" src="assets/icons/active.svg">
                                            <img *ngIf="item.disabled" src="assets/icons/not_active.svg">
                                            <div>{{item.name}}</div>
                                        </div>
                                    </a>
                                </td>
                                <td class="col-3">
                                    <div class="same-line">
                                        <div *ngFor="let tag of item.tags" class="gap-between-elements text-align-center gray-text rounded-pill px-2">{{tag}}</div>
                                    </div>
                                </td>
                                <td class="col-3">
                                    <div class="same-line">
                                        <div *ngFor="let contractor of item.contractors" class="gap-between-elements text-align-center gray-text rounded-pill px-2">{{contractor}}</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                            <tbody *ngIf="planners.length == 0" class="min-width">
                                <tr>
                                    <td *ngIf="loading">{{'basic.loading' | translate}}</td>
                                    <td *ngIf="!loading">{{'planners.noPlanners' | translate}}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <div *ngIf="contractors.length > 0 && superuser">
                            <div>{{'planners.addContractor' | translate}}</div>
                            <select #contractorselect (change)="onSelected(contractorselect.value)">
                                <option>-{{'planners.selectContractor' | translate}}-</option>
                                <option *ngFor="let item of contractors" value="{{item.id}}">{{item.name}} {{item.id == currentContractorId ? ('planners.currentContractor' | translate) : '' }}</option>
                            </select>
                            <br>
                            <button (click)="addContractor()" class="btn btn-primary col-3">{{'planners.add' | translate}}</button>
                        </div>
                    </div>
                    <!-- Modal -->
                    <div class="modal fade" id="plannerModal" tabindex="-1" aria-labelledby="plannerModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-body padding-modal">
                                    <img src="assets/icons/person_black_24dp.svg" class="top-position-image">
                                    <span class="title ms-2">{{'planners.newPlanner' | translate}}</span>
                                    <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
                                    <form ngNativeValidate (ngSubmit)="addNewPlanner()">

                                        <div class="form-group mt-3 row">
                                            <div class="col-6">
                                                <label class="gray-text"><span class="mt-1">*</span>{{'basic.firstName' | translate}}</label><br>
                                                <input type="text" [(ngModel)]="firstName" [ngModelOptions]="{standalone: true}" class="form-control " required>
                                            </div>
                                            <div class="col-6">
                                                <label class="gray-text"><span class="mt-1">*</span>{{'basic.lastName' | translate}}</label><br>
                                                <input type="text" [(ngModel)]="lastName" [ngModelOptions]="{standalone: true}" class="form-control" required>
                                            </div>

                                        </div>
                                        <div class="mt-3 row form-group">
                                            <div class="col-6">
                                                <div class="gray-text form-group">
                                                    <label><span class="mt-1">*</span>{{'basic.email' | translate}}</label><br>
                                                    <input type="email"  [(ngModel)]="email" [ngModelOptions]="{standalone: true}" class="form-control" required>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="mt-3 row form-group">
                                            <div class="col-6">
                                                <div class="gray-text form-group">
                                                    <label><span class="mt-1">*</span>{{'basic.username' | translate}}</label><br>
                                                    <input type="text"  [(ngModel)]="username" [ngModelOptions]="{standalone: true}" class="form-control" required>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 row form-group">
                                            <div class="col-6">
                                                <div class="gray-text form-group">
                                                    <label><span class="mt-1">*</span>{{'basic.password' | translate}}</label><br>
                                                    <input type="password"  [(ngModel)]="password" minlength="6" [ngModelOptions]="{standalone: true}" class="form-control" required>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="gray-text form-group">
                                                    <label><span class="mt-1">*</span>{{'basic.passwordAgain' | translate}}</label><br>
                                                    <input type="password"  [(ngModel)]="password2" minlenght="6" [ngModelOptions]="{standalone: true}" class="form-control" required>
                                                    <span [hidden]="password == password2" class="red-text">{{'basic.passwordsDontMatch' | translate}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="mt-3 form-group row">
                                            <div class="col-6">
                                                <p class="gray-text">{{'basic.contractors' | translate}}</p>
                                                <select class="form-select" (change)="selectContractor($event.target.value)">
                                                    <option [value]="">{{'planners.selectContractors' | translate}}</option>
                                                    <option *ngFor="let contractor of unselectedContractors" [value]="contractor.id">{{contractor.name}} {{contractor.id == currentContractorId ? ('planners.currentContractor' | translate) : '' }}</option>
                                                </select>

                                                <div class="same-line mt-3">
                                                    <div  *ngFor="let contractor of selectedContractors" (click)="unselectContractor(contractor.id)" style="cursor: pointer;" class="gap-between-elements text-align-center gray-text rounded-pill px-2">{{contractor.name}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="mt-3 form-group row">
                                            <div class="col-6">
                                                <p class="gray-text">{{'basic.interfaceLanguage' | translate}}</p>
                                                <select class="form-select" (change)="languageChange($event.target.value)">
                                                    <option *ngFor="let language of interfaceLanguages" [value]="language.databaseLanguage">{{language.displayName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="mt-5">
                                            <button type="submit" id="modalSaveButton" class="btn btn-primary col-3">{{'basic.save' | translate}}</button>
                                            <button type="button" id="modalClose" class="btn btn-secondary ms-3 col-3" data-bs-dismiss="modal">{{'basic.cancel' | translate}}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>



