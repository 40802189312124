import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PlannerContractorService } from '@modules/planner/services/planner-contractor/planner-contractor.service';
import { WorkorderService } from '@modules/planner/services/workorder/workorder.service';
import { ToastService } from '@shared/services/toast/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { interval, startWith, Subject, Subscription, switchMap, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core'
import { Workorder } from '@shared/models/workorder';
import barcodesJSON from 'assets/barcodes.json'
import { Barcode } from '@shared/models/barcode';
import { Sms } from '@shared/models/sms';
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-contractor-page',
  templateUrl: './contractor-page.component.html',
  styleUrls: ['./contractor-page.component.scss']
})
export class ContractorPageComponent implements OnInit, OnDestroy {

  // Barcodes
  barcodes: Barcode[] = barcodesJSON.available_barcodes.sort((a, b) => (a.description > b.description) ? 1 : -1)
  selectedBarcodes: Barcode[] = [] as Barcode[]
  barcodeEdit: boolean = false
  barcodesSet: boolean = false

  // Sms
  smsEdit: boolean = false
  smsMessages: Sms[] = []
  smsSettings= {'sender':'','timezone':'+00:00'}
  smsTooltipHover = false
  // values for new message
  over1Message = false
  smsContentRealLength = 0
  smsContentMaxLength = 918
  smsContentMessageLength = 160
  smsContent = ''
  smsTiming = '1d'
  smsSendWhen = 'ifnotconfirmed'
  smsSendAccessType = '1'
  smsPhase = '1'
  smsSender = ''
  smsTimezone = 'Europe/London'
  smsSendToWho: string = '2'


  timeInterval: Subscription | undefined
  componentDestroyed$: Subject<boolean> = new Subject()

  //editor booleans
  infoEditor = false
  timeEditor = false
  msaEditor = false
  excelEditor = false
  addMsaActive = false

  //loading booleans
  msaEstimatesLoading = true
  estimatesLoading = true
  importedLoading = true

  estimatesSet = Array()
  calculatedTime: any

  contractorId: any | undefined
  userid: any | undefined

  contractorData: any
  contractorEstimates: any
  contractorName: string | undefined
  contractorInfo: string = ""
  contractorOrderCount: any
  contractorProjectCount: any
  contractorTimeEstimates: any
  contractorMsaEstimates: any
  contractorMailTemplates: any
  contractorLogo: any
  contractorCreated: string = ""
  contractorMsaObject: any
  msaNames: any
  allEstimates: any
  timeEstimatesObj: any
  ngModelArray: any[] = [];
  selectedMsa: any
  msaDefaults = Array()
  selectedMsaId: any;
  allEstimatesObject: any
  chosenEstimateObject: any
  myParam: string | undefined;
  showContractorInfo: boolean = false;

  //queue stuff
  queueProgress = 0
  queues = Array()
  activeWorkorderQueues = false
  queFinished: boolean = false

  //mailing file stuff
  dragAreaClass: any
  fileName: any
  fileError: any
  fileSelected: any
  logoFile: any
  createMailText = ''
  scheduledText = ''
  freeText = ''
  scheduledTextEdit = ''
  freeTextEdit = ''
  selectedText = ''
  modalText = ''
  mailType = 'scheduled'
  mailingPreview = false
  modalType = 'scheduled'
  variablesHover = false
  infoHover = false

  workorders: Array<Workorder> = {} as Workorder[]
  limitValue: number = 10

  startDate;
  endDate;
  results: number | null = null

  imagesLoading: boolean = false
  endingTimeDelay: number = 1
  editToggleMailingFileOptions: boolean = false
  endingTimeDelayOptions: number[] = [1,2,3,4,5,7,14]



  // Fuse types
  /*
  fuseTypes: Array<ReportType> = [
    {id: 0, name: 'Cartridges', openCustom: false},
    {id: 1, name: 'Circuit-breaker', openCustom: false},
    {id: 2, name: 'Other', openCustom: true}
  ]

  // Extra work types
  extraWorkTypes: Array<ReportType> = [
    {id: 0, name: 'Extra work 1', openCustom: false},
    {id: 1, name: 'Extra work 2', openCustom: true},
    {id: 2, name: 'Extra work 3', openCustom: false}
  ]

  outOfRangeReasons: Array<ReportType> = [
    {id: 0, name: 'Malfunction', openCustom: false},
    {id: 1, name: 'Theft', openCustom: false},
    {id: 2, name: 'Energy hoarder', openCustom: false},
    {id: 3, name: 'Happens', openCustom: true}
  ]
  */

  constructor(
    private plannerContractorService: PlannerContractorService,
    private userService: UserService,
    private workorderService: WorkorderService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.getContractorInfo()
    this.dragAreaClass = "drag-area";
  }

  ngOnDestroy(): void {
    this.timeInterval?.unsubscribe()
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  /**
   * Gets all information of current contractor and inserts values to variables.
   * After the subscribe completes, calls other methods to run.
   */
  getContractorInfo() {
    this.contractorMsaEstimates = []
    this.route.params.subscribe((params: Params) => this.myParam = params['newcontractor'])
    this.getCurrentContractorId(() =>
      this.plannerContractorService.getContractorById(this.contractorId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        try {
          if (data.communications) {
            let parsedJson = JSON.parse(data.communications)
            if (parsedJson.endingTimeDelayInDays) this.endingTimeDelay = parsedJson.endingTimeDelayInDays
          }
        } catch {
          this.endingTimeDelay = 1
        }
        this.contractorCreated = data.created
        this.contractorData = data
        this.contractorName = data.name
        this.contractorInfo = data.information
        this.contractorEstimates = data.default_time_parameters
        this.contractorMailTemplates = data.communications
        this.contractorLogo =  this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64,' + data.logo)
        this.contractorTimeEstimates = data.default_time_parameters
        this.allEstimates = data.all_estimates

        if(data.sms) {
          let parsedsms = JSON.parse(data.sms)

          if(parsedsms.sender) {
            this.smsSettings.sender = parsedsms.sender
            this.smsSender = parsedsms.sender
          }
          if(parsedsms.timezone) {
            this.smsSettings.timezone = parsedsms.timezone
            this.smsTimezone = parsedsms.timezone
          }
          if(parsedsms.messages) {
            this.smsMessages = parsedsms.messages
            this.smsMessages.forEach(sms => {
              this.checkMaxLength(sms, sms.content)
            })
          }
        }

        // Check for barcodes in contractor and add them to UI
        if (data.hasOwnProperty('contractor_barcodes')) {
          if (data.contractor_barcodes) {
            this.selectedBarcodes = []
            this.barcodesSet = true
            let contractorBarcodes = JSON.parse(data.contractor_barcodes)
            contractorBarcodes.forEach(barcode => {
              let barcodeObject = this.barcodes.find(b => b.code === barcode)
              if (barcodeObject) {
                barcodeObject.selected = true
                this.selectedBarcodes.push(barcodeObject)
              }
            })
            this.selectedBarcodes.sort((a, b) => (a.description > b.description) ? 1 : -1)
          }
        }
      })
      .add(() => {
        if(this.myParam) this.checkQueues()
        this.getWorkorders()
        this.getContractorCount()
        this.calculateTimeEstimates()
        this.timeEstimatesJSONStringToArray()
        //this.getTimeEstimates()
        this.getMsaEstimates()
        this.createAllEstimatesObject()
        this.setMailTemplates()
      })
    )
  }

  newContractorNextBtn() {
    this.router.navigate(['planner/contractor'])
  }

  /**
   * Is called when the file inside the fileInput has changed.
   * @param event
   */
    onFileChange(event: any) {
    let files: FileList = event.target.files;
    this.saveFiles(files);
  }

  /**
   * These HostListeners act as eventlisteners.
   * They are listening to different drag events.
   */
  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "drag-area active";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "drag-area active";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "drag-area";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "drag-area";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "drag-area active";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      this.saveFiles(files);
    }
  }

  /**
   * Handles the file that has been input by the user.
   * Handles the styling related to fileupload.
   * @param files
   */
  saveFiles(files: FileList) {

    if (files.length > 1) {
      this.fileError = "Only one file at time!"
    } else {
      this.logoFile = files[0]
      this.fileSelected = true
      this.dragAreaClass = "drag-area active";
      this.fileError = ""
      this.fileName = files[0].name
    }
  }

  clearFiles() {
    this.logoFile = null
    this.fileSelected = null
    this.fileName = ''
    this.dragAreaClass = "drag-area"
  }

  /**
   * Checks if the user has a workorder queue
   */
  checkQueues() {

    this.timeInterval = interval(3000)
    .pipe(
      startWith(0),
      switchMap(() => this.plannerContractorService.getQueues())

    ).subscribe(d => {
      //console.log(d)
      if (d.length != 0) {
        for (let i = 0; i < d.length; i++) {
          //console.log(d[i])
          //console.log(d[i].status)
          if(d[i].contractor_id == this.contractorId && d[i].queue_type == 1 && d[i].status == 3) {

            if (d[i].processed_amount == null) {
              this.queueProgress = 0
            } else {
              this.queueProgress = d[i].processed_amount

              if (d[i].status == 50) {
                this.queFinished = true
                this.timeInterval?.unsubscribe()
              } else {
                this.activeWorkorderQueues = true
              }
            }
          } else if (d[i].contractor_id == this.contractorId && d[i].queue_type == 1 && d[i].status == 50) {
            this.queueProgress = d[i].processed_amount
            this.queFinished = true
            this.timeInterval?.unsubscribe()
            //this.queueProgress = 100
          }
        }
      } else {
        this.activeWorkorderQueues = false
      }
    })
  }

  enableTimeEditor() {
    this.timeEditor = true
  }

  enableMsaEditor() {
    this.msaEditor = true
  }

  disableTimeEditor() {
    this.timeEditor = false
    this.msaEditor = false
    this.cancelMsaEstimateAdding()
    this.getContractorInfo()
  }

  /**
   * This is a very custom function constructs a 'time-estimate-json' object from form values and sends them to the backend.
   * @param f
   */
  saveChangesToEstimates(f: NgForm) {

    this.estimatesLoading = true

    let estimateArr = Array()

    for (let key of Object.keys(f.value)) {
      let value = f.value[key]
      let keyString = key.toString()

      if(key.toString().includes('name')) {
        // is a an estimate name value

        let i = this.getIndexFromKeyString(keyString)

        let x = this.getSecondPart(keyString)

        estimateArr[i]['estimates'].push({
          'estimateid': x+1
        })


      } else if (key.toString().includes('time')) {
        // is a an estimate time value

        let i = this.getIndexFromKeyString(keyString)

        let x = this.getSecondPart(keyString)

        estimateArr[i].estimates[x]['time'] = value

      } else if (key.toString().includes('highlighted')) {
        // is a an estimate time value

        let i = this.getIndexFromKeyString(keyString)

        estimateArr[i]['highlighted'] = value

      } else {
        // is an estimate
        let i = Number(keyString)
        let x = this.getSecondPart(keyString)

        if(!isNaN(i)) {
          estimateArr.push({
            'categoryid': Number(i)+1,
            'estimates': Array()
          })
        }
      }
    }

    for (let key of Object.keys(f.value)) {
      let keyString = key.toString()

      if (key.toString().includes('checked')) {
        // is a an estimate time value

        let i = Number(this.getIndexFromKeyString(keyString))

        for (let x = 0; x < this.contractorTimeEstimates[i].estimates.length; x++) {
          estimateArr[i].estimates[x]['attribute'] = this.contractorTimeEstimates[i].estimates[x]['attribute']
        }

      }
    }

    let estimateObject = {}

    for (let i = 0; i < estimateArr.length; i++) {
      const element = estimateArr[i];

      estimateObject[i] = element

    }

    let timeEstimateJson = JSON.stringify(estimateObject)

    this.plannerContractorService.updateContractorTimeEstimates(this.contractorId, timeEstimateJson)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(d => {
      this.timeEditor = false
    }).add(()=> {
      this.toastService.sendToast(true, this.translateService.instant('planner.contractorPage.toast.timeEstimatesUpdated'))
      this.getContractorInfo()
    })
  }

  /**
   * This is a very custom function constructs a 'time-estimate-json' object from form values and sends them to the backend.
   * @param f
   */
  saveChangesToMsaEstimates(f: NgForm) {

    let estimateArr = Array()

    this.addMsaActive = false

    for (let key of Object.keys(f.value)) {
      let value = f.value[key]
      let keyString = key.toString()

      if(key.toString().includes('name')) {
        // is a an estimate name value

        let i = Number(this.getIndexFromKeyString(keyString))

        let x = this.getSecondPart(keyString)

        estimateArr[i]['estimates'].push({
          'estimateid': x+1
        })


      } else if (key.toString().includes('time')) {
        // is a an estimate time value

        let i = Number(this.getIndexFromKeyString(keyString))

        let x = Number(this.getSecondPart(keyString))

        estimateArr[i].estimates[x]['time'] = value

      } else if (key.toString().includes('highlighted')) {
        //console.log(value)
        let i = Number(this.getIndexFromKeyString(keyString))

        estimateArr[i]['highlighted'] = value

      } else {
        // is an estimate
        let i = Number(keyString)

        if(!isNaN(i)) {
          estimateArr.push({
            'categoryid': Number(i)+1,
            'estimates': Array()
          })
        }
      }
    }

    for (let key of Object.keys(f.value)) {
      let keyString = key.toString()

      if (key.toString().includes('checked')) {

        let i = Number(this.getIndexFromKeyString(keyString))

        for (let x = 0; x < this.selectedMsa[i].estimates.length; x++) {
          estimateArr[i].estimates[x]['attribute'] = this.selectedMsa[i].estimates[x]['attribute']
        }

      }
    }

    let estimateObject = {}

    for (let i = 0; i < estimateArr.length; i++) {
      const element = estimateArr[i];

      estimateObject[i] = element
    }

    let timeEstimateJson = JSON.stringify(estimateObject)

    f.reset()

    this.workorderService.updateMsaTimes(timeEstimateJson, this.selectedMsaId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(d => {
      this.timeEditor = false
      this.msaEditor = false
    }).add(()=> {
      this.toastService.sendToast(true, this.translateService.instant('planner.contractorPage.toast.msaTimeEstimatesUpdated'))
      this.getContractorInfo()
    })
  }

  /**
   * This method returns the first numeric value in our key.
   * Example:
   *  Name for a key: 23-keyname-72
   *  Returns: 23
   *
   * @param keyString
   * @returns
   */
  getIndexFromKeyString(keyString) {

    keyString = keyString.substring(0, keyString.indexOf('-'))

    let keyNr = Number(keyString)

    return keyNr
  }

  /**
   * This method returns the second numeric value in our key.
   * Example:
   *  Name for a key: 23-keyname-72
   *  Returns: 72
   *
   * @param keyString
   * @returns
   */
  getSecondPart(keyString) {
    return Number(keyString.split('_')[1])
  }

  /**
   * Gets current contractor id and calls a callback method to tell that it has finished
   * @param cb
   */
  getCurrentContractorId(cb) {
    this.userService.getUserInfo()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      data => {
        this.contractorId = data.current_contractor
        this.userid = data.id

        // cb() = callback to let the other function know that this one is finished
        cb()
      }
    );
  }

  /**
   * Counts the number of orders in current contractor
   *
   * @edit 13.12.2022
   * Enabled use of getWorkorderCount to get all meters count.
   * Added sort for workorders to get ordered by SYSTEM ID
   * @author Jesse Lindholm
   */
  getWorkorders() {
    this.contractorOrderCount = 0

    // Get workorders count
    this.workorderService.getWorkorderCount(this.contractorId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      data => this.contractorOrderCount = data
    )

    // Get 50 workorders for display
    this.workorderService.getWorkorders('50', null)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe({
      next: workorders => {
        this.workorders = workorders
        this.workorders.sort((a, b) => a.id - b.id)
        this.importedLoading = false
      },
      error: () => {
        this.importedLoading = false
      }
    })
  }

  /**
   * Counts the number of contractors in current contractor
   */
  getContractorCount() {
    this.contractorProjectCount = 0
    this.workorderService.getProjects()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(data => {
      this.contractorProjectCount = data.length
    })
  }

  /**
   * Converts contractorTimeEstimates string into an object.
   * Then converts the object to an array of objects so that ngFor can loop through it.
   */
  timeEstimatesJSONStringToArray() {

    this.timeEstimatesObj = JSON.parse(this.contractorTimeEstimates)
    let mapped
    if (this.timeEstimatesObj) {
      mapped = Object.keys(this.timeEstimatesObj).map(key => (
        {
          categoryid: this.timeEstimatesObj[key]['categoryid'],
          highlighted: this.timeEstimatesObj[key]['highlighted'],
          estimates: this.timeEstimatesObj[key]['estimates']
        }
      ))
    }
    if (mapped) {
      this.contractorTimeEstimates = mapped
      this.namesToDefaultEstimates()
    } else this.estimatesLoading = false
  }

  /**
   * Fetches the msa-estimates for the contractor and builds usable variables from that.
   * Calls a few functions to build data for the UI after it finishes.
   */
  getMsaEstimates() {
    let msas = Array()
    let msaEstimates = Array()
    this.workorderService.getMsas()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      data => {
        if (data.length == 0) this.msaEstimatesLoading = false
        // Loop through data to set markers
        for (let i=0; i < data.length; i++) {
          try {

            let parameters = ""
            if(data[i].default_time_parameters) {
              parameters = JSON.parse(<string>data[i].default_time_parameters)
            }

            if(parameters) {
              // this.msaDefaults.push(JSON.parse(data[i].default_time_parameters!))
              this.msaDefaults.push(parameters)
            }

            msaEstimates[i] = {
              "id": data[i].id,
              // "estimates": JSON.parse(data[i].default_time_parameters!)
              "estimates": parameters
            }

            msas[i] = {
              "name": data[i].name,
              "id": data[i].id
            }



          } catch (error) {
            console.log(error)
            continue
          }
        }
        this.msaNames = msas
        this.contractorMsaEstimates = msaEstimates
        this.contractorMsaObject = msaEstimates
      }
    ).add(() => {
      this.namesToMsaEstimates()
      this.setSelectedMsa()
      this.msaEstimatesLoading = false
    })
  }

  /**
   * Calculates time for all workorders based on contractor default time estimates.
   *
   * @param defaultTimes
   * defaultTimes is the default_time_parameters of the contractor
   *
   * @param time_parameters
   * time_parameters is an array of work_order time parameters.
   */
  calculateTimeEstimates() {

    this.plannerContractorService.getContractorTime(this.contractorId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      data => {
        // Call to convert minutes to hours and minutes
        this.minutesToHoursAndMinutes(data)

    })
  }

  /**
   * Calculates minutes/hours from minutes.
   * @param time
   */
  minutesToHoursAndMinutes(time) {
    this.calculatedTime = this.workorderService.minutesToHoursAndMinutes(time)
  }

  /**
   * Loops through contractor default_time_parameters and all_estimates to link names to msa estimates.
   */
  namesToDefaultEstimates() {
    let allContractorEstim

    try {
      allContractorEstim = JSON.parse(this.allEstimates)
    } catch (error) {
      console.log(error)
    }

    for (let x = 0; x < this.contractorTimeEstimates.length; x++) {
      const projEstim = this.contractorTimeEstimates[x];
      // projEstim = yksi default estimaattityyppi

      if(this.contractorTimeEstimates[x].highlighted) {
        this.contractorTimeEstimates[x]['checked'] = true
      }
      if (allContractorEstim != null) {
        for (let i = 0; i < Object.keys(allContractorEstim).length; i++) {
          const allEstim = allContractorEstim[i];
          //allEstim = yksi all estimaattityyppi
          if (allEstim.categoryid == projEstim.categoryid){
            this.contractorTimeEstimates[x]['name'] = allEstim.type
          }
        }
      }
      for (let i = 0; i < projEstim.estimates.length; i++) {
        const defaultEstimate = projEstim.estimates[i];

        if (allContractorEstim != null) {
          for (let y = 0; y < Object.keys(allContractorEstim).length; y++) {
            const allEstim = allContractorEstim[y];
            //allEstim = yksi all estimaattityyppi
            for (let index = 0; index < allEstim.estimates.length; index++) {
              const oneAllEstimate = allEstim.estimates[index];

              if(oneAllEstimate.estimateid == defaultEstimate.estimateid && projEstim.categoryid == allEstim.categoryid) {
                this.contractorTimeEstimates[x].estimates[i]['name'] = oneAllEstimate.name
              }
            }
          }
        }
      }
    }
    this.estimatesLoading = false
  }

  /**
   * Almost same as namesToDefaultEstimates()
   * Loops through contractor msa:s and all_estimates to link names to msa estimates.
   */
  namesToMsaEstimates() {
    let allContractorEstim

    try {
      allContractorEstim = JSON.parse(this.allEstimates)
    } catch (error) {
      console.log(error)
    }

    for (let x = 0; x < this.contractorMsaEstimates.length; x++) {
      const projEstim = this.contractorMsaEstimates[x];
      // projEstim = yksi default estimaattityyppi

      if(projEstim.estimates) {

        for (let z = 0; z < Object.keys(projEstim.estimates).length; z++) {
          const element = projEstim.estimates[z];


          if(element.highlighted) {
            element['checked'] = true
          }

          for (let i = 0; i < Object.keys(allContractorEstim).length; i++) {
            const allEstim = allContractorEstim[i];
            //allEstim = yksi all estimaattityyppi
            if (allEstim.categoryid == element.categoryid){
              element['name'] = allEstim.type
            }
          }

          for (let i = 0; i < element.estimates.length; i++) {
            const defaultEstimate = element.estimates[i];

            for (let y = 0; y < Object.keys(allContractorEstim).length; y++) {
              const allEstim = allContractorEstim[y];
              //allEstim = yksi all estimaattityyppi
              for (let index = 0; index < allEstim.estimates.length; index++) {
                const oneAllEstimate = allEstim.estimates[index];

                if(oneAllEstimate.estimateid == defaultEstimate.estimateid && element.categoryid == allEstim.categoryid) {
                  element.estimates[i]['name'] = oneAllEstimate.name
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * This one gets msa id as parameter and builds an msa time-estimate object for that specific msa
   * The object 'selectedMsa' is used in rendering data to the UI
   * @param id
   */
  msaSelect(id) {

    this.selectedMsaId = Number(id)

    for (let index = 0; index < this.contractorMsaEstimates.length; index++) {
      if(this.contractorMsaEstimates[index].id == id && this.contractorMsaEstimates[index].estimates) {

        let mapped = Object.keys(this.contractorMsaEstimates[index].estimates).map(key => (
          {
            categoryid: this.contractorMsaEstimates[index]['estimates'][key].categoryid,
            checked: this.contractorMsaEstimates[index]['estimates'][key].checked,
            estimates: this.contractorMsaEstimates[index]['estimates'][key].estimates,
            highlighted: this.contractorMsaEstimates[index]['estimates'][key].highlighted,
            name: this.contractorMsaEstimates[index]['estimates'][key].name,
          }
        ))
        this.selectedMsa = mapped

        // Break the loop when the correct one has been found so that we dont override the variable with our else statement.
        break

      } else {
        // If selectedMsa is null, UI will say that there are no time estimates fot that specific MSA
        this.selectedMsa = null
      }
    }
  }

  /**
   * Builds the default selection msa time-estimate object
   */
  setSelectedMsa() {
    let index = 0

    if (this.contractorMsaEstimates[index]) {

      if(this.selectedMsaId) {

        for (let i = 0; i < this.contractorMsaEstimates.length; i++) {
          if(this.contractorMsaEstimates[i].id == this.selectedMsaId && this.contractorMsaEstimates[i].estimates) {

            let mapped = Object.keys(this.contractorMsaEstimates[i].estimates).map(key => (
              {
                categoryid: this.contractorMsaEstimates[i]['estimates'][key].categoryid,
                checked: this.contractorMsaEstimates[i]['estimates'][key].checked,
                estimates: this.contractorMsaEstimates[i]['estimates'][key].estimates,
                highlighted: this.contractorMsaEstimates[i]['estimates'][key].highlighted,
                name: this.contractorMsaEstimates[i]['estimates'][key].name,
              }
            ))
            this.selectedMsa = mapped

            // Break the loop when the correct one has been found so that we dont override the variable with our else statement.
            break

          } else {
            // If selectedMsa is null, UI will say that there are no time estimates fot that specific MSA
            this.selectedMsa = null
          }
        }

      } else if (this.contractorMsaEstimates[index].estimates && !this.selectedMsaId) {
        let mapped = Object.keys(this.contractorMsaEstimates[index].estimates).map(key => (
          {
            categoryid: this.contractorMsaEstimates[index]['estimates'][key].categoryid,
            checked: this.contractorMsaEstimates[index]['estimates'][key].checked,
            estimates: this.contractorMsaEstimates[index]['estimates'][key].estimates,
            highlighted: this.contractorMsaEstimates[index]['estimates'][key].highlighted,
            name: this.contractorMsaEstimates[index]['estimates'][key].name,
          }
        ))
        this.selectedMsa = mapped
        this.selectedMsaId = this.contractorMsaEstimates[0].id

      } else {
        // If selectedMsa is null, UI will say that there are no time estimates fot that specific MSA
        this.selectedMsa = null
      }
    } else {
      this.msaEstimatesLoading = false
    }
  }

  /**
   * Small function to parse allEstimates json string to create an object
   */
  createAllEstimatesObject() {

    try {
      this.allEstimatesObject = JSON.parse(this.allEstimates)
    } catch (error) {
      console.log(error)
    }

  }

  /**
   * Function that controlls if msa adding in edit view is active or not.
   * Default to show the first time-parameters in allEstimates
   */
  activateMsaAdding() {
    this.addMsaActive = true
    this.chosenEstimateObject = this.allEstimatesObject[0]

  }

  /**
   * Function that changes the chosen default estimate in msa settings, when adding a new msa.
   * @param value
   */
  setChosen(value) {
    this.chosenEstimateObject = this.allEstimatesObject[value]
  }

  /**
   * Called when the user confirms to add new time-parameters to selected msa.
   */
  addParameterToSelectedMsa(f: NgForm) {

    if (!f.pristine) {
      this.msaEstimatesLoading = true
      this.addMsaActive = false
      delete this.chosenEstimateObject.type

      for (let index = 0; index < this.chosenEstimateObject.estimates.length; index++) {
        delete this.chosenEstimateObject.estimates[index].name;
      }

      let msaArray = Array()

      for (let index = 0; index < this.contractorMsaObject.length; index++) {

        if(this.contractorMsaObject[index].estimates && this.contractorMsaObject[index].id == this.selectedMsaId) {

          for (let i = 0; i < this.contractorMsaObject[index].estimates.length; i++) {
            delete this.contractorMsaObject[index].estimates[i].name

            if(this.contractorMsaObject[index].estimates[i].checked) delete this.contractorMsaObject[index].estimates[i].checked

            msaArray.push(this.contractorMsaObject[index].estimates[i])
          }
        }
      }
      msaArray.push(this.chosenEstimateObject)

      // selectedMsaId is undefined if the user has not touched the MSA select.
      // The MSA select displays the first index of msaNames as default
      if(!this.selectedMsaId) {
        this.selectedMsaId = this.msaNames[0].id
      }

      this.workorderService.updateMsaTimes(JSON.stringify(msaArray), this.selectedMsaId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        () => {}
      )

      this.getContractorInfo()
    } else {
      this.toastService.sendToast(false, this.translateService.instant('planner.contractorPage.toast.noChanges'))
    }
  }

  /**
   * Cancels the msa adding mode.
   */
  cancelMsaEstimateAdding() {
    this.addMsaActive = false
  }

  /**
   * Function that removes a time-parameter from an MSA, it needs the index of the time-estimate and its name.
   * @param index
   * @param name
   */
  deleteMsaEstimate(index, name) {
    Swal.fire({
      title: this.translateService.instant('planner.contractorPage.toast.delete1') + name + this.translateService.instant('planner.contractorPage.toast.delete2') ,
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('basic.yes') ,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isDismissed) {
        // Do nothing
      } else if (result.isConfirmed) {

        this.msaEstimatesLoading = true

        let msaArray = JSON.parse(JSON.stringify(this.selectedMsa))

        for (let y = 0; y < msaArray.length; y++) {
          delete msaArray[y].name
          delete msaArray[y].checked

          for (let i = 0; i < msaArray[y].estimates.length; i++) {
            delete msaArray[y].estimates[i].name
          }
        }

        msaArray.splice(index, 1)

        msaArray.splice(0 , msaArray.lenght);
        this.workorderService.updateMsaTimes(JSON.stringify(msaArray), this.selectedMsaId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          () => {
            this.getContractorInfo()
          }
        )
      }
    })
  }


  /**
   * Sets the innerHtml of time estimate icon buttons
   * @param estimateObject
   * @param index
   * @returns
   */
  setElementType(estimateObject, index) {
    let highlighted = ''

    if (estimateObject[index]['highlighted']) {
      highlighted = estimateObject[index]['highlighted']
    }

    if (highlighted == 'color') {
      return '<img src="assets/icons/attributes/colors.svg">'
    } else if (highlighted == 'shape'){
      return '<img src="assets/icons/attributes/shapes.svg">'
    } else if (highlighted == 'dots'){
      return '<img src="assets/icons/attributes/dots.svg">'
    } else {
      return this.translateService.instant('basic.notSelected')
    }
  }

  /**
   * Sets element type based on time estimate icon button click
   * @param estimateObject
   * @param index
   * @param highlighted
   */
  setSelectedElementType(estimateObject, index, highlighted) {

    if (estimateObject[index]) {
      estimateObject[index]['highlighted'] = highlighted
    } else {
      estimateObject['highlighted'] = highlighted
    }
  }

  /**
   * Sets icons for time estimate dropdown options
   * @param estimateObject
   * @param firstIndex
   * @param secondIndex
   * @param attribute
   */
  setSelectedIconAttribute(estimateObject, firstIndex, secondIndex, attribute) {
    if (estimateObject[firstIndex]) {
      estimateObject[firstIndex].estimates[secondIndex]['attribute'] = attribute
    } else {
      estimateObject.estimates[secondIndex]['attribute'] = attribute
    }
  }


  /**
   * Sets the selected icon based on parameters (edit view)
   * @param estimateObject
   * @param firstIndex
   * @param secondIndex
   * @returns
   */
  setIcon(estimateObject, firstIndex, secondIndex) {

    let attribute = ''

    if (estimateObject[firstIndex]) {
      attribute = estimateObject[firstIndex].estimates[secondIndex]['attribute']
    } else {
      attribute = estimateObject.estimates[secondIndex]['attribute']
    }

    if (attribute == 'cross') {
      return '<img src="assets/select-icons/select_gray_cross.svg">'
    } else if (attribute == 'diamond'){
      return '<img src="assets/select-icons/select_gray_diamond.svg">'
    } else if (attribute == 'hexagon'){
      return '<img src="assets/select-icons/select_gray_hexagon.svg">'
    } else if (attribute == 'octagon'){
      return '<img src="assets/select-icons/select_gray_octagon.svg">'
    } else if (attribute == 'plumbob'){
      return '<img src="assets/select-icons/select_gray_plumbob.svg">'
    } else if (attribute == 'reverse-triangle'){
      return '<img src="assets/select-icons/select_gray_reverse_triangle.svg">'
    } else if (attribute == 'ball'){
      return '<img src="assets/select-icons/select_gray_ball.svg">'
    } else if (attribute == 'square-star'){
      return '<img src="assets/select-icons/select_gray_square_star.svg">'
    } else if (attribute == 'square'){
      return '<img src="assets/select-icons/select_gray_square.svg">'
    } else if (attribute == 'star'){
      return '<img src="assets/select-icons/select_gray_star.svg">'
    } else if (attribute == 'triangle'){
      return '<img src="assets/select-icons/select_gray_triangle.svg">'
    } else if (attribute == 'north-star'){
      return '<img src="assets/select-icons/select_gray_north_star.svg">'
    } else {
      return 'Select icon'
    }
  }

  /**
   * Sets the icons for time estimates (non edit view)
   * @param attribute
   * @returns
   */
  setIconByAttribute(attribute) {

    if (attribute == 'cross') {
      return '<img src="assets/select-icons/select_gray_cross.svg">'
    } else if (attribute == 'diamond'){
      return '<img src="assets/select-icons/select_gray_diamond.svg">'
    } else if (attribute == 'hexagon'){
      return '<img src="assets/select-icons/select_gray_hexagon.svg">'
    } else if (attribute == 'octagon'){
      return '<img src="assets/select-icons/select_gray_octagon.svg">'
    } else if (attribute == 'plumbob'){
      return '<img src="assets/select-icons/select_gray_plumbob.svg">'
    } else if (attribute == 'reverse-triangle'){
      return '<img src="assets/select-icons/select_gray_reverse_triangle.svg">'
    } else if (attribute == 'ball'){
      return '<img src="assets/select-icons/select_gray_ball.svg">'
    } else if (attribute == 'square-star'){
      return '<img src="assets/select-icons/select_gray_square_star.svg">'
    } else if (attribute == 'square'){
      return '<img src="assets/select-icons/select_gray_square.svg">'
    } else if (attribute == 'star'){
      return '<img src="assets/select-icons/select_gray_star.svg">'
    } else if (attribute == 'triangle'){
      return '<img src="assets/select-icons/select_gray_triangle.svg">'
    } else if (attribute == 'north-star'){
      return '<img src="assets/select-icons/select_gray_north_star.svg">'
    } else {
      return ''
    }
  }

  /**
   * Creates the mailing template and sends it to backend
   */
  saveMailingTemplate() {

    let mailObject = {}

    // If contractor doesn't have any mail templates in DB, we add the first iteration.
    if(!this.contractorMailTemplates) {
      mailObject[0] = {
        type: this.mailType,
        template: this.createMailText
      }
    } else {
      // If contractor has mail, we add all the existing mail to the object.
      // In the last iteration we add the newly created mail.
      let mailTemplates = JSON.parse(this.contractorMailTemplates)

      let mail = {
        type: this.mailType,
        template: this.createMailText
      }

      mailObject = Object.assign({mail}, mailTemplates)

    }

    let mailToUpload = JSON.stringify(mailObject)

    this.plannerContractorService.uploadMail(this.contractorId, mailToUpload)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(() => {
      this.contractorMailTemplates = mailToUpload
      this.setMailTemplates()
    })

    if(this.logoFile) {
      this.plannerContractorService.uploadMailImage(this.contractorId, this.logoFile)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {})
    }

  }

  saveLogo() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.addEventListener('change', (event) => {
        const files: FileList = (event.target as HTMLInputElement).files!;
        if (files.length > 0) {
          this.fileName = files[0].name;
          this.saveFiles(files);
        }
      });
      fileInput.click();
    }
  }
  /**
   * We go through contractor mail templates and replace existing mail text with updated ones
   * @returns
   */
  editMailTemplate() {

    let mailObject = JSON.parse(this.contractorMailTemplates)

    if(this.mailType == 'free' && !this.freeTextEdit) {
      Swal.fire(this.translateService.instant('planner.contractorPage.swal.emptyMail') )
      return
    } else if (this.mailType == 'scheduled' && !this.scheduledTextEdit) {
      Swal.fire(this.translateService.instant('planner.contractorPage.swal.emptyMail') )
      return
    }

    for (const key in mailObject) {
      if (Object.prototype.hasOwnProperty.call(mailObject, key)) {
        const element = mailObject[key]

        if(element.type == this.mailType) {
          if(this.mailType == 'free' && this.freeText) {
            mailObject[key] = {
              type: this.mailType,
              template: this.freeTextEdit
            }
          } else if (this.mailType == 'scheduled' && this.scheduledText) {
            mailObject[key] = {
              type: this.mailType,
              template: this.scheduledTextEdit
            }
          }
        }
      }
    }

    let mailToUpload = JSON.stringify(mailObject)

    this.plannerContractorService.uploadMail(this.contractorId, mailToUpload)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(() => {
      this.contractorMailTemplates = mailToUpload
      this.setMailTemplates()
    })

    if(this.logoFile) {
      this.plannerContractorService.uploadMailImage(this.contractorId, this.logoFile)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {})
    }

  }

  mailingPreviewSetup(boolean) {
    this.mailingPreview = boolean
  }

  /**
   * Sets the mailingtext according to the event that is sent. (From select or code)
   * @param event
   */
  setMailText(event) {
    if (event == 'free') {
      this.selectedText = this.freeText
    } else if (event == 'scheduled') {
      this.selectedText = this.scheduledText
    }
  }

  /**
   * Sets the mailingtext according to the event that is sent. (From select or code)
   * @param event
   */
  setModalText(event) {
    this.modalType = event
  }

  /**
   * Handles the hover function inside our mailing modals
   * @param boolean
   * @param target
   */
  hoverHandler(boolean, target = "") {
    if(target === 'info') {
      this.infoHover = boolean
    } else if(target === 'sms') {
      this.smsTooltipHover = boolean
    } else {
      this.variablesHover = boolean
    }
  }

  /**
   * Sets our two types of text variables according to JSON data from contractorMailTemplates
   */
  setMailTemplates() {

    let mailTemplates = JSON.parse(this.contractorMailTemplates)

    for (const key in mailTemplates) {
      if (Object.prototype.hasOwnProperty.call(mailTemplates, key)) {
        const element = mailTemplates[key];

        if (element.type == 'scheduled') {
          this.scheduledText = element.template
          this.scheduledTextEdit = element.template
        } else if (element.type == 'free') {
          this.freeText = element.template
          this.freeTextEdit = element.template
        }
      }
    }
    if (this.mailType === 'free') this.selectedText = this.freeText
    else this.selectedText = this.scheduledText
  }

  /**
   * 3.11.2022
   * Change limitValue, which controls how many workorders are shown in list.
   * @param newValue new value for limit
   * @author Jesse Lindholm
   */
  changeLimit(newValue: number) {
    if (newValue !== this.limitValue) this.limitValue = newValue
  }

  /**
   * 24.11.2022
   * User clicks unselected/selected barcode description and update is made to selectedBarcodes array
   * @param barcode Clicked barcode in UI
   * @author Jesse Lindholm
   *
   * @edit 25.11.2022
   * Added set value barcode.selected
   * @author Jesse Lindholm
   */
  selectBarcode(barcode: Barcode) {
    barcode.selected = !barcode.selected
    if (this.selectedBarcodes.length > 0) {
      const indexOf = this.selectedBarcodes.indexOf(barcode)
      // Index is -1 if not found
      if (indexOf === -1) this.selectedBarcodes.push(barcode)
      else this.selectedBarcodes.splice(indexOf, 1)
    } else this.selectedBarcodes.push(barcode)
  }

  /**
   * Set value to barcodeEdit variable
   * @param value boolean value that is set to barcodeEdit
   */
  setBarcodeEdit(value: boolean) {
    this.barcodeEdit = value
  }

  saveBarcodes() {
    let barcodesToApi: string[] = []
    this.selectedBarcodes.forEach(element => {
      barcodesToApi.push(element.code)
    });
    if (this.barcodesSet) this.plannerContractorService.updateContractorBarcodes(this.contractorId, barcodesToApi).subscribe()
    else this.plannerContractorService.createContractorBarcodes(this.contractorId, barcodesToApi).subscribe()
    this.setBarcodeEdit(false)
  }

  /**
   * Reset barcodes, clearing selectedBarcodes and resetting barcodes array selected values.
   * @author Jesse Lindholm
   */
  resetBarcodes() {
    this.selectedBarcodes = []
    // Reset barcodes selected value
    this.barcodes.forEach(element => {
      element.selected = false
    })
    this.setBarcodeEdit(false)
  }

  /**
   * Set value to smsEdit variable
   * @param value boolean value that is set to smsEdit
   */
  setSmsEdit(value: boolean) {
    this.smsEdit = value
  }

  saveSmss() {
    // let barcodesToApi: string[] = []
    // this.selectedBarcodes.forEach(element => {
    //   barcodesToApi.push(element.code)
    // });
    // if (this.barcodesSet) this.plannerContractorService.updateContractorBarcodes(this.contractorId, barcodesToApi).subscribe()
    // else this.plannerContractorService.createContractorBarcodes(this.contractorId, barcodesToApi).subscribe()

    this.smsSettings.sender = this.smsSender
    this.smsSettings.timezone = this.smsTimezone

    // jos uusi, tallenna
    let i = this.smsMessages.length + 1
    let now = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en')

    if(i <= 1) {
      if(this.smsContent != "") {
        this.smsMessages.push({
          'content': this.smsContent,
          'sendTiming': this.smsTiming,
          'sendTimingFiltered': this.smsTiming.replace(/[^\d]/g, ''),
          'sendAccessType': this.smsSendAccessType,
          'sendWhen': this.smsSendWhen,
          'created': now,
          'created_by': this.userid,
          'modified': '',
          'modified_by': '',
          'over1Message': false,
          'contentMessageLength': 0,
          'contentMaxLength': 0,
          'realLength': this.smsContentRealLength,
          'sendPhase': this.smsPhase,
          'personType': this.smsSendToWho
        })
      }
    }


    for (let msg of this.smsMessages) {
      msg.modified = now
      msg.modified_by = this.userid
      msg.sendTimingFiltered = msg.sendTiming.replace(/[^\d]/g, '')
    }

    // Currently sender is not required
    // sender is required
    // if(this.smsSender === "") {
    //   this.toastService.sendToast(false, this.translateService.instant('planner.contractorPage.toast.errNoSender'))
    // } else {
      this.plannerContractorService.updateContractorSmss(this.contractorId, this.smsMessages, this.smsSettings).subscribe()
      this.smsEdit = false
    // }

  }

  removeSms(i = -1) {
    Swal.fire({
      title: this.translateService.instant('planner.contractorPage.swal.deleteMessageConfirmation'),
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('basic.yes'),
    }).then((result) => {
      if (result.isConfirmed) {
        if(i >= 0) {
          this.smsMessages.splice(i, 1)
        } else {
          // tyhjennä uuden viestin laatikko
          this.smsContent = ''
          this.smsTiming = '4w'
        }
      }
    })
  }

  resetSmss() {
    this.smsEdit = false
  }
  newSms() {
    // Jos len on tyhjä, tallenna tyhjän lomakkeen data, liitä se ensin, ja sitten pushaa tyhjä
    let i = this.smsMessages.length + 1

    // myElement = angular.element( document.querySelector( '#newsms' ) );
    let now = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en')
    if(i <= 1) {

      this.smsMessages.push({
        'content': this.smsContent,
        'sendTiming': this.smsTiming,
        'sendTimingFiltered': this.smsTiming.replace(/[^\d]/g, ''),
        'sendAccessType': this.smsSendAccessType,
        'sendWhen': this.smsSendWhen,
        'created': now,
        'created_by': this.userid,
        'modified': '',
        'modified_by': '',
        'over1Message': this.over1Message,
        'contentMessageLength': this.smsContentMessageLength,
        'contentMaxLength': this.smsContentMaxLength,
        'realLength': this.smsContentRealLength,
        'sendPhase': this.smsPhase,
        'personType': this.smsSendToWho
      })
    }

    this.smsMessages.push({
      'content': ' ',
      'sendTiming': '1d',
      'sendTimingFiltered': '1',
      'sendAccessType': '1',
      'sendWhen': 'ifnotconfirmed',
      'created': now,
      'created_by': this.userid,
      'modified': '',
      'modified_by': '',
      'over1Message': false,
      'contentMessageLength': 160,
      'contentMaxLength': 918,
      'realLength': 0,
      'sendPhase': '1',
      'personType': '0'
    })

  }

  checkMaxLength(sms, event) {
    let regex = /^[@£$¥èéùìòÇØøÅå_^{}\[\]~\\|ÆæßÉ!"#¤%&'\(\)*+,\-\.\/0-9:;<=>\?A-ZÄÖÑÜ§¿a-zäöñüà ]*$/u
    let validGSM = regex.test(event)

    if(validGSM) {
      let doubleCharacters = /[\^{}\[\]~\\|€]/g
      let count = (event.match(doubleCharacters) || []).length
      sms.contentMaxLength = 918
      sms.contentMessageLength = 160
      sms.realLength = sms.content.length + count
    } else {
      sms.contentMaxLength = 402
      sms.contentMessageLength = 70
      sms.realLength = sms.content.length
    }

    if(event.length > sms.contentMessageLength) {
      sms.over1Message = true
    } else {
      sms.over1Message = false

    }
  }

  checkMaxLengthDefault(event) {
    let regex = /^[@£$¥èéùìòÇØøÅå_^{}\[\]~\\|ÆæßÉ!"#¤%&'\(\)*+,\-\.\/0-9:;<=>\?A-ZÄÖÑÜ§¿a-zäöñüà ]*$/u
    let validGSM = regex.test(event)

    if(validGSM) {
      let doubleCharacters = /[\^{}\[\]~\\|€]/g
      let count = (event.match(doubleCharacters) || []).length
      this.smsContentMaxLength = 918
      this.smsContentMessageLength = 160
      this.smsContentRealLength = this.smsContent.length + count

    } else {
      this.smsContentMaxLength = 402
      this.smsContentMessageLength = 70
      this.smsContentRealLength = this.smsContent.length
    }

    if(event.length > this.smsContentMessageLength) {
      this.over1Message = true
    } else {
      this.over1Message = false

    }
  }

  saveInfo() {
    this.plannerContractorService.updateContractorInformation(this.contractorId, this.contractorInfo)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.showContractorInfo = false
      })
  }

  findImages() {
    if (!this.startDate || !this.endDate || new Date(this.startDate) > new Date(this.endDate)) {
      this.toastService.sendToast(false, this.translateService.instant('planner.contractorPage.toast.notValidDates'))
      return;
    }
    this.results = null;
    this.plannerContractorService.findImages(this.startDate, this.endDate)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.results = data;
      })
  }

  downloadImages() {
    this.imagesLoading = true;
    if (!this.startDate || !this.endDate || new Date(this.startDate) > new Date(this.endDate)) {
      this.toastService.sendToast(false, this.translateService.instant('planner.contractorPage.toast.notValidDates'));
      return;
    }
    this.plannerContractorService.downloadImages(this.startDate, this.endDate).pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(({ url, filename }) => {
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = filename;
      document.body.appendChild(anchor); // Required for Firefox
      anchor.click();
      window.URL.revokeObjectURL(url); // Clean up
      document.body.removeChild(anchor);
      this.toastService.sendToast(true, this.translateService.instant('planner.contractorPage.toast.imagesDownloaded'));
      this.imagesLoading = false;
    });
  }

  changeEndingTimeDelay(endingTime) {
    this.plannerContractorService.changeEndingTimeContractor(endingTime, this.contractorId).subscribe(
      () => {
        this.endingTimeDelay = endingTime
        this.editToggleMailingFileOptions = false
      },
      error => {
        this.toastService.sendToast(false, 'Failed to update ending time delay for contractor. Error: ' + error.toString())
      }
    )


  }
}

