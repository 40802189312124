
<header>
    <div class="text-center">
        <h5>
            <div class="float-start ms-2"><img alt="Back button" (click)="backButtonPressed()" src="assets/icons/chevron_left_gray_24dp.svg" class="image-report"></div>
            <img alt="Edit pencil image" src="assets/icons/edit_blue_24dp.svg" class="image-report me-2 ms-negative-value">
            <span>{{'fieldReporting.danishHeatMeterReport.newMeterReport' | translate}}</span>
        </h5>
        </div>
    <nav class="d-flex">
        <div class="text-center gray-text" [ngClass]="{
            'blue-bottom': tab === 1,
            'default-bottom': tab !== 1,
            'col-4': basicReport,
            'col-6': !basicReport
          }">{{'planner.reports.phase.1' | translate}}</div>
        <div class="col-4 text-center gray-text" *ngIf="basicReport" [ngClass]="tab === 2 ? 'blue-bottom' : 'default-bottom'">{{'planner.reports.phase.2' | translate}}</div>
        <div class="col-4 text-center gray-text" *ngIf="basicReport" [ngClass]="tab === 3 ? 'blue-bottom' : 'default-bottom'">{{'planner.reports.phase.3' | translate}}</div>
        <div class="text-center gray-text" *ngIf="!basicReport" [ngClass]="{
            'blue-bottom': tab === 4,
            'default-bottom': tab !== 4,
            'col-4': basicReport,
            'col-6': !basicReport
          }">{{'planner.reports.phase.4' | translate}}</div>
    </nav>
</header>
<div class="main p-3">
    <div [hidden]="tab !== 1" class="bg-white p-3">
        <h5>{{'fieldReporting.report.planningHasBeenDone' | translate}}</h5>
        <div class="d-flex justify-content-between">
            <button type="button" class="btn btn-lg w-45" (click)="tabOnePartTwoSelectionId = 1; tabOneTextarea = ''; tabOnePartOneSelectionId = 0" [ngClass]="tabOnePartTwoSelectionId === 1 ? 'btn-primary' : 'btn-secondary'">{{'basic.yes' | translate}}</button>
            <button type="button" class="btn btn-lg w-45" (click)="tabOnePartTwoSelectionId = 2; tabOneTextarea = ''; tabOnePartOneSelectionId = 0" [ngClass]="tabOnePartTwoSelectionId === 2 ? 'btn-primary' : 'btn-secondary'">{{'basic.no' | translate}}</button>
        </div>
        <div [hidden]="tabOnePartTwoSelectionId === 0" class="mt-2">
            <h5 [hidden]="tabOnePartTwoSelectionId !== 1">{{'basic.additionalInformation' | translate}}</h5>
            <h5 [hidden]="tabOnePartTwoSelectionId !== 2">{{'basic.interruptReason' | translate}}</h5>
            <textarea class="form-control w-auto" [(ngModel)]="tabOneTextarea"></textarea>
            <h5 [hidden]="tabOnePartTwoSelectionId !== 2">{{'basic.interruptCode' | translate}}</h5>
            <select [hidden]="tabOnePartTwoSelectionId !== 2" name="data" id="interruptCode" class="form-select" [(ngModel)]="selectedInterruptCode">
                <option *ngFor="let code of interruptCodes" [value]="code.interrupt_code">
                    {{ code.definition }}
                </option>
            </select>
        </div>
        <div *ngIf="basicReport && tabOnePartTwoSelectionId === 1">
            <h5>{{'fieldReporting.report.installationWillBeDoneWithActivation' | translate}}</h5>
            <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-lg w-45" (click)="tabOnePartOneSelectionId = 1" [ngClass]="tabOnePartOneSelectionId === 1 ? 'btn-primary' : 'btn-secondary'">{{'basic.yes' | translate}}</button>
                <button type="button" class="btn btn-lg w-45" (click)="tabOnePartOneSelectionId = 2" [ngClass]="tabOnePartOneSelectionId === 2 ? 'btn-primary' : 'btn-secondary'">{{'basic.no' | translate}}</button>
            </div>
        </div>
    </div>
    <div [hidden]="tab !== 2" class="bg-white p-3">
        <h5>{{'fieldReporting.report.installationHasBeenDone' | translate}}</h5>
        <div class="d-flex justify-content-between">
            <button type="button" class="btn btn-lg w-45" (click)="tabTwoSelectionId = 1; tabTwoTextarea = ''" [ngClass]="tabTwoSelectionId === 1 ? 'btn-primary' : 'btn-secondary'">{{'basic.yes' | translate}}</button>
            <button type="button" class="btn btn-lg w-45" (click)="tabTwoSelectionId = 2; tabTwoTextarea = ''" [ngClass]="tabTwoSelectionId === 2 ? 'btn-primary' : 'btn-secondary'">{{'basic.no' | translate}}</button>
        </div>
        <div [hidden]="tabTwoSelectionId === 0" class="mt-2">
            <h5 [hidden]="tabTwoSelectionId !== 1">{{'basic.additionalInformation' | translate}}</h5>
            <h5 [hidden]="tabTwoSelectionId !== 2">{{'basic.interruptReason' | translate}}</h5>
            <textarea class="form-control w-auto" [(ngModel)]="tabTwoTextarea"></textarea>
            <h5 [hidden]="tabTwoSelectionId !== 2">{{'basic.interruptCode' | translate}}</h5>
            <select [hidden]="tabTwoSelectionId !== 2" name="data" id="interruptCode" class="form-select" [(ngModel)]="selectedInterruptCode">
                <option *ngFor="let code of interruptCodes" [value]="code.interrupt_code">
                    {{ code.definition }}
                </option>
            </select>
        </div>
    </div>
    <div [hidden]="tab !== 3" class="bg-white p-3">
        <h5>{{'fieldReporting.report.activationHasBeenDone' | translate}}</h5>
        <div class="d-flex justify-content-between">
            <button type="button" class="btn btn-lg w-45" (click)="tabThreeSelectionId = 1; tabThreeTextarea = ''" [ngClass]="tabThreeSelectionId === 1 ? 'btn-primary' : 'btn-secondary'">{{'basic.yes' | translate}}</button>
            <button type="button" class="btn btn-lg w-45" (click)="tabThreeSelectionId = 2; tabThreeTextarea = ''" [ngClass]="tabThreeSelectionId === 2 ? 'btn-primary' : 'btn-secondary'">{{'basic.no' | translate}}</button>
        </div>
        <div [hidden]="tabThreeSelectionId === 0" class="mt-2">
            <h5 [hidden]="tabThreeSelectionId !== 1">{{'basic.additionalInformation' | translate}}</h5>
            <h5 [hidden]="tabThreeSelectionId !== 2">{{'basic.interruptReason' | translate}}</h5>
            <textarea class="form-control w-auto" [(ngModel)]="tabThreeTextarea"></textarea>
            <h5 [hidden]="tabThreeSelectionId !== 2">{{'basic.interruptCode' | translate}}</h5>
            <select [hidden]="tabThreeSelectionId !== 2" name="data" id="interruptCode" class="form-select" [(ngModel)]="selectedInterruptCode">
                <option *ngFor="let code of interruptCodes" [value]="code.interrupt_code">
                    {{ code.definition }}
                </option>
            </select>
        </div>
    </div>
    <div [hidden]="tab !== 4" class="bg-white p-3">
        <h5>{{'fieldReporting.report.installationHasBeenDone' | translate}}</h5>
        <div class="d-flex justify-content-between">
            <button type="button" class="btn btn-lg w-45" (click)="tabTwoSelectionId = 1; tabFourTextareaPartOne = ''" [ngClass]="tabTwoSelectionId === 1 ? 'btn-primary' : 'btn-secondary'">{{'basic.yes' | translate}}</button>
            <button type="button" class="btn btn-lg w-45" (click)="tabTwoSelectionId = 2; tabFourTextareaPartOne = ''" [ngClass]="tabTwoSelectionId === 2 ? 'btn-primary' : 'btn-secondary'">{{'basic.no' | translate}}</button>
        </div>
        <div [hidden]="tabTwoSelectionId === 0" class="mt-2">
            <h5 *ngIf="tabTwoSelectionId === 1">{{'basic.additionalInformation' | translate}}</h5>
            <h5 *ngIf="tabTwoSelectionId === 2">{{'basic.interruptReason' | translate}}</h5>
            <textarea class="form-control w-auto" [(ngModel)]="tabFourTextareaPartOne"></textarea>
        </div>
        <h5>{{'fieldReporting.report.activationHasBeenDone' | translate}}</h5>
        <div class="d-flex justify-content-between">
            <button type="button" class="btn btn-lg w-45" (click)="tabThreeSelectionId = 1; tabFourTextareaPartTwo = ''" [ngClass]="tabThreeSelectionId === 1 ? 'btn-primary' : 'btn-secondary'">{{'basic.yes' | translate}}</button>
            <button type="button" class="btn btn-lg w-45" (click)="tabThreeSelectionId = 2; tabFourTextareaPartTwo = ''" [ngClass]="tabThreeSelectionId === 2 ? 'btn-primary' : 'btn-secondary'">{{'basic.no' | translate}}</button>
        </div>
        <div [hidden]="tabThreeSelectionId === 0" class="mt-2">
            <h5 *ngIf="tabThreeSelectionId === 1">{{'basic.additionalInformation' | translate}}</h5>
            <h5 *ngIf="tabThreeSelectionId === 2">{{'basic.interruptReason' | translate}}</h5>
            <textarea class="form-control w-auto" [(ngModel)]="tabFourTextareaPartTwo"></textarea>
            <h5 [hidden]="tabThreeSelectionId !== 2">{{'basic.interruptCode' | translate}}</h5>
            <select [hidden]="tabThreeSelectionId !== 2" name="data" id="interruptCode" class="form-select" [(ngModel)]="selectedInterruptCode">
                <option *ngFor="let code of interruptCodes" [value]="code.interrupt_code">
                    {{ code.definition }}
                </option>
            </select>
        </div>
    </div>
    <footer class="fixed-bottom">
        <div class="p-4 d-flex justify-content-between align-items-center">
            <!-- Left side -->
            <button type="button" class="btn btn-secondary" [hidden]="tab === 1" (click)="previousTab()">{{'basic.previous' | translate}}</button>
            
            <!-- Right side -->
            <div class="ms-auto">
                <div *ngIf="showNext()" class="align-end">
                    <button type="button" (click)="saveReport(false)" class="btn btn-secondary mb-1" [disabled]="nextDisabled()">{{'basic.next' | translate}}</button>
                    <button type="button" (click)="saveReport(true)" class="btn btn-primary" [disabled]="nextDisabled()">{{'basic.saveAndQuit' | translate}}</button>
                </div>
                <button type="button" (click)="saveReport(false)" class="btn btn-primary" [disabled]="saveDisabled()" *ngIf="showSave()">{{'basic.save' | translate}}</button>
            </div>
        </div>
    </footer>
</div>

