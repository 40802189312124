import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ngx-circle-custom',
  templateUrl: './ngx-circle-custom.component.html',
  styleUrls: ['./ngx-circle-custom.component.scss']
})
export class NgxCircleCustomComponent implements OnInit {

  constructor() { }

  @Input() efficiency: number = 0
  @Input() efficiencyTitle: string = ""
  @Input() color: string = ''

  colorInner: string = ""
  colorOuter: string = ""

  ngOnInit(): void {
    if (this.efficiencyTitle === "") this.efficiencyTitle = this.efficiency.toString()
    if (this.color === '') {
      if (this.efficiency >= 1) {
        this.colorOuter = "#78C000"
        this.colorInner = "#C7E596"
        this.efficiency = (this.efficiency - 1) * 100
      } else {
        this.colorOuter = '#FF0000'
        this.colorInner = '#ffcccb'
        this.efficiency = this.efficiency * 100
      }
    }else {
      if (this.color === 'green') {
        this.colorOuter = "#78C000"
        this.colorInner = "#C7E596"
      } else if (this.color === 'red') {
          this.colorOuter = '#FF0000'
          this.colorInner = '#ffcccb'
      }
      this.efficiency = this.efficiency * 100
    }
  
    
  }

}
