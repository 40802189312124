import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CallServiceService } from '@modules/call-service/services/call-service.service';
import { WorkorderService } from '@modules/planner/services/workorder/workorder.service';
import { PlannerContractorService } from '@modules/planner/services/planner-contractor/planner-contractor.service';
import { TranslateService } from '@ngx-translate/core';
import { Log } from '@shared/models/log';
import { Slot } from '@shared/models/slot';
import { Workorder } from '@shared/models/workorder';
import { of, Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { catchError, take } from "rxjs/operators";
import { CalendarsService } from '@modules/planner/services/calendars/calendars.service';
import { CallingLog } from '@shared/models/calling-log';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-report-workorder-detail',
  templateUrl: './report-workorder-detail.component.html',
  styleUrls: ['./report-workorder-detail.component.scss'],
  providers: [HttpClient]
})
export class ReportWorkorderDetailComponent implements OnInit, OnDestroy {


  // Global
  componentDestroyed$: Subject<boolean> = new Subject()
  spinner: boolean = true
  imageSpinner: boolean = true
  userLang

  // Contractor
  contractorType: any | undefined
  selectedImage = null

  // Status
  workorderStatus: any = {}

  // Workorder
  workorder: Workorder = {} as Workorder
  workorderImages: Array<any> = Array()
  workorderLogs: Array<Log> = Array()

  // Calendar
  currentSlot: Slot = {} as Slot
  calendarName: string = ""

  // Interrupted
  interrupted: boolean = false

  //Alternative timeslots content
  timeSlots: TimeSlot[] = [];
  originalTimeSlots: TimeSlot[] = [];
  isLoading: boolean = true;

  callLogs: CallingLog[] = []
  reports: any[] = []
  selectedTab: number = 1

  constructor(
    private plannerContractorService: PlannerContractorService,
    private router: Router,
    private workorderService: WorkorderService,
    private route: ActivatedRoute,
    private callService: CallServiceService,
    private translateService: TranslateService,
    private calendars: CalendarsService,
    private datePipe: DatePipe
  ) { }

  /**
   * Get workorder ID from URL and get information shown on page with it.
   * @author Jesse Lindholm
   */
  ngOnInit(): void {
    this.userLang = localStorage.getItem('userlanguage');
    this.route.params.subscribe((params: Params) => {
      if (this.isNumber(params.id)) {
        this.getCalendarByWorkorder(params['id']);
        this.getWorkorder(params['id']);
        this.getReports(params['id'])
      } else {
        Swal.fire(this.translateService.instant('planner.reportWorkorderDetail.noMeterIdFound'));
      }
    });
  }

  /**
   * Destroy components subscribe listeners, so they don't take up memory.
   * @author Jesse Lindholm
   */
  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  getReports(id) {
    this.workorderService.getReports(id).subscribe(
      data => {
        this.reports = data
        this.reports.forEach(r => {
          // Append 'Z' if not present to indicate UTC time
          const dateString = r.created.endsWith('Z') ? r.created : r.created + 'Z';
          const utcDate = new Date(dateString);
        
          // Transform the date to local time using DatePipe
          r.created = this.datePipe.transform(utcDate, 'dd.MM.yyyy HH:ss');
        });
      },
      error => console.log(error.toString())
    )
  }

  filterLogs(logs, tab) {
    return logs.filter(l => l.phase === tab)
  }

  /**
   * Get workorder logs, update description to match given message.
   * @author Jesse Lindholm
   */
  getLogs(workorderId, enduserIds) {
    this.workorderService.getWorkorderLogs(workorderId, enduserIds)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.type === 'workorder') {
              if (element.created) {
                const dateString = element.created.endsWith('Z') ? element.created : element.created + 'Z';
                const utcDate = new Date(dateString);
                
                // Transform the date to local time using DatePipe
                element.created = this.datePipe.transform(utcDate, 'dd.MM.yyyy HH:mm') || '';
              }


              if (element.description == 'msg_workorder_modified') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.modified')
              else if (element.description == 'msg_workorder_started_working') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.startedWorking')
              else if (element.description == 'msg_workorder_not_started') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.notStarted')
              else if (element.description == 'msg_workorder_moved_to_calendar') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.movedToCalendar')
              else if (element.description == 'msg_workorder_marked_done') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.done')
              else if (element.description == 'msg_workorder_marked_done_with_additional_work') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.doneWithAdditional')
              else if (element.description == 'msg_workorder_interrupted') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.interrupted')
              else if (element.description == 'msg_time_confirmed') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.msg_time_confirmed')
              else if (element.description == 'msg_time_confirmed_by_enduser') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.msg_time_confirmed_by_enduser')
              else if (element.description == 'msg_time_confirmed_by_enduser_with_modifications') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.msg_time_confirmed_by_enduser_with_modifications')
              else if (element.description == 'msg_time_confirmed_by_phoneservice_with_modifications') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.msg_time_confirmed_by_phoneservice_with_modifications')
              else if (element.description == 'msg_workorder_generated_coordinates') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.msg_workorder_generated_coordinates')
              else if (element.description == 'msg_workorder_forwarded') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.msg_workorder_forwarded')
              else if (element.description == 'msg_workorder_message_sent') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.msg_workorder_message_sent')
              else if (element.description == 'msg_workorder_mass_modified') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.interrupted')
              else if (element.description === 'msg_workorder_reported') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.reported')
              else if (element.description.startsWith('msg_workorder_status_updated, new id ')) {
                // msg_workorder_status_updated, new id 38
                let id = element.description.substring('msg_workorder_status_updated, new id '.length);
                this.plannerContractorService.getStatus(id)
                  .pipe(takeUntil(this.componentDestroyed$))
                  .subscribe(
                    data => {
                      let definition
                      let defjson = JSON.parse(data.definition) || {}
                      if (this.userLang) {
                        definition = defjson[this.userLang] ||
                          defjson['en'] ||
                          undefined;
                      } else {
                        definition = defjson['en'] || undefined;
                      }

                      element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.msg_workorder_status_updated') + definition
                    })
              }
              else element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.unknown')
            } else if (element.type === 'call') {
              element.user_name = element.firstname + " " + element.lastname
              if (element.answered === 0) element.description = this.translateService.instant('basic.didNotAnswer')
              else if (element.answered === 1) element.description = this.translateService.instant('basic.answered')
            }
          }
          this.workorderLogs = data
        }
      )
  }

  // Number check, return false if not number and true if number
  isNumber(string) {
    let isNotNumber = isNaN(+string)
    if (isNotNumber == true) return false
    else return true
  }

  /**
   * Navigate to dashboard
   * @author Jesse Lindholm
   */
  navigateToReports() {
    this.router.navigate(['planner/reports'])
  }

  /**
   * Get contractor info
   * @param contractorId contractors ID
   */
  getContractor(contractorId) {
    this.plannerContractorService.getContractorById(contractorId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.contractorType = data.contractor_type
        // After work order data is set, check contractor type and call initializeTimeSlots2 if applicable
        if (this.contractorType === 6) {
          this.initializeTimeSlots();
        }
      })
  }

  /**
   * Get status state
   * @param workorderId status ID
   */
  getStatus(workorderId) {
    this.plannerContractorService.getStatusByWorkorderId(workorderId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.workorderStatus = data
      })
  }

  /**
   * Basic get workorder api call to get information about workorder. Unique handling for
   * access type and status.
   * @param id workorders ID
   * @author Jesse Lindholm
   */
  getWorkorder(id): void {
    this.workorderService.getWorkorder(id)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          // Existing logic to handle data
          this.workorder = data;
          this.getContractor(this.workorder.contractor_id);
          this.getStatus(this.workorder.id);
          if (data.state == 6) this.interrupted = true;
          else this.interrupted = false;
          this.getWorkorderImages(id);
          this.spinner = false;
          let enduserIds: any = null
          if (data.enduser_id && data.enduser_id.length > 0) {
            enduserIds = data.enduser_id.map(i => i.id)
            enduserIds = enduserIds.toString()
          }
          this.getLogs(id, enduserIds);
        }
      );
  }

  /**
   * Get calendar and slot data from database with workorder id
   * @param workorderId workorders id
   * @author Jesse Lindholm
   */
  getCalendarByWorkorder(workorderId) {
    this.callService.getCalendarsByWorkorder(workorderId)
      .pipe(
        takeUntil(this.componentDestroyed$),
        catchError((error) => {
          if (error.status === 404) {
            // Handle 404 error: calendar not found
            // You could set some default state, navigate away, or show a message
            console.error('Calendar not found:', error);
            // For example, set the properties to default values
            this.currentSlot = {} as Slot
            this.calendarName = 'Calendar Not Found';
            // You can also return an Observable that emits a value, such as of({})
            return of({}); // Return an empty object to ensure the stream continues
          } else {
            // If it's some other kind of error, you might want to rethrow it
            // or handle it in another way
            // return throwError(error);
            return of({}); // Return an empty object to ensure the stream continues
          }
        })
      )
      .subscribe(
        data => {
          if (data) {
            this.currentSlot = data.calendar.slots[data.currentslot]
            this.calendarName = data.calendar.name
          }
        },
        error => {
          // console.log('getcalendarbyworkordererr', error)
        }
      )
  }

  /**
   * Get workorders images from database. Different handling for interrupt image.
   * @param workorderId workorders id
   * @author Jesse Lindholm
   */
  getWorkorderImages(workorderId) {
    this.workorderService.getWorkorderImages(workorderId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        data.forEach(image => {
          this.workorderService.fetchImage(image.id).subscribe(imageUrl => {
            if (imageUrl) {
              image.url = imageUrl;
              this.workorderImages.push(image);
            }
          });
        });
        this.imageSpinner = false
      });
  }

  initializeTimeSlots(): void {
    this.calendars.getAlternativeTimeslots(String(this.workorder.id)).pipe(
      take(1)
    ).subscribe({
      next: (data) => {
        // Initially populate originalTimeSlots with all alternatives
        this.originalTimeSlots = data.message.map(timeslot => ({
          slotId: timeslot.id,
          date: new Date(timeslot.starttime),
          time: `${timeslot.starttime.substr(11, 5)}-${timeslot.endtime.substr(11, 5)}`,
          selected: false
        }));

        // Exclude the current slot only from timeSlots for display and selection
        this.timeSlots = this.originalTimeSlots.filter(timeslot => timeslot.slotId !== this.currentSlot.id);
        this.isLoading = false;
      },
      error: (err) => {
        console.error('Failed to fetch alternative timeslots:', err);
      }
    });
  }


  // Helper method to format a Date object to a time string 'HH:MM'
  formatTime(date: Date): string {
    return date.toISOString().substring(11, 16);
  }


  selectSlot(index: number): void {
    // Toggle the selected state of the clicked slot and reset others
    this.timeSlots.forEach((slot, i) => {
      slot.selected = i === index ? !slot.selected : false;
    });
  }

  isAnySlotSelected(): boolean {
    return this.timeSlots.some(slot => slot.selected);
  }

  confirmSelection(): void {
    const selectedSlot = this.timeSlots.find(slot => slot.selected);
    if (selectedSlot) {
      const workorderId = this.workorder.id;
      let [startTime, endTime] = selectedSlot.time.split('-');
      const startDate = this.formatDate(selectedSlot.date, startTime);
      const endDate = this.formatDate(selectedSlot.date, endTime);

      // Deselect all slots
      this.originalTimeSlots.forEach(slot => slot.selected = false);
      // Explicitly select the current slot to reflect the latest user action
      selectedSlot.selected = true;

      // Update timeSlots to exclude the currently selected one and maintain others as unselected
      this.timeSlots = this.originalTimeSlots.filter(slot => slot.slotId !== selectedSlot.slotId);

      // Proceed with updating calendar slots and work order details as before
      this.calendars.updateCalendarSlots(selectedSlot.slotId, workorderId, "add").subscribe(() => {
        this.workorderService.updateWorkorderDetails(workorderId, 2, startDate, endDate).subscribe(() => {
          this.workorderService.updateWorkorderTimeConfirmation(workorderId, 1).subscribe(() => {
            this.getCalendarByWorkorder(workorderId);
            this.getLogs(workorderId, null);
          }, error => {
            console.error('Error confirming workorder time:', error);
          });
        }, error => {
          console.error('Error updating workorder details:', error);
        });
      });
    }
  }

  formatDate(date: Date, time: string): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${time}`;
  }
}

interface TimeSlot {
  slotId: Number,
  date: Date;
  time: string;
  selected: boolean;
}
