import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FieldReportingService} from '@modules/field-reporting/services/field-reporting/field-reporting.service';
import {WorkorderService} from '@modules/planner/services/workorder/workorder.service';
import {UserService} from '@shared/services/user/user.service';
import {Subject, takeUntil} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from "@shared/services/toast/toast.service";
import {Workorder} from "@shared/models/workorder";
import Swal from 'sweetalert2';
import { Status } from '@shared/models/status';
import { PlannerContractorService } from '@modules/planner/services/planner-contractor/planner-contractor.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private fieldReporting: FieldReportingService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private workorderService: WorkorderService,
    private plannerContractorService: PlannerContractorService
    ) { }

  userid: any;
  currentContractor: any;
  currentContractorId: number = 0
  contractors = Array();
  workerteams = Array();
  tab = 1;
  currentUser;
  workorderArray = Array()
  displayedWorkorders = Array()
  filteredWorkorders = Array()
  allOrders = Array()
  mustDoForTodayOrders = Array()
  doneOrders = Array()
  toDoCount = 0;
  doneCount = 0;
  freeLocationsCount = 0;
  tabTitle = ''
  topIcon = 0
  expandInventoryList = 'assets/icons/expand_more_gray_24dp.svg';
  showInventoryList = false;
  showDashboard = true;
  showSingleOrderDetail = false;
  workorderId;
  runGetWorkorder = false;
  typeArray = Array();
  lat: number | null = null
  lon: number | null = null
  sortChoice = 'default';
  customerId = 1;
  contractorType: number = 0
  loading: boolean = true

  queuedWorkorders: Number[] = [];
  searchField: string = ""
  searchedWorkorders: any[] = []
  selectedWorkorders: Workorder[] = []
  listSpinner: boolean = false
  searchActive: boolean = false
  longPressTimer: any
  isLongPress: boolean = false
  firstItem: boolean = false
  statuses: Status[] = []
  firstInterruptedStatus: Status | null = null
  allInterruptedStatuses: Status[] = []
  showWorklist: boolean = false
  currentWorkordersCount: number = 0

  ngOnInit(): void {
    this.getStatuses()
    this.sortChoice = 'default';
    this.getCurrentContractor();
    this.route.queryParams.subscribe(params => {
      if (params['queuedWorkorderId']) {
        const workorderId = Number(params['queuedWorkorderId'])
        this.queuedWorkorders.push(workorderId);
      }
    })

     // Retrieve the saved filter choice from localStorage
     const savedFilter = localStorage.getItem('selectedFilter');
     if (savedFilter) {
         this.changeFilter(savedFilter);
     }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  /**
   * Get user info and set contractors variable from it.
   * Set also workerteams correctly
   */
  getCurrentContractor() {
    this.userService.getUserInfo()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.userid = data.id;
        this.contractors = Array();
        this.currentContractorId = data.current_contractor
        this.contractorType = data.contractor_type
        this.customerId = data.customer_id;
        // Loop through contractors to add them to contractors array. If
        // contractor id matches current contractor number set its name to current contractor variable
        for (let i = 0; i < Object.keys(data.contractors).length; i++) {
          if (Object.keys(data.contractors)[i] == data.current_contractor) this.currentContractor = Object.values(data.contractors)[i]
          if (Object.values(data.contractors)[i] != this.currentContractor) this.contractors.push({ name: Object.values(data.contractors)[i], id: Object.keys(data.contractors)[i] })
        }
        // Loop through workers data and push workerteams to workerteams variable and allworkerteams variable
        // Workerteams variable is active variable and allworkerteams is for later usage for accessing all original workerteams
        data.workers.forEach(worker => {
          interface Workerteam {
            id: number;
            name: string;
            contractor_id: number;
          }
          const teamsArray: Workerteam[] = Object.values(worker.workerteams);
          teamsArray.forEach(workerteam => {
            if (workerteam.contractor_id === data.current_contractor) {
              this.workerteams.push(workerteam);
            }
          });
          this.workerteams.forEach(wt => wt.checked = true)
        });
        let json = localStorage.getItem('field-reporting-workerteams')
        if (json) {
          let workerteamsStorage = JSON.parse(json)
          if (workerteamsStorage.length > 0) {
            workerteamsStorage.forEach(element => {
              let foundWorkerteam = this.workerteams.find(wt => wt.id === element.id)
              if (foundWorkerteam) foundWorkerteam.checked = element.checked
            });
            this.getWorkerteamOrders()
          } else this.getWorkerteamOrders()
        } else this.getWorkerteamOrders()
        this.currentUser = data.id;
      }
      )
  }

  /**
   * Switch contractor by pressing another contractor in list from interface
   * @param contractorId correct contractor id to change to
   */
  switchContractor(contractorId) {
    this.userService.updateCurrentContractor(this.currentUser, contractorId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        () => window.location.reload()
      )
  }

  /**
   * Go through work orders that workerteam has and apply data accordingly
   */
  getWorkerteamOrders() {
    this.toDoCount = 0;
    this.doneCount = 0;
    this.freeLocationsCount = 0;

    this.displayedWorkorders = []
    this.changeFilter(this.sortChoice)
    this.fieldReporting.getFieldReporting(this.customerId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.topIcon = 0
        this.workorderArray = []
        this.mustDoForTodayOrders = []
        this.doneOrders = []
        if(this.contractorType == 2) {
          this.workerteams.forEach(wt => {
            wt.mainOrders = 0
            wt.extraOrders = 0
          })

          // Contractor type 2 sees all workorders, for all workerteams
          for (let i = 0; i < data.all.length; i++) {
            data.all[i].expand = false
            let element = data.all[i]
            if (element.workerteam_id && this.workerteams.length > 0) {
              let workerteam = this.workerteams.find(wt => wt.id === element.workerteam_id)
              if (workerteam) {
                if (element.time_window_start !== null) {
                  workerteam.mainOrders++;
                } else {
                  workerteam.extraOrders++
                }
              }
            }
            this.workorderArray.push(data.all[i])
          }
          for (let k = 0; k < data.today.length; k++) {
            data.today[k].expand = false
            this.mustDoForTodayOrders.push(data.today[k])
          }
          for (let x = 0; x < data.done.length; x++) {
            data.done[x].expand = false
            this.doneOrders.push(data.done[x])
            
          }
        } else {
          const checkedWorkerteamIds = this.workerteams.filter(wt => wt.checked).map(checked => checked.id)

          this.workerteams.forEach(wt => {
            wt.mainOrders = 0
            wt.extraOrders = 0
          })
          for (let i = 0; i < data.all.length; i++) {
            let element = data.all[i]
            if (element.workerteam_id && this.workerteams.length > 0) {
              let workerteam = this.workerteams.find(wt => wt.id === element.workerteam_id)
              if (workerteam) {
                if (element.time_window_start !== null) {
                  workerteam.mainOrders++;
                } else {
                  workerteam.extraOrders++
                }
              }
            }
            if (checkedWorkerteamIds.includes(data.all[i].workerteam_id) || data.all[i].reserver_id == this.userid) {
              this.workorderArray.push(data.all[i])
            }
          }
          for (let k = 0; k < data.today.length; k++) {
            if (checkedWorkerteamIds.includes(data.today[k].workerteam_id) || data.today[k].reserver_id == this.userid) {
              this.mustDoForTodayOrders.push(data.today[k])
            }
          }
          for (let x = 0; x < data.done.length; x++) {
            let element = data.done[x];
            
            if (checkedWorkerteamIds.includes(element.workerteam_id) || element.reserver_id == this.userid) {
              // Check if there's an element with the same id but a higher report_id
              const hasHigherReport = data.done.some(el => el.id === element.id && el.report_id > element.report_id);
              element.disabled = hasHigherReport ? true : false;
              this.doneOrders.push(element);
            }
          }
        }
        this.getLocation()
        if (this.tab === 1) this.displayedWorkorders = this.workorderArray
        else if (this.tab === 2) this.displayedWorkorders = this.mustDoForTodayOrders
        else if (this.tab === 3) this.displayedWorkorders = this.doneOrders
        let worklist = localStorage.getItem('worklist')
        if (worklist) this.setTabTitle(worklist)
        else this.setTabTitle('main')
        this.loading = false;
      })
  }

  /**
   * Change tab so that we display correct information in interface
   */
  tabChanger(tab) {
    this.topIcon = 0
    this.tab = tab
    if (tab == 1) {
      this.displayedWorkorders = this.workorderArray
    } else if (tab == 2) {
      this.displayedWorkorders = this.mustDoForTodayOrders
    } else if (tab == 3) {
      this.displayedWorkorders = this.doneOrders
    }
    this.changeFilter(this.sortChoice)
    let worklist = localStorage.getItem('worklist')
    if (worklist) this.setTabTitle(worklist)
    else this.setTabTitle('main')
  }

  /**
   * Change active icon to correct one and show correct list of workorders. Disable also previous icon from active state.
   * @param icon clicked icon
   */
  topIconSwitch(icon) {
    if (icon == this.topIcon) {
      this.topIcon = 0
      if (this.tab == 1) {
        this.displayedWorkorders = this.workorderArray;
      } else if (this.tab == 2) {
        this.displayedWorkorders = this.mustDoForTodayOrders
      } else if (this.tab == 3) {
        this.displayedWorkorders = this.doneOrders
      }
    } else {
      this.topIcon = icon
    }


    // TODO check if this can be simplified
    // Logic for tab one
    if (this.tab == 1) {
      if (this.topIcon == 3) { // openAccess
        this.displayedWorkorders = this.workorderArray.filter((el) => {
          return el.access_type == 1
        });
      } else if (this.topIcon == 1) { // keyNeeded
        this.displayedWorkorders = this.workorderArray.filter((el) => {
          return el.access_type == 3
        });
      } else if (this.topIcon == 2) { // notOpen
        this.displayedWorkorders = this.workorderArray.filter((el) => {
          return el.access_type == 2
        });
      }
    }
    // Logic for tab two
    else if (this.tab == 2) {
      if (this.topIcon == 3) { // openAccess
        this.displayedWorkorders = this.mustDoForTodayOrders.filter((el) => {
          return el.access_type == 1
        });
      } else if (this.topIcon == 1) { // keyNeeded
        this.displayedWorkorders = this.mustDoForTodayOrders.filter((el) => {
          return el.access_type == 3
        });
      } else if (this.topIcon == 2) { // notOpen
        this.displayedWorkorders = this.mustDoForTodayOrders.filter((el) => {
          return el.access_type == 2
        });
      }
    }
    // Logic for tab three
    else if (this.tab == 3) {
      if (this.topIcon == 3) { // openAccess
        this.displayedWorkorders = this.doneOrders.filter((el) => {
          return el.access_type == 1
        });
      } else if (this.topIcon == 1) { // keyNeeded
        this.displayedWorkorders = this.doneOrders.filter((el) => {
          return el.access_type == 3
        });
      } else if (this.topIcon == 2) { // notOpen
        this.displayedWorkorders = this.doneOrders.filter((el) => {
          return el.access_type == 2
        });
      }
    }
    this.changeFilter(this.sortChoice)

  }

  

  toSingleWorkorder(workorder) {
    let id = workorder.id
    if (!this.isLongPress) {
      this.workorderId = id;
      this.showDashboard = false;
      this.showSingleOrderDetail = true;
      this.runGetWorkorder = true;
    } else {
      if(!this.firstItem) {
        this.addSelectedWorkorder(workorder)
      } else {
        this.firstItem = false
      }
    }

  }


  /**
   * Sets attributes dynamically to color and borderm-bottom
   * @param tabId tabId which is 1-3
   * @returns correct color and border-botto attribute for tab
   */
  styleObject(tabId): Object {
    if (this.tab == tabId) return { 'color': '#0058ff', 'border-bottom': '#0058ff 4px solid' }
    else return { 'color': 'gray', 'border-bottom': '0px solid white' }
  }

  /**
   * Switches to correct workerteam, removes correct workerteam from list and runs getWorkerteamOrders function
   * @param id what workerteam we want to switch to
   * 
   * @edit 10.9.2024
   * Revamped function to work with new approach to workerteams and checked value. Supports now multiselection.
   */
  toggleWorkerteam(item) {
    // Contractor type 2 sees all workorders, for all workerteams, so for them we disable this filter
    if(this.contractorType != 2) {
      this.loading = true
      if (item.checked) item.checked = false
      else item.checked = true
      this.saveWorkerteamsLocalStorage()
      this.getWorkerteamOrders()
    }
  }

  /**
   * Save workerteams to local storage
   */
  saveWorkerteamsLocalStorage() {
    const combined = this.workerteams.map(team => ({
      id: team.id,
      checked: team.checked
    }));
    localStorage.setItem('field-reporting-workerteams', JSON.stringify(combined));
  }

  toggleInventoryList() {
    if (!this.showInventoryList) {
      this.showInventoryList = true;
      this.expandInventoryList = 'assets/icons/expand_less_gray_24dp.svg'
    } else {
      this.showInventoryList = false;
      this.expandInventoryList = 'assets/icons/expand_more_gray_24dp.svg'
    }
  }

  setInformation(workorderArray) {
    // Apply time window end logic which is when does the work order end
    if (workorderArray.time_window_end != null) {
      // Split time window end in 2, date and time
      let splitTimeWindowEnd = workorderArray.time_window_end.split(" ")
      // Split time second time
      let splittedSecondTimeEnd = splitTimeWindowEnd[1].split(":");
      // Format time to correct time view
      workorderArray.end = splittedSecondTimeEnd[0] + ":" + splittedSecondTimeEnd[1]
      // Apply logic to date
      let date = new Date(splitTimeWindowEnd[0])
      workorderArray.day = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
    }
    let workorderDate;
    // Apply time window start logic. Time window start is when work order is started
    if (workorderArray.time_window_start != null) {
      // Split it to time and date
      let splitTimeWindowStart = workorderArray.time_window_start.split(" ")
      // Split time second time
      let splittedSecondTimeStart = splitTimeWindowStart[1].split(":");
      // Format time
      workorderArray.start = splittedSecondTimeStart[0] + ":" + splittedSecondTimeStart[1]
      // Make date a date
      let date = new Date(splitTimeWindowStart[0])
      workorderDate = date;
      // Save date to variable
      workorderArray.day = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
    }

    // Set up tools for work order
    if (workorderArray.tools != null) {
      // Configure tools to correct JSON format
      let tempToolsOriginal = workorderArray.tools.replaceAll("'", '"').substring(1, (workorderArray.tools.length - 1))
      // Set toolsArray to use in HTML
      workorderArray.toolsArray = JSON.parse("[" + tempToolsOriginal + "]")
    }
    let today = new Date()
    if (workorderDate != null) {
      // Compare dates to today's date
      if ((workorderDate.getFullYear() + "-" + workorderDate.getMonth() + "-" + workorderDate.getDate() == today.getFullYear() + "-" + today.getMonth() + "-" + today.getDate())) {
        // Push to inventory list work order list
        this.workorderArray[2].push(workorderArray)
        // If has tools run this
        if (workorderArray.toolsArray) {
          for (let i = 0; i < workorderArray.toolsArray.length; i++) {
            let doesExist = false;
            for (let z = 0; z < this.typeArray.length; z++) {
              // Check if tool exists in typeArray and add to count if does
              if (this.typeArray[z].name == workorderArray.toolsArray[i].name) {
                this.typeArray[z].count++
                doesExist = true
              }
            }
            // If tool does not exist add to typeArray
            if (!doesExist) {
              this.typeArray.push({ "name": workorderArray.toolsArray[i].name, "count": 1 })
            }
          }
        }
        if (workorderArray.model) {
          // Configure meters to correct JSON format
          let replaced = workorderArray.model.replaceAll("'", '"')
          let model = JSON.parse(replaced)
          let doesExist = false;
          // Run through typeArray array to check if meter exists
          for (let x = 0; x < this.typeArray.length; x++) {
            // Check if meter exists in typeArray and add to count if does
            if (this.typeArray[x].name == model.name) {
              this.typeArray[x].count++
              doesExist = true
            }
          }
          // If meter does not exist in typeArray push it there
          if (!doesExist) this.typeArray.push({ "name": model.name, "count": 1 })
        }
      }
    }
  }
  /**
   * Toggle dashboard on and hide single order detail. Also change runGetWorkorder to false so
   * that it can be changed to true when needs to be run in single order detail component. This function is
   * called when single order detail is closed
   */
  showDashboardToggle() {
    this.showDashboard = true;
    this.showSingleOrderDetail = false;
    this.runGetWorkorder = false;
    this.displayedWorkorders.forEach(element => {
      element.expand = false
    });
  }

  


  changeFilter(value) {
    this.sortChoice = value
    localStorage.setItem('selectedFilter', value); // Save the filter choice

    if (value == 'address') {
      this.displayedWorkorders.sort((a, b) => (a.address > b.address) ? 1 : ((b.address > a.address) ? -1 : 0))
    } else if (value == 'contactperson') {
      this.displayedWorkorders.sort((a, b) => (a.endusers[0]?.contact_person_first > b.endusers[0]?.contact_person_first) ? 1 : ((b.endusers[0]?.contact_person_last > a.endusers[0]?.contact_person_last) ? -1 : 0))
      this.displayedWorkorders.sort((a, b) => (a.endusers[0]?.contact_person_last > b.endusers[0]?.contact_person_last) ? 1 : ((b.endusers[0]?.contact_person_last > a.endusers[0]?.contact_person_last) ? -1 : 0))
    } else if (value == 'starttime') {
      this.displayedWorkorders.sort((a, b) => (a.time_window_start > b.time_window_start) ? 1 : ((b.time_window_start > a.time_window_start) ? -1 : 0))
    } else if (value == 'distance') {
      this.displayedWorkorders.sort((a, b) => (a.distance > b.distance) ? 1 : ((b.distance > a.distance) ? -1 : 0))
    } else {
      this.displayedWorkorders.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
    }
  }

  getLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lon = position.coords.longitude;
        if (this.lat && this.lon) {
          // todo laske etäisyys mittareista

          for (let i = 0; i < this.displayedWorkorders.length; i++) {
            if (this.displayedWorkorders[i].coordinates) {
              try {
                let wLat = JSON.parse(this.displayedWorkorders[i].coordinates!).lat
                let wLon = JSON.parse(this.displayedWorkorders[i].coordinates!).lon

                let distance = this.calcDistance(this.lat, this.lon, wLat, wLon)
                this.displayedWorkorders[i].distance = distance
              } catch (error) {
                //console.log(error)

              }
            }
          }
        }
      });
    } else {
      console.log("No support for geolocation")
    }
  }

  //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies
  calcDistance(lat1, lon1, lat2, lon2) {
    let Radius = 6371; // km
    let dLat = this.toRad(lat2 - lat1);
    let dLon = this.toRad(lon2 - lon1);
    lat1 = this.toRad(lat1);
    lat2 = this.toRad(lat2);
    let returnVal = ""

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = Radius * c; // km

    return d
  }

  // Converts numeric degrees to radians
  toRad(Value) {
    return Value * Math.PI / 180;
  }

  filterOutQueued(workorders) {
    const ret = workorders.filter(wo => !this.queuedWorkorders.includes(wo.id));
    return ret;
  }

  search() {
    if(this.searchField == "") {
      this.toastService.sendToast(false, this.translateService.instant('fieldReporting.dashboard.nothingToSearch'))
    } else {
      this.listSpinner = true
      this.searchedWorkorders = []
      this.selectedWorkorders = []
      this.isLongPress = false;
      this.firstItem = false;
      this.workorderService.searchWorkorders(
        this.searchField,
        'multisearch'
      ).pipe(takeUntil(this.componentDestroyed$))
        .subscribe({
          next: data => {
            this.searchedWorkorders = data
            this.searchedWorkorders.forEach(element => {
              element.expand = false
            });
            this.listSpinner = false
          },
          // Error handling, show error message in HTML after stopping spinner
          error: () => {
            this.toastService.sendToast(false, this.translateService.instant('planners.reports.noWorkordersFound'))
            this.listSpinner = false
          }
        })
    }
  }

  onMouseDown(workorder): void {
    if (!this.isLongPress) {
      this.longPressTimer = setTimeout(() => {
        this.isLongPress = true;
        if(this.selectedWorkorders.length == 0) {
          this.firstItem = true;
        }
        this.addSelectedWorkorder(workorder);
      }, 500); // 500 ms for long press
    }
  }

  onMouseUp(): void {
    clearTimeout(this.longPressTimer);
    // this.isLongPress = false;
  }

  addSelectedWorkorder(workorder): void {
    const index = this.selectedWorkorders.indexOf(workorder);
    if (index > -1) {
      this.selectedWorkorders.splice(index, 1); // Remove from selected if already there
    } else {
      this.selectedWorkorders.push(workorder); // Add to selected if not already there
    }

    if(this.selectedWorkorders.length == 0) {
      this.isLongPress = false;
      this.firstItem = false;
    }
  }

  interruptSelected() {
    if(this.selectedWorkorders.length > 0) {
      if (this.firstInterruptedStatus) {
        let workorders = this.selectedWorkorders.filter(w =>
          !this.allInterruptedStatuses.some(a => a.id === w.status_id)
        ).map(wo => wo.id)

        this.selectedWorkorders.forEach(element => {
          if (this.firstInterruptedStatus) element.status_id = this.firstInterruptedStatus.id
        });
        if (workorders.length > 0) {
          this.fieldReporting.patchWorkorders(workorders, this.firstInterruptedStatus.id)
          .subscribe(() => {
            this.selectedWorkorders = []
            this.search()
          })
        } else Swal.fire(this.translateService.instant('fieldReporting.dashboard.massInterruptError'))
      }
    }
  }

  getStatuses() {
    this.plannerContractorService.getStatuses()
    .subscribe(data => {
      this.statuses = data
      let status = this.statuses.find(s => s.state === 4)
      if (status) this.firstInterruptedStatus = status
      this.allInterruptedStatuses = []
      this.statuses.forEach(element => {
        if (element.state === 4) this.allInterruptedStatuses.push(element)
      });
    })
  }


  setTabTitle(message: string) {
    let userlang = localStorage.getItem('userlanguage')
    if (userlang) this.translateService.currentLang = userlang
    if (message === 'extra') {
      this.translateService.get('basic.extraWorklist').subscribe({
        next: translation => this.tabTitle = translation
      })
      this.showWorklist = true
      this.currentWorkordersCount = this.displayedWorkorders.filter(dwo => dwo.time_window_start === null).length
      localStorage.setItem('worklist', 'extra')
    }
    else {{
      this.translateService.get('basic.mainWorklist').subscribe({
        next: translation => {
          this.tabTitle = translation
        }
      })
      this.showWorklist = false
      this.currentWorkordersCount = this.displayedWorkorders.filter(dwo => dwo.time_window_start !== null).length
      localStorage.setItem('worklist', 'main')
    }}
  }

  filterWorkerteams(workerteams) {
    return workerteams.filter(wt => wt.checked)
  }

}
