
  


import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

    constructor(private toastService: ToastService) {}
    /** 
      * Handles Http operations that failed.
      * Lets the app continue.
      * @param operation - name of the operation that failed
      * @param result - optional value to return as the observable result
      */
    public handleError<T>(operation = 'operation', result?: T) {
       return (error: any): Observable<never> => {
         this.toastService.sendToast(false, error.message);
         // Throw an error to propagate it to the component's error handler
         return throwError(() => new Error(error.message));
       };
    }
}