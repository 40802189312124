import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '@core/auth/auth.module';
import { LoginComponent } from '@core/auth/components/login/login.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PlannerModule } from '@modules/planner/planner.module'
import { AppRoutingModule } from '@app/app-routing.module';
import { SharedModule } from '@shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { FieldReportingModule } from '@modules/field-reporting/field-reporting.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { NgxSliderModule } from 'ngx-slider-v2';
import { NgChartsModule } from 'ng2-charts';
import { CallServiceModule } from '@modules/call-service/call-service.module';
import { EndUserModule } from '@modules/end-user/end-user.module';
import { EndUserComponent } from './layout/end-user/end-user/end-user.component';
import { UserInfoResolver } from '@shared/services/user/user.info.resolver';
import { ProjectLeadComponent } from './layout/project-lead/project-lead.component';
import { ProjectLeadModule } from '@modules/project-lead/project-lead.module';

@NgModule({
  declarations: [
    AppComponent,
    EndUserComponent,
    ProjectLeadComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule,
    PlannerModule,
    FieldReportingModule,
    AppRoutingModule,
    LeafletModule,
    SharedModule,
    BrowserAnimationsModule, // required animations module
    NgxSliderModule,

    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
    RouterModule.forRoot([
      {path: 'login', component: LoginComponent},
    ]),
    SweetAlert2Module.forRoot(),
    NgbModule,
    NgChartsModule,
    CallServiceModule,
    EndUserModule,
    ProjectLeadModule
  ],
  exports: [],
  providers: [DatePipe, UserInfoResolver],
  bootstrap: [ AppComponent ]
})
export class AppModule { }

// required for Ahead Of Time compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
