<div class="page-container">
    <div class="page-heading">
        <h3 class="bold-text black-text"><img src="assets/icons/person_black_24dp.svg"><span class="ms-2">{{'sideNavigation.titleWorkers' | translate}}</span></h3>
    </div>
    <div class="page-content">
        <div class="card">
            <div class="card-body">
                <div class="margin-left">
                    <button class="btn btn-secondary" (click)="toWorkersPage()">{{'basic.back' | translate}}</button>
                    <form ngNativeValidate (ngSubmit)="saveEdit(1)">
                    <div class="row margin-top">

                        <div class="col-5">
                            <div class="large bold-text row">
                                <div>
                                    <span id="worker-name">{{name}}</span>
                                    <button class="btn btn-secondary float-end me-3" (click)="toggleEdit(1)" *ngIf="!editMode1"><img src="assets/icons/edit_gray_24dp.svg" class="image-edit"><span class="ms-1">{{'basic.edit' | translate}}</span></button>
                                </div>
                            </div>
                            <div class="row small-text mt-5 mb-3">
                                <div class="col-4">
                                    <div class="gray-text">{{'basic.email' | translate}}</div>
                                    <div *ngIf="editMode1 == false">
                                        <div class="bold-text">{{email}}</div>
                                        <div class="bold-text" *ngIf="email == null">{{'basic.noEmail' | translate}}</div>
                                    </div>
                                    <div class="form-group" *ngIf="editMode1 == true">
                                        <input type="email" id="emailId" class="form-control max-content" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" required>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="gray-text">{{'basic.phoneNumber' | translate}}</div>
                                    <div *ngIf="editMode1 == false">
                                        <div class="bold-text">{{phone}}</div>
                                        <div class="bold-text" *ngIf="phone == null">{{'planner.workerDetail.noPhoneNumber' | translate}}</div>
                                    </div>
                                    <div class="form-group" *ngIf="editMode1 == true">
                                        <input type="text" class="form-control max-content" [(ngModel)]="phone" [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="gray-text">{{'basic.interfaceLanguage' | translate}}</div>
                                    <div *ngIf="editMode1 === false" class="bold-text">
                                        <div>{{workerLanguage.displayName}}</div>
                                        <div *ngIf="!workerLanguage.displayName">{{'basic.noLanguage' | translate}}</div>
                                    </div>
                                    <div [hidden]="!editMode1">
                                        <select class="form-select" (change)="languageChange($event.target.value)">
                                            <option *ngIf="!workerLanguage.displayName" disabled [selected]="!workerLanguage.displayName">{{'basic.noLanguage' | translate}}</option>
                                            <option *ngFor="let language of interfaceLanguages" [selected]="workerLanguage.databaseLanguage === language.databaseLanguage" [value]="language.databaseLanguage">{{language.displayName}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="editMode1">
                                <div class="col-12 mb-2">
                                    <button type="submit" class="btn btn-primary">{{'basic.save' | translate}}</button>
                                    <button type="button" class="btn btn-secondary ms-2" (click)="cancelEdit(1)">{{'basic.cancel' | translate}}</button>
                                </div>
                            </div>
                            <div class="border-bottom-96 mt-5"></div>
                        </div>
                        <div class="col-7 small-text border-start border-gray">
                            <div class="ms-4">
                                <div class="gray-text">
                                    <span>{{'planner.workerDetail.workersEfficiency' | translate}}</span>
                                    <button class="btn btn-secondary float-end" type="button" (click)="toggleEdit(2)" *ngIf="!editMode2"><img src="assets/icons/edit_gray_24dp.svg" class="image-edit"><span class="ms-1">{{'basic.edit' | translate}}</span></button>
                                </div>
                                <div class="gray-text"></div>
                                <div *ngIf="editMode2 === false">
                                    <circle-progress
                                        [percent]="efficiency"
                                        [radius]="18"
                                        [outerStrokeWidth]="4"
                                        [innerStrokeWidth]="4"
                                        [space]="-4"
                                        [outerStrokeColor]="colorOuter"
                                        [innerStrokeColor]="colorInner"
                                        [animation]="true"
                                        [animationDuration]="300"
                                        [titleFontSize]="'13'"
                                        [title]="efficiencyValue"
                                        [showUnits]=false
                                        [titleColor]="colorOuter"
                                        [titleFontWeight]="'600'"
                                    ></circle-progress>
                                </div>
                                <div class="form-group" *ngIf="editMode2 === true">
                                    <input type="number" min="0.5" max="2.0" step="0.01" class="form-control max-content" [(ngModel)]="efficiencyValue" [ngModelOptions]="{standalone: true}">
                                </div>
                                <div class="mt-5">
                                    <div class="gray-text">{{'planner.workerDetail.belongsToTeams' | translate}}</div>
                                    <div *ngFor="let team of teams; let i = index" class="item bold-text">
                                        <div>
                                            <span>{{team}}</span>
                                            <span *ngIf="teams.length - 1 > i">,</span>
                                        </div>
                                    </div>
                                    <div *ngIf="teams == null" class="bold-text">{{'basic.noTeams' | translate}}</div>
                                </div>
                                <div class="mt-4">
                                    <div class="gray-text">{{'planner.workerDetail.employmentRelationship' | translate}}</div>
                                    <div *ngIf="editMode2 == false">
                                        <div>
                                            <span class="bold-text" *ngIf="employmentRelationshipStart">{{employmentRelationshipStart | date: 'dd.MM.yyyy'}}</span>
                                            <span class="bold-text" *ngIf="!employmentRelationshipStart">{{'basic.start' | translate}}</span>
                                            <span class="bold-text"> - </span>
                                            <span class="bold-text" *ngIf="!employmentRelationshipEnd">{{'basic.end' | translate}}</span>
                                            <span class="bold-text" *ngIf="employmentRelationshipEnd">{{employmentRelationshipEnd  | date: 'dd.MM.yyyy'}}</span>
                                        </div>
                                    </div>
                                    <div class="form-group" *ngIf="editMode2 == true">
                                        <div class="display-flex">
                                            <input type="date" class="form-control max-content me-2" [(ngModel)]="employmentRelationshipStart" [ngModelOptions]="{standalone: true}">
                                            <input type="date" class="form-control max-content" [(ngModel)]="employmentRelationshipEnd" [ngModelOptions]="{standalone: true}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>
                    <div class="row">
                        <div class="col-5 mt-4">
                            <div>
                                <div class="margin-left-fix">
                                    <img [src]="personAccount" class="image-position"><span class="ms-2 fs-6">{{'basic.account' | translate}}</span>

                                    <button class="btn btn-secondary float-end me-3 font-size-small" (click)="toggleEdit(3)" *ngIf="!editMode3 && !createUser && !accountDisabled"><img src="assets/icons/edit_gray_24dp.svg" class="image-edit"><span class="ms-1">{{'basic.edit' | translate}}</span></button>
                                    <button class="float-end btn btn-secondary font-size-small me-1" (click)="disableAccount()" *ngIf="!createUser && !accountDisabled">
                                        <img class="disable-icon" src="assets/icons/person_off_gray_48.svg">
                                        <span class="ms-1">{{'basic.disableAccount' | translate}}</span>
                                    </button>
                                    <button class="float-end btn btn-primary font-size-small me-1" (click)="activateAccount()" *ngIf="!createUserActive && accountDisabled">
                                        <img class="disable-icon" src="assets/icons/person_white_24dp.svg">
                                        <span class="ms-1">{{'basic.activateAccount' | translate}}</span>
                                    </button>
                                    <div class="gray-text font-size-small" *ngIf="accountDisabled && !createUserActive">({{'basic.disabled' | translate}})</div>
                                </div>

                            </div>
                            <div *ngIf="createUser">
                                <div class="gray-text mt-3 small-text">{{'basic.noAccount' | translate}}</div>
                                <button class="btn btn-primary mt-3" (click)="createAccount()"><span class=" position-plus">+</span><span class="small-text ms-1">{{'basic.createAccount' | translate}}</span></button>
                            </div>
                            <div *ngIf="!createUser">
                                <div *ngIf="showUsername" class="gray-text small-text mt-3 ">
                                    <span>{{'basic.username' | translate}}: </span>
                                    <span class="black-text bold-text">{{username}}</span>
                                    <button *ngIf="editMode3" class="btn btn-sm btn-secondary ms-1" (click)="changeUsernameToggle()">{{'basic.change' | translate}}</button>
                                </div>
                                <form ngNativeValidate (ngSubmit)="saveEdit(3)">
                                <div *ngIf="!showUsername && editMode3">
                                    <div class="bold-text mt-2">
                                        <span *ngIf="!createUserActive">{{'basic.changeUsername' | translate}}</span>
                                        <span *ngIf="createUserActive">{{'basic.createAccount' | translate}}</span>
                                    </div>
                                    <div *ngIf="!createUserActive">
                                        <div class="small-text gray-text mt-2">{{'basic.oldUsername' | translate}}</div>
                                        <div class="black-text bold-text small-text">{{username}}</div>
                                    </div>
                                    <div class="small-text gray-text mt-2">{{'basic.newUsername' | translate}}</div>
                                    <input type="text" class="form-control max-content" [required]="!showUsername" [(ngModel)]="newUsername" [ngModelOptions]="{standalone: true}">
                                </div>
                                <div *ngIf="showPassword" class="gray-text small-text mt-2">
                                    <span>Password: </span>
                                    <span class="black-text bold-text ms-2">********</span>
                                    <button *ngIf="editMode3" class="btn btn-sm btn-secondary ms-1" (click)="changePasswordToggle()">Change</button></div>
                                <div *ngIf="!showPassword && editMode3">
                                    <div class="small-text gray-text mt-2">
                                        <span>{{'basic.password' | translate}}</span>
                                        <a class="ms-2 small-text generate-link" (click)="generatePassword()">{{'basic.generatePassword' | translate}}</a>
                                    </div>
                                    <div class="password d-flex">
                                        <input id="login-password" type="password" class="form-control password-length max-content" [required]="!showPassword" [(ngModel)]="newPassword" [ngModelOptions]="{standalone: true}">
                                        <button (click)="hideShowPassword()" class="border border-gray" type="button"><img *ngIf="!showGeneratedPassword" src="assets/icons/visibility_gray_24dp.svg"> <img *ngIf="showGeneratedPassword" src="assets/icons/visibility_off_gray_24dp.svg"></button>
                                    </div>
                                </div>
                                <div class="mb-2 mt-3" *ngIf="editMode3 == true">
                                    <button type="submit" class="btn btn-primary">{{'basic.save' | translate}}</button>
                                    <button type="button" class="btn btn-secondary ms-2" (click)="cancelEdit(3)">{{'basic.cancel' | translate}}</button>
                                </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-7 border-start border-gray  small-text">
                            <div class="ms-4">
                                <div class="gray-text">{{'workers.workingHours' | translate}}</div>
                                <div *ngIf="(isWindowTooSmallBasic === true && editMode2 === false) || (isWindowTooSmallEdit === true && editMode2 === true)">{{'workers.windowTooSmall' | translate}}</div>
                                <div class="table mt-2" *ngIf="(isWindowTooSmallBasic === false && editMode2 === false) || (isWindowTooSmallEdit === false && editMode2 === true)">
                                    <td class="gray-text">
                                        <div class="day position-day border-end border-gray" [style.height]="dayHeight" [style.line-height]="dayHeight">{{'workers.day' | translate}}</div>
                                        <div class="day position-day border-end border-gray" [style.height]="dayHeight" [style.line-height]="dayHeight">{{'basic.start' | translate}}</div>
                                        <div class="day position-day border-end border-gray" [style.height]="dayHeight" [style.line-height]="dayHeight">{{'basic.end' | translate}}</div>
                                    </td>
                                    <td *ngFor="let day of days" class="border-top-none" >
                                        <div class="border-end border-top border-gray min-width-40" [style.height]="dayHeight" [ngClass]="editMode2 === false ? 'width-55' : 'width-81'">
                                            <div class="d-flex justify-content-center" [style.color]="day.checked ? 'black' : (!day.checked ? 'gray' :null)" >{{day.name}}</div>
                                            <div class="position-checkbox" *ngIf="editMode2 == true">
                                                <label class="container container-padding-left-38">
                                                    <input type="checkbox" [(ngModel)]="day.checked" [ngModelOptions]="{standalone: true}" checked="checked">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class=" border-top border-end border-gray" [style.height]="dayHeight" [ngClass]="editMode2 === false ? 'width-55' : 'width-81'">
                                            <div class="text-align-center gray-text" *ngIf="editMode2 == false">{{day.start}}</div>
                                            <div class="d-flex justify-content-center" *ngIf="editMode2 == true">
                                                <input type="time" [(ngModel)]="day.start" [ngModelOptions]="{standalone: true}" class="position-input">
                                            </div>
                                        </div>
                                        <div class="border-top border-end border-bottom border-gray" [style.height]="dayHeight" [ngClass]="editMode2 === false ? 'width-55' : 'width-81'">
                                            <div class="text-align-center gray-text" *ngIf="editMode2 == false">{{day.end}}</div>
                                            <div class="d-flex justify-content-center" *ngIf="editMode2 == true">
                                                <input type="time" [(ngModel)]="day.end" [ngModelOptions]="{standalone: true}" class="position-input" >
                                            </div>
                                        </div>
                                    </td>
                                </div>
                                <div *ngIf="editMode2 == true" class="margin-top-1">
                                    <div class=" gray-text">{{'basic.selectSkills' | translate}}</div>
                                    <div class="border border-gray border-2 scrollbar" [style.height]="skillHeight" *ngIf="qualifications.length > 0">
                                        <div class="" *ngFor="let skill of qualifications; let i = index">
                                            <div *ngIf="i == 0" class="gray-text ms-2">
                                                <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#createNewSkillModal" class="gray-text" data-bs-dismiss="modal">
                                                    <span class="fs-4">+</span>
                                                    <span class="ms-3 position-new-skill">{{'workers.createNewSkill' | translate}}</span>
                                                </a>
                                            </div>
                                            <div class="border-top-alignment"></div>
                                            <label class="container container-padding-left-22 my-2">
                                                <input type="checkbox" [(ngModel)]="qualifications[i].checked" [ngModelOptions]="{standalone: true}" (click)="skillClicked(i)" checked="checked">
                                                <span class="checkmark position-checkbox-skill"></span>
                                                <span class="ms-3 skill-text ">{{skill.name}}</span>
                                            </label>

                                        </div>
                                    </div>
                                    <div class="border border-gray border-2 width-skills scrollbar" *ngIf="qualifications.length == 0">
                                        <div class="ms-3">
                                            <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#createNewSkillModal" class="gray-text" data-bs-dismiss="modal">
                                                <span class="fs-4">+</span>
                                                <span class="ms-3 position-new-skill">{{'workers.createNewSkill' | translate}}</span>
                                            </a>
                                        </div>
                                        <div class="border-top-alignment"></div>
                                        <div class="ms-5 gray-text">{{'workers.noSkills' | translate}}</div>

                                    </div>
                                </div>
                                <div class="mt-2">
                                    <div class="gray-text">{{'basic.skills' | translate}}</div>
                                    <div *ngIf="hasCheckedValue == true">
                                        <div *ngFor="let skill of qualifications" class="inline-block">
                                            <div *ngIf="skill.checked == true" class="skill px-2 rounded-pill">{{skill.name}}</div>
                                        </div>
                                    </div>
                                    <div *ngIf="!qualifications || hasCheckedValue == false" class="bold-text">{{'workers.noSkills' | translate}}</div>
                                </div>
                                <div class="mb-2 mt-3" *ngIf="editMode2 == true">
                                    <button type="button" class="btn btn-primary" (click)="saveEdit(2)">{{'basic.save' | translate}}</button>
                                    <button type="button" class="btn btn-secondary ms-2" (click)="cancelEdit(2)">{{'basic.cancel' | translate}}</button>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-5 mt-4">
                            <div class="gray-text">
                                <span>{{'basic.tags' | translate}}</span>
                                <button class="btn btn-secondary float-end" type="button" (click)="toggleEdit(5)" *ngIf="!editMode5"><img src="assets/icons/edit_gray_24dp.svg" class="image-edit"><span class="ms-1">{{'basic.edit' | translate}}</span></button>
                            </div>
                            <div *ngIf="tags.length === 0">{{'basic.noTags' | translate}}</div>
                            <div *ngIf="tags.length != 0">
                                <div *ngFor="let tag of tags; let i = index" class="bold-text">
                                    {{tag}}
                                </div>

                            </div>
                            <div *ngIf="editMode5 == true" class="margin-top-1">
                                <div class=" gray-text">{{'basic.addTag' | translate}}</div>
                                <div class="mt-3 form-group row">
                                    <div class="col-6">
                                        <input type="text" id="tag-input"
                                               [(ngModel)]="tag"
                                               class="form-control width-input">
                                    </div>
                                    <div class="same-line mt-3">
                                        <div  *ngFor="let existingTag of existingTags" (click)="unselectTag(existingTag)" style="cursor: pointer;" class="gap-between-elements text-align-center gray-text rounded-pill px-2">{{existingTag}}</div>
                                    </div>
                                    <div>
                                        <button type="button" id="saveTagsButton" (click)="saveEdit(5)" class="btn btn-primary col-3">{{'basic.save' | translate}}</button>
                                        <button type="button" class="btn btn-secondary ms-2" (click)="cancelEdit(5)">{{'basic.cancel' | translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-7 mt-4 small-text border-start border-gray">
                            <div class="gray-text">
                                <span>{{'basic.contractors' | translate}}</span>
                                <button class="btn btn-secondary float-end" type="button" (click)="toggleEdit(4)" *ngIf="!editMode4"><img src="assets/icons/edit_gray_24dp.svg" class="image-edit"><span class="ms-1">{{'basic.edit' | translate}}</span></button>
                            </div>
                            <div *ngIf="contractors.length === 0">{{'planner.plannerDetail.noContractors' | translate}}</div>
                            <div *ngIf="contractors.length != 0">
                                <div *ngFor="let contractor of contractors; let i = index" class="bold-text">
                                    {{contractor.id}}: {{contractor.name}}
                                </div>

                            </div>
                            <div *ngIf="editMode4 == true" class="margin-top-1">
                                <div class=" gray-text">{{'planner.plannerDetail.selectContractors' | translate}}</div>
                                <div class="mt-3 form-group row">
                                    <div class="col-6">
                                        <p class="gray-text">{{'basic.contractors' | translate}}</p>
                                        <select class="form-select" (change)="selectContractor($event.target.value)">
                                            <option [value]="">{{'planners.selectContractors' | translate}}</option>
                                            <option *ngFor="let contractor of unselectedContractors" [value]="contractor.id">{{contractor.name}} {{contractor.id == currentContractorId ? ('planners.currentContractor' | translate) : '' }}</option>
                                        </select>

                                        <div class="same-line mt-3">
                                            <div  *ngFor="let contractor of selectedContractors" (click)="unselectContractor(contractor.id)" style="cursor: pointer;" class="gap-between-elements text-align-center gray-text rounded-pill px-2">{{contractor.name}}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <button type="button" [disabled]="saving" id="saveButton" (click)="saveEdit(4)" class="btn btn-primary col-3">{{'basic.save' | translate}}</button>
                                        <button type="button" [disabled]="saving" class="btn btn-secondary ms-2" (click)="cancelEdit(4)">{{'basic.cancel' | translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="createNewSkillModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <h3>{{'workers.enterNewSkill' | translate}}</h3>
                        <form ngNativeValidate (ngSubmit)="createNewSkill()">
                            <div class="form-group">
                                <input type="text" [(ngModel)]="newSkill" [ngModelOptions]="{standalone: true}" class="form-control max-content" required>
                            </div>
                            <button type="submit" class="btn btn-primary">{{'basic.save' | translate}}</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="modalCloseNewSkill">{{'basic.cancel' | translate}}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal end -->
    </div>
</div>
