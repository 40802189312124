import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ManagerProject } from '@shared/models/manager-project';
import { ErrorHandlingService } from '@shared/services/error-handling/error-handling.service';
import { catchError, map } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ProjectLeadService {

  constructor(private http: HttpClient, private errorHandlingService: ErrorHandlingService,
    private translateService: TranslateService
    ) {}

  getManagerProjects() {
    return this.http.get<ManagerProject[]>(`${baseUrl}/api/managerprojects`)
      .pipe(
        map((data: ManagerProject[]) => data['message']),
        catchError(this.errorHandlingService.handleError<ManagerProject[]>(this.translateService.instant('basic.failed')))
      )
  }
}
