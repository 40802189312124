import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorkorderService } from '@modules/planner/services/workorder/workorder.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@shared/services/toast/toast.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-workorders-without-coordinates',
  templateUrl: './workorders-without-coordinates.component.html',
  styleUrls: ['./workorders-without-coordinates.component.scss']
})
export class WorkordersWithoutCoordinatesComponent implements OnInit, OnDestroy {

  workorders = Array()
  selectedWorkorders = Array()  // Updated name
//{address: 'Testikuja 9', old_device_id: 123, project_id: 1234, created: '1.1.2023', coordinates: {lat: null, lon: null}, selected: false}

  amountPerPage = 50
  workorderStart: number = 0
  workorderEnd: number = this.amountPerPage
  currentPage: number = 1
  totalPages: number = 0
  lastWorkorder: number = this.amountPerPage
  totalWorkorders: number = 0
  selectedMetersAmount: number = 0
  componentDestroyed$: Subject<boolean> = new Subject()
  generateSpinner: boolean = false
  allSelected: boolean = false

  constructor(
    private workorderService: WorkorderService,  // Updated name
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.getWorkordersWithoutCoordinates()  // Updated name
  }

  /**
   * Unsubscribes from Observables (stream of data), 
   * which makes website perform better.
   * If all subscriptions are not unsubscribed they all hog memory and 
   * there will memory leaks.
   */
  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete() 
  }

  /**
   * @created 30.01.2023
   * Change presentation of workorders.
   * Set variables for slice pipe in loop to show correct page of workorders.
   * Currently shows two workorders at a time
   * @param direction move forward or backwards in the list of workorders
   * @author Jesse Lindholm
   */
  changeWorkordersSet(direction: string) {
    let workordersLength = this.workorders.length
    if (
      direction === 'next' &&
      this.workorderStart + this.amountPerPage < workordersLength
    ) {
      this.workorderStart += this.amountPerPage
      this.workorderEnd += this.amountPerPage
      if(this.workorderEnd > workordersLength) {
        this.workorderEnd = workordersLength
      }
      this.lastWorkorder = this.workorderEnd
      this.currentPage += 1
    } else if (
      direction === 'previous' &&
      this.workorderStart - this.amountPerPage >= 0
    ) {
      this.workorderStart -= this.amountPerPage
      this.workorderEnd = this.workorderStart + this.amountPerPage
      this.lastWorkorder = this.workorderEnd
      this.currentPage -= 1
    }
  }

  /**
   * Select or unselect meter from selectedWorkorders list.
   * selectedWorkorders list is displayed to user in pop up menu.
   * @param meter workorder
   * @param value boolean value true/false, depending are we adding or removing workorder. True = adding, false = removing.
   */
  changeSelectedAmount(meter: any, value: boolean) {
    if (value) {
      this.selectedMetersAmount += 1
      this.selectedWorkorders.push(meter)  // Updated name
    } else {
      this.selectedMetersAmount -= 1
      const index = this.selectedWorkorders.indexOf(meter, 0);  // Updated name
      if (index > -1) {
        this.selectedWorkorders.splice(index, 1);  // Updated name
      }
    }
  }

  /**
   * Simple api-call to get workorders for page.
   * Gets only workorders that do not have coordinates.
   * Main api-call of page on-load.
   */
  getWorkordersWithoutCoordinates() {
    this.workorderService.getWorkordersWithoutCoordinates()  // Updated name
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          let i = 0
          for (i; i < data.length; i++) {
            data[i].selected = false
            data[i].coordinates = {lat: null, lng: null}
          }
          this.workorders = data
          this.totalWorkorders = i
          this.totalPages = Math.ceil(i / this.amountPerPage)
          if(this.lastWorkorder > this.totalWorkorders) {
            this.lastWorkorder = this.totalWorkorders
          }
        }
      )
  }

  /**
   * Clear coordinates from workorder.
   * User is able to select with checkbox workorder, if both coordinates properties are null.
   * @param workorder 
   */
  clearCoordinatesSingle(workorder) {
    workorder.coordinates.lat = null
    workorder.coordinates.lng = null
  }

  /**
   * Update api-call for workorder coordinates. Performs api-call for single workorder.
   * Removes workorder from displayed list.
   * @param workorder 
   */
  updateCoordinatesSingle(workorder) {
    if (workorder.coordinates.lat && workorder.coordinates.lng) {
      this.workorderService.updateWorkorderCoordinates(workorder.id, workorder.coordinates)
      for (let i = 0; i < this.workorders.length; i++) {
        const element = this.workorders[i];
        if (element.id === workorder.id) {
          this.workorders.splice(i, 1)
          break
        }
      }
    } else if (workorder.coordinates.lat === null) this.toastService.sendToast(false, this.translateService.instant('planner.workordersWithoutCoordinates.noLatitude'))
    else if (workorder.coordinates.lng === null) this.toastService.sendToast(false, this.translateService.instant('planner.workordersWithoutCoordinates.noLongitude'))
  }

  /**
   * Unselect workorder programatically. Called when user "focuses" on input field in UI.
   * Because of this function user does not see workorders that he has selected and typed coordinates afterwards in selected workorders pop up menu.
   * @param workorder 
   */
  unselectCheckbox(workorder) {
    if (workorder.selected) {
      workorder.selected = false
      let index = this.selectedWorkorders.findIndex(w => w.id === workorder.id)  // Updated name
      if (index >= 0) this.selectedWorkorders.splice(index, 1)  // Updated name
      this.selectedMetersAmount -= 1
    }
  }

  /**
   * Generate coordinates for selected workorders. After we get response, clear
   * selected workorders and then stop spinner.
   */
  generateCoordinates() {
    this.generateSpinner = true
    this.workorderService.generateCoordinates(this.selectedWorkorders)  // Updated name
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      () => {
        this.getWorkordersWithoutCoordinates()  // Updated name
        this.selectedWorkorders = Array()  // Updated name
        this.selectedMetersAmount = 0
        this.generateSpinner = false
        this.allSelected = false
      }
    )
  }

  toggleAllWorkorders() {
    this.selectedWorkorders = []  // Updated name
    this.selectedMetersAmount = 0
    this.allSelected = !this.allSelected
    this.workorders.forEach(workorder => {
      if (this.allSelected)  {
        workorder.selected = true
        this.selectedWorkorders.push(workorder)  // Updated name
        this.selectedMetersAmount += 1
      }
      else workorder.selected = false
    });
  }
}
